import { SETTINGS } from '../../../constants/settings';
import { StepProps } from '../NewApplicationFlow';
import { PortalContent } from '../../PortalContent';
import { ApplicationStep } from '../ApplicationStep';
import { useApplication } from '../../../contexts/ApplicationContext';
import { Direction } from '../../Direction';

const Instructions = ({ onNextClick }: StepProps) => {
	const application = useApplication();
	return (
		<ApplicationStep onClickNext={onNextClick}>
			{application.type === 'Corporate' ? (
				<Direction
					title="Directions"
					content={<PortalContent contentKey="VendorPortal.CorporateApplication.InstructionStep.Direction" />}
				/>
			) : (
				<Direction
					title="Directions"
					content={<PortalContent contentKey="VendorPortal.VendorApplication.InstructionStep.Direction" />}
				/>
			)}
			{application.subType === 'Renewal' ? (
				<PortalContent contentKey={SETTINGS.ApplicationRenewalInstruction} />
			) : (
				<PortalContent contentKey={SETTINGS.ApplicationInstruction} />
			)}
		</ApplicationStep>
	);
};
export default Instructions;
