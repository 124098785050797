import { useFormContext } from 'react-hook-form';
import { Divider, Grid, Typography, Box } from '@mui/material';

import { FormInputText } from '../../../Fields/FormInputText';
import { FormSelect } from '../../../Fields/FormSelect';
import { FormRadio } from '../../../Fields/FormRadio';
import { Application } from '../../../../models';
import { StepProps } from '../../NewApplicationFlow';
import { CorporationSelector } from '../../../CorporationSelector';
import { useApplication } from '../../../../contexts/ApplicationContext';
import { FormInputNumber } from '../../../Fields/FormInputNumber';
import { GridCell } from '../../../Vendor/GridCell';

const CorporateDetailsForm = ({ fieldMetaData }: StepProps & { fieldMetaData: { [key: string]: any } }) => {
	const {
		control,
		formState: { errors },
		watch
	} = useFormContext<Application>();

	const application = useApplication();

	const selectedBusinessOwnershipType = watch('ownershipType');
	const isConvictionCivilJudgment = watch('convictionCivilJudgment') === 'yes';
	const isMailingSameAsPhysical = watch('mailingSameAsPhysical') === 'yes';
	const isPrimaryContactAddressSameAsMailing = watch('primaryContactAddressSameAsMailing') === 'yes';
	const isComplianceContactAddressSameAsMailing = watch('complianceContactAddressSameAsMailing') === 'yes';

	return (
		<Box>
			<form>
				<Grid container pt={1} columnSpacing={{ xs: 2, sm: 3, lg: 4 }}>
					<Grid item xs={12}>
						<Typography variant="body2">* Marks all required fields</Typography>
					</Grid>
					{application.subType === 'Renewal' && (
						<>
							<Grid item mt={2} xs={12} md={6} lg={4}>
								<CorporationSelector
									selectedValue={application.corporationId}
									isDisabled={true}
									forceShow
									showVirtual={false}
								/>
							</Grid>
							<Grid item mt={2} xs={12} md={6} lg={8}>
								<GridCell title="Corporate ID" value={application.wicCorporateId} />
							</Grid>
						</>
					)}
					{fieldMetaData?.legalNameOfCorporate && (
						<Grid item xs={12} lg={6}>
							<FormInputText
								control={control}
								name={fieldMetaData?.legalNameOfCorporate?.fieldName}
								metadata={fieldMetaData?.legalNameOfCorporate}
								maxLength={100}
							/>
						</Grid>
					)}
					{fieldMetaData?.corporateId && (
						<Grid item xs={12} lg={6}>
							<FormInputText
								name={fieldMetaData?.corporateId?.fieldName}
								control={control}
								metadata={fieldMetaData?.corporateId}
							/>
						</Grid>
					)}
					{!fieldMetaData.ownershipType && fieldMetaData?.federalTaxId && (
						<Grid item xs={12} lg={6}>
							<FormInputNumber
								name={fieldMetaData?.federalTaxId?.fieldName}
								control={control}
								metadata={fieldMetaData?.federalTaxId}
								maxLength={9}
							/>
						</Grid>
					)}
					{fieldMetaData?.stateTaxId && (
						<Grid item xs={12} lg={6}>
							<FormInputNumber
								name={fieldMetaData?.stateTaxId?.fieldName}
								control={control}
								metadata={fieldMetaData?.stateTaxId}
								maxLength={14}
							/>
						</Grid>
					)}
					{fieldMetaData?.email && (
						<Grid item xs={12} lg={6}>
							<FormInputText
								name={fieldMetaData?.email?.fieldName}
								control={control}
								metadata={fieldMetaData?.email}
								maxLength={100}
							/>
						</Grid>
					)}
					{fieldMetaData?.phone && (
						<Grid item xs={12} lg={6}>
							<FormInputText
								name={fieldMetaData?.phone?.fieldName}
								control={control}
								metadata={fieldMetaData?.phone}
								maxLength={14}
							/>
						</Grid>
					)}
				</Grid>
				{fieldMetaData?.physicalAddress?.line1 && (
					<>
						<Divider sx={{ m: '20px 0px' }} />
						<Grid container pt={1} columnSpacing={{ xs: 4 }}>
							<Grid item xs={12}>
								<Typography variant="body2" fontWeight={700}>
									Physical Address
								</Typography>
							</Grid>
							{fieldMetaData?.physicalAddress?.line1 && (
								<Grid item xs={12} lg={6}>
									<FormInputText
										name={fieldMetaData?.physicalAddress.line1?.fieldName}
										control={control}
										metadata={fieldMetaData?.physicalAddress?.line1}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.physicalAddress?.line2 && (
								<Grid item xs={12} lg={6}>
									<FormInputText
										name={fieldMetaData?.physicalAddress?.line2?.fieldName}
										control={control}
										metadata={fieldMetaData?.physicalAddress?.line2}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.physicalAddress?.city && (
								<Grid item xs={12} lg={6}>
									<FormInputText
										name={fieldMetaData?.physicalAddress?.city?.fieldName}
										control={control}
										metadata={fieldMetaData?.physicalAddress?.city}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.physicalAddress?.state && (
								<Grid item xs={12} lg={6}>
									<FormInputText
										name={fieldMetaData?.physicalAddress?.state?.fieldName}
										control={control}
										metadata={fieldMetaData?.physicalAddress?.state}
										maxLength={2}
									/>
								</Grid>
							)}
							{fieldMetaData?.physicalAddress?.zipCode && (
								<Grid item xs={12} lg={6}>
									<FormInputText
										name={fieldMetaData?.physicalAddress?.zipCode?.fieldName}
										control={control}
										metadata={fieldMetaData?.physicalAddress?.zipCode}
										maxLength={9}
									/>
								</Grid>
							)}
						</Grid>
					</>
				)}

				{fieldMetaData?.mailingAddress?.line1 && (
					<>
						<Divider sx={{ m: '20px 0px' }} />
						<Grid container pt={1} columnSpacing={{ xs: 4 }}>
							<Grid item xs={12}>
								<Typography variant="body2" fontWeight={700}>
									Mailing Address
								</Typography>
							</Grid>
							{fieldMetaData?.mailingSameAsPhysical && (
								<Grid item xs={12}>
									<FormRadio
										required={fieldMetaData?.mailingSameAsPhysical?.required}
										name={fieldMetaData?.mailingSameAsPhysical?.fieldName}
										control={control}
										label={fieldMetaData?.mailingSameAsPhysical?.title}
										testId={fieldMetaData?.mailingSameAsPhysical?.fieldName}
										errors={errors?.mailingSameAsPhysical}
										row
										yesNo
										metadata={fieldMetaData?.mailingSameAsPhysical}
									/>
								</Grid>
							)}
							{fieldMetaData?.mailingAddress?.line1 && (
								<Grid item xs={12} lg={6}>
									<FormInputText
										name={fieldMetaData?.mailingAddress?.line1?.fieldName}
										control={control}
										metadata={fieldMetaData?.mailingAddress?.line1}
										disabled={isMailingSameAsPhysical}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.mailingAddress?.line2 && (
								<Grid item xs={12} lg={6}>
									<FormInputText
										name={fieldMetaData?.mailingAddress?.line2?.fieldName}
										control={control}
										metadata={fieldMetaData?.mailingAddress?.line2}
										disabled={isMailingSameAsPhysical}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.mailingAddress?.city && (
								<Grid item xs={12} lg={6}>
									<FormInputText
										name={fieldMetaData?.mailingAddress?.city?.fieldName}
										control={control}
										metadata={fieldMetaData?.mailingAddress?.city}
										disabled={isMailingSameAsPhysical}
										maxLength={25}
									/>
								</Grid>
							)}
							{fieldMetaData?.mailingAddress?.state && (
								<Grid item xs={12} lg={6}>
									<FormInputText
										name={fieldMetaData?.mailingAddress?.state?.fieldName}
										control={control}
										metadata={fieldMetaData?.mailingAddress?.state}
										disabled={isMailingSameAsPhysical}
										maxLength={2}
									/>
								</Grid>
							)}
							{fieldMetaData?.mailingAddress?.zipCode && (
								<Grid item xs={12} lg={6}>
									<FormInputText
										name={fieldMetaData?.mailingAddress?.zipCode?.fieldName}
										control={control}
										metadata={fieldMetaData?.mailingAddress?.zipCode}
										disabled={isMailingSameAsPhysical}
										maxLength={9}
									/>
								</Grid>
							)}
						</Grid>
					</>
				)}
				{fieldMetaData?.primaryContact?.email && (
					<>
						<Divider sx={{ m: '20px 0px' }} />
						<Grid container pt={1} columnSpacing={{ xs: 4 }}>
							<Grid item xs={12}>
								<Typography variant="body2" fontWeight={700}>
									Primary Contact
								</Typography>
							</Grid>
							{fieldMetaData?.primaryContact?.firstName && (
								<Grid item xs={12} lg={6}>
									<FormInputText
										name={fieldMetaData?.primaryContact?.firstName?.fieldName}
										control={control}
										metadata={fieldMetaData?.primaryContact?.firstName}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.primaryContact?.lastName && (
								<Grid item xs={12} lg={6}>
									<FormInputText
										name={fieldMetaData?.primaryContact?.lastName?.fieldName}
										control={control}
										metadata={fieldMetaData?.primaryContact?.lastName}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.primaryContact?.title && (
								<Grid item xs={12} lg={6}>
									<FormInputText
										name={fieldMetaData?.primaryContact?.title?.fieldName}
										control={control}
										metadata={fieldMetaData?.primaryContact?.title}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.primaryContact?.email && (
								<Grid item xs={12} lg={6}>
									<FormInputText
										name={fieldMetaData?.primaryContact?.email?.fieldName}
										control={control}
										metadata={fieldMetaData?.primaryContact?.email}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.primaryContact?.phone && (
								<Grid item xs={12} lg={6}>
									<FormInputText
										name={fieldMetaData?.primaryContact?.phone?.fieldName}
										control={control}
										metadata={fieldMetaData?.primaryContact?.phone}
										maxLength={14}
									/>
								</Grid>
							)}
							{fieldMetaData?.primaryContactAddressSameAsMailing && (
								<Grid item xs={12} pt={2}>
									<FormRadio
										required={fieldMetaData?.primaryContactAddressSameAsMailing?.required}
										name={fieldMetaData?.primaryContactAddressSameAsMailing?.fieldName}
										control={control}
										label={fieldMetaData?.primaryContactAddressSameAsMailing?.title}
										testId={fieldMetaData?.primaryContactAddressSameAsMailing?.fieldName}
										errors={errors?.primaryContactAddressSameAsMailing}
										row
										yesNo
										metadata={fieldMetaData?.primaryContactAddressSameAsMailing}
									/>
								</Grid>
							)}
							{fieldMetaData?.primaryContact?.address?.line1 && (
								<Grid item xs={12} lg={6}>
									<FormInputText
										name={fieldMetaData?.primaryContact?.address?.line1?.fieldName}
										control={control}
										metadata={fieldMetaData?.primaryContact?.address?.line1}
										disabled={isPrimaryContactAddressSameAsMailing}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.primaryContact?.address?.line2 && (
								<Grid item xs={12} lg={6}>
									<FormInputText
										name={fieldMetaData?.primaryContact?.address?.line2?.fieldName}
										control={control}
										metadata={fieldMetaData?.primaryContact?.address?.line2}
										disabled={isPrimaryContactAddressSameAsMailing}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.primaryContact?.address?.city && (
								<Grid item xs={12} lg={6}>
									<FormInputText
										name={fieldMetaData?.primaryContact?.address?.city?.fieldName}
										control={control}
										metadata={fieldMetaData?.primaryContact?.address?.city}
										disabled={isPrimaryContactAddressSameAsMailing}
										maxLength={25}
									/>
								</Grid>
							)}
							{fieldMetaData?.primaryContact?.address?.county && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.primaryContact?.address?.county?.fieldName}
										control={control}
										metadata={fieldMetaData?.primaryContact?.address?.county}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.primaryContact?.address?.state && (
								<Grid item xs={12} lg={6}>
									<FormInputText
										name={fieldMetaData?.primaryContact?.address?.state?.fieldName}
										control={control}
										metadata={fieldMetaData?.primaryContact?.address?.state}
										disabled={isPrimaryContactAddressSameAsMailing}
										maxLength={2}
									/>
								</Grid>
							)}
							{fieldMetaData?.primaryContact?.address?.zipCode && (
								<Grid item xs={12} lg={6}>
									<FormInputText
										name={fieldMetaData?.primaryContact?.address?.zipCode?.fieldName}
										control={control}
										metadata={fieldMetaData?.primaryContact?.address?.zipCode}
										disabled={isPrimaryContactAddressSameAsMailing}
										maxLength={9}
									/>
								</Grid>
							)}
						</Grid>
					</>
				)}
				{fieldMetaData?.complianceContact?.email && (
					<>
						<Divider sx={{ m: '20px 0px' }} />
						<Grid container pt={1} columnSpacing={{ xs: 4 }}>
							<Grid item xs={12}>
								<Typography variant="body2" fontWeight={700}>
									Compliance Contact
								</Typography>
							</Grid>
							{fieldMetaData?.complianceContact?.firstName && (
								<Grid item xs={12} lg={6}>
									<FormInputText
										name={fieldMetaData?.complianceContact?.firstName?.fieldName}
										control={control}
										metadata={fieldMetaData?.complianceContact?.firstName}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.complianceContact?.lastName && (
								<Grid item xs={12} lg={6}>
									<FormInputText
										name={fieldMetaData?.complianceContact?.lastName?.fieldName}
										control={control}
										metadata={fieldMetaData?.complianceContact?.lastName}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.complianceContact?.email && (
								<Grid item xs={12} lg={6}>
									<FormInputText
										name={fieldMetaData?.complianceContact?.email?.fieldName}
										control={control}
										metadata={fieldMetaData?.complianceContact?.email}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.complianceContact?.phone && (
								<Grid item xs={12} lg={6}>
									<FormInputText
										name={fieldMetaData?.complianceContact?.phone?.fieldName}
										control={control}
										metadata={fieldMetaData?.complianceContact?.phone}
										maxLength={14}
									/>
								</Grid>
							)}
							{fieldMetaData?.complianceContactAddressSameAsMailing && (
								<Grid item xs={12} pt={2}>
									<FormRadio
										required={fieldMetaData?.complianceContactAddressSameAsMailing?.required}
										name={fieldMetaData?.complianceContactAddressSameAsMailing?.fieldName}
										control={control}
										label={fieldMetaData?.complianceContactAddressSameAsMailing?.title}
										testId={fieldMetaData?.complianceContactAddressSameAsMailing?.fieldName}
										errors={errors?.complianceContactAddressSameAsMailing}
										row
										yesNo
										metadata={fieldMetaData?.complianceContactAddressSameAsMailing}
									/>
								</Grid>
							)}
							{fieldMetaData?.complianceContact?.address?.line1 && (
								<Grid item xs={12} lg={6}>
									<FormInputText
										name={fieldMetaData?.complianceContact?.address?.line1?.fieldName}
										control={control}
										metadata={fieldMetaData?.complianceContact?.address?.line1}
										disabled={isComplianceContactAddressSameAsMailing}
										required={!isComplianceContactAddressSameAsMailing}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.complianceContact?.address?.line2 && (
								<Grid item xs={12} lg={6}>
									<FormInputText
										name={fieldMetaData?.complianceContact?.address?.line2?.fieldName}
										control={control}
										metadata={fieldMetaData?.complianceContact?.address?.line2}
										disabled={isComplianceContactAddressSameAsMailing}
										required={!isComplianceContactAddressSameAsMailing}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.complianceContact?.address?.city && (
								<Grid item xs={12} lg={6}>
									<FormInputText
										name={fieldMetaData?.complianceContact?.address?.city?.fieldName}
										control={control}
										metadata={fieldMetaData?.complianceContact?.address?.city}
										disabled={isComplianceContactAddressSameAsMailing}
										required={!isComplianceContactAddressSameAsMailing}
										maxLength={25}
									/>
								</Grid>
							)}
							{fieldMetaData?.complianceContact?.address?.state && (
								<Grid item xs={12} lg={6}>
									<FormInputText
										name={fieldMetaData?.complianceContact?.address?.state?.fieldName}
										control={control}
										metadata={fieldMetaData?.complianceContact?.address?.state}
										disabled={isComplianceContactAddressSameAsMailing}
										required={!isComplianceContactAddressSameAsMailing}
										maxLength={2}
									/>
								</Grid>
							)}
							{fieldMetaData?.complianceContact?.address?.zipCode && (
								<Grid item xs={12} lg={6}>
									<FormInputText
										name={fieldMetaData?.complianceContact?.address?.zipCode?.fieldName}
										control={control}
										metadata={fieldMetaData?.complianceContact?.address?.zipCode}
										disabled={isComplianceContactAddressSameAsMailing}
										required={!isComplianceContactAddressSameAsMailing}
										maxLength={9}
									/>
								</Grid>
							)}
						</Grid>
					</>
				)}
				{fieldMetaData?.ownershipType && (
					<>
						<Divider sx={{ m: '20px 0px' }} />
						<Grid container pt={1} columnSpacing={{ xs: 4 }}>
							<Grid item xs={12}>
								<Typography variant="body2" fontWeight={700}>
									Business Ownership
								</Typography>
							</Grid>
							{fieldMetaData?.ownershipType && (
								<Grid item xs={12} pt={2}>
									<FormSelect
										required={fieldMetaData?.ownershipType?.required}
										name={fieldMetaData?.ownershipType?.fieldName}
										control={control}
										label={fieldMetaData?.ownershipType?.title}
										testId={fieldMetaData?.ownershipType?.fieldName}
										errors={errors.ownershipType}
										list={[
											{ key: 'SoleProprietorship', label: 'Sole Proprietorship' },
											{ key: 'CorporationOrLlc', label: 'Corporation or LLC' },
											{ key: 'Partnership', label: 'Partnership' }
										]}
										metadata={fieldMetaData?.ownershipType}
									/>
								</Grid>
							)}
							{fieldMetaData?.nameOfOwner && selectedBusinessOwnershipType === 'SoleProprietorship' && (
								<Grid item xs={12} lg={6}>
									<FormInputText
										name={fieldMetaData?.nameOfOwner?.fieldName}
										control={control}
										metadata={fieldMetaData?.nameOfOwner}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.ownerSsn && selectedBusinessOwnershipType === 'SoleProprietorship' && (
								<Grid item xs={12} lg={6}>
									<FormInputNumber
										name={fieldMetaData?.ownerSsn?.fieldName}
										control={control}
										// Force the data into the federalTaxId field
										metadata={{ ...fieldMetaData?.ownerSsn, ...{ fieldName: fieldMetaData.federalTaxId.fieldName } }}
										maxLength={9}
									/>
								</Grid>
							)}
							{fieldMetaData?.stateOfTexas && selectedBusinessOwnershipType === 'CorporationOrLlc' && (
								<Grid item xs={12} pt={2}>
									<FormRadio
										required={fieldMetaData?.stateOfTexas?.required}
										name={fieldMetaData?.stateOfTexas?.fieldName}
										control={control}
										label={fieldMetaData?.stateOfTexas?.title}
										testId={fieldMetaData?.stateOfTexas?.fieldName}
										errors={errors?.stateOfTexas}
										row
										yesNo
										metadata={fieldMetaData?.stateOfTexas}
									/>
								</Grid>
							)}
							{fieldMetaData?.texasSosCharterNumber &&
								(selectedBusinessOwnershipType === 'CorporationOrLlc' ||
									selectedBusinessOwnershipType === 'Partnership') && (
									<Grid item xs={12} lg={6}>
										<FormInputNumber
											name={fieldMetaData?.texasSosCharterNumber?.fieldName}
											control={control}
											metadata={fieldMetaData?.texasSosCharterNumber}
											maxLength={10}
										/>
									</Grid>
								)}
							{fieldMetaData?.federalTaxId &&
								(selectedBusinessOwnershipType === 'CorporationOrLlc' ||
									selectedBusinessOwnershipType === 'Partnership') && (
									<Grid item xs={12} lg={6}>
										<FormInputText
											name={fieldMetaData?.federalTaxId?.fieldName}
											control={control}
											metadata={fieldMetaData?.federalTaxId}
											maxLength={9}
										/>
									</Grid>
								)}
							{fieldMetaData?.registeredAgentDetails &&
								(selectedBusinessOwnershipType === 'CorporationOrLlc' ||
									selectedBusinessOwnershipType === 'Partnership') && (
									<Grid item xs={12} lg={6}>
										<FormInputText
											name={fieldMetaData?.registeredAgentDetails?.fieldName}
											control={control}
											metadata={fieldMetaData?.registeredAgentDetails}
											multiline
											rows={4}
											maxLength={2000}
										/>
									</Grid>
								)}
							{fieldMetaData?.officerDetails && selectedBusinessOwnershipType === 'CorporationOrLlc' && (
								<Grid item xs={12} lg={6}>
									<FormInputText
										name={fieldMetaData?.officerDetails?.fieldName}
										control={control}
										metadata={fieldMetaData?.officerDetails}
										multiline
										rows={4}
										maxLength={2000}
									/>
								</Grid>
							)}
							{fieldMetaData?.partnerDetails && selectedBusinessOwnershipType === 'Partnership' && (
								<Grid item xs={12} lg={6}>
									<FormInputText
										name={fieldMetaData?.partnerDetails?.fieldName}
										control={control}
										metadata={fieldMetaData?.partnerDetails}
										multiline
										rows={4}
										maxLength={2000}
									/>
								</Grid>
							)}
						</Grid>
					</>
				)}
				{fieldMetaData?.convictionCivilJudgment && (
					<>
						<Grid item xs={12}>
							<Divider sx={{ m: '20px 0px' }} />
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body2" fontWeight={700}>
								Conviction/Civil Judgement
							</Typography>
						</Grid>
						{fieldMetaData?.convictionCivilJudgment && (
							<Grid item xs={12} pt={2}>
								<FormRadio
									required={fieldMetaData?.convictionCivilJudgment?.required}
									name={fieldMetaData?.convictionCivilJudgment?.fieldName}
									control={control}
									label={fieldMetaData?.convictionCivilJudgment?.title}
									testId={fieldMetaData?.convictionCivilJudgment?.fieldName}
									errors={errors?.convictionCivilJudgment}
									row
									yesNo
									metadata={fieldMetaData?.convictionCivilJudgment}
								/>
							</Grid>
						)}
						{fieldMetaData?.convictionDetails && isConvictionCivilJudgment && (
							<Grid item xs={12} lg={6}>
								<FormInputText
									name={fieldMetaData?.convictionDetails?.fieldName}
									control={control}
									metadata={fieldMetaData?.convictionDetails}
									multiline
									rows={4}
									maxLength={2000}
								/>
							</Grid>
						)}
					</>
				)}
			</form>
		</Box>
	);
};

export default CorporateDetailsForm;
