import axios from 'axios';
import { Response, parseResponse } from '../../models';
import { useMutation } from './../../utils/react-query';
import { UPDATE_VENDOR_DETAILS } from './constants';
import { Vendor } from '../../models/Vendor';

export const useUpdateVendorDetailsMutation = (corpId: string, vendorId: string, options?: any) => {
	const mutationFn = (payload: Vendor) =>
		axios.post<Response<Vendor>>(UPDATE_VENDOR_DETAILS(corpId, vendorId), payload).then(parseResponse);
	return useMutation(mutationFn, options);
};
