import { useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Grid, Typography, Box } from '@mui/material';

import { Response, parseResponse, addressToString } from '../models';
import { Corporation } from '../models/Corporation';
import { LoadingPage } from '../pages/Loading';
import { GridCell } from './Vendor/GridCell';
import { useMetadata } from '../contexts/MetadataContext';

interface ProfileDetailsProps {
	corporationId: string;
}

const formatDate = (rawDate?: string) => {
	if (!rawDate) return ''; // Handle undefined or null case
	const date = new Date(rawDate);
	return date.toLocaleDateString();
};

export const ProfileDetails = ({ corporationId }: ProfileDetailsProps) => {
	const { metadata } = useMetadata();

	const fetchProfileDetails = () =>
		axios.get<Response<Corporation>>(`/api/corporations/${corporationId}`).then(parseResponse);

	const { data, isFetching } = useQuery({
		queryKey: ['corporations', corporationId],
		queryFn: () => fetchProfileDetails()
	});

	const profileData = useMemo(() => {
		if (data) {
			const formattedData: Corporation = {
				...data,
				contractStartDate: formatDate(data?.contractStartDate),
				contractEndDate: formatDate(data?.contractEndDate)
			};
			return formattedData;
		}
		return null;
	}, [data]);

	const addressDetails = addressToString(profileData?.address);

	if (isFetching) {
		return <LoadingPage data-testid="profile-details" />;
	}

	return (
		<>
			<Box display="flex" flexDirection="column" data-testid="profile-details">
				<Box display="flex" gap={5}>
					<Typography variant="h2" fontSize={18} color="#B84787">
						Basic Information
					</Typography>
				</Box>
				<Grid container spacing={2}>
					<GridCell title="Corporate ID" value={profileData?.corporateId} />
					<GridCell title="Name" value={profileData?.name} />
					<GridCell title="Federal ID" value={profileData?.federalId} />
					<GridCell title="State ID" value={profileData?.stateId} />
					<GridCell title="Mail Code" value={profileData?.mailCode} />
					<GridCell title="Primary Contact" value={profileData?.primaryContact?.name} />
					<GridCell title="Compliance Contact" value={profileData?.complianceContact?.name} />
					<GridCell title="Total No. of Active Vendors" value={profileData?.totalNumberOfActiveVendors} />
					{metadata.some(item => item.name === 'VendorPortal.CorporationDetails.ContractStartDate' && item.visible) && (
						<GridCell title="Contract Start Date" value={profileData?.contractStartDate} />
					)}
					{metadata.some(item => item.name === 'VendorPortal.CorporationDetails.ContractEndDate' && item.visible) && (
						<GridCell title="Contract End Date" value={profileData?.contractEndDate} />
					)}
					<GridCell xs={6} title="Address" value={addressDetails} />
				</Grid>
			</Box>
		</>
	);
};
