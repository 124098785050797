import { Box } from '@mui/material';
import { Navigator } from './Navigator';
import { Outlet } from 'react-router';

const drawerWidth = 256;

export const MainLayout = () => {
	return (
		<>
			<Box
				component="nav"
				width={drawerWidth}
				sx={{ background: theme => theme?.palette?.grey?.['800'], overflowX: 'hidden', overflowY: 'auto' }}
			>
				<Navigator width={drawerWidth} />
			</Box>
			<Box display="flex" flexDirection="column" flex={1} overflow="auto">
				<Outlet />
			</Box>
		</>
	);
};
