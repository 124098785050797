import { Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';

import queries from '../components/Applications/queries';
import { RefDocument } from '../models';
import { EntityType } from '../models/EntityType';
import { CustomNoRowsOverlay } from './CustomNoRowsOverlay/CustomNoRowsOverlay';

interface ReferenceDocumentsProps {
	parentType: EntityType;
}

export const ReferenceDocuments = ({ parentType }: ReferenceDocumentsProps) => {
	const { isFetching, data: refDocList } = useQuery(queries.getDocuments());

	// Filter reference documents based on parentType
	const filteredRefDocList = (refDocList as RefDocument[])?.filter(doc => doc.type === parentType);

	// Add a unique id to each row using the index
	const rowsWithId = (filteredRefDocList || []).map((row, index) => ({
		...row,
		id: index,
		nameLink: row.title
	}));

	const columns: GridColDef[] = [
		{
			field: 'nameLink',
			headerName: 'Name',
			minWidth: 300,
			renderCell: params => (
				<a href={params.row.link} target="_blank" rel="noopener noreferrer" download>
					{params.row.title}
				</a>
			)
		}
	];

	return (
		<>
			<Typography variant="subtitle1" sx={{ color: '#B84787' }}>
				Reference Documents
			</Typography>
			<DataGrid
				sx={{ border: 'none' }}
				slots={{
					noRowsOverlay: () => <CustomNoRowsOverlay message="No documents to display" />
				}}
				rows={rowsWithId || []}
				columns={columns}
				loading={isFetching}
				disableRowSelectionOnClick
				disableVirtualization
				data-testid="doc-list"
			/>
		</>
	);
};
