import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { Controller } from 'react-hook-form';

export interface FormInputProps {
	name: string;
	label: string;
	setValue?: any;
	control?: any;
	disabled?: boolean;
	testId?: string;
	required?: boolean;
	isSelected?: boolean;
	errors?: any;
	onChange?: () => void;
}

export const FormCheckbox = ({
	name,
	testId,
	control,
	disabled,
	label,
	required,
	errors,
	isSelected
}: FormInputProps) => {
	return (
		<FormControl size={'small'} variant={'outlined'}>
			<FormControlLabel
				label={label}
				key={testId}
				required={required}
				control={
					<Controller
						name={name}
						control={control}
						render={({ field: { onChange, value }, fieldState }) => (
							<Checkbox
								defaultChecked={isSelected}
								checked={value}
								disabled={disabled}
								onChange={(_e, checked) => {
									onChange(checked);
								}}
								data-testid={testId}
							/>
						)}
					/>
				}
			/>
			<FormHelperText error>{errors?.message}</FormHelperText>
		</FormControl>
	);
};
