import { Box, Typography } from "@mui/material";

import { TextField } from "../../Fields/TextField";
import { Checkbox } from "../../Fields";

interface SnapApprovalProps {
    isSnapApproved: boolean;
    handleSnapApprovedChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    readOnly: boolean;
}

export const SnapApproval = ({ isSnapApproved, handleSnapApprovedChange, readOnly }: SnapApprovalProps) => {
    return (
			<>
				<Box display="flex" gap={2}>
					<Box flex={1}>
						<Typography variant="subtitle2" sx={{ fontWeight: 'bold', marginTop: '15px' }}>
							Is the store SNAP approved?
						</Typography>
						<Box>
							<Checkbox onChange={handleSnapApprovedChange} id="storeIsSnapApproved" label="Yes" disabled={readOnly} />
						</Box>
					</Box>
					<Box flex={2}>
						{isSnapApproved && (
							<TextField
								id="snapNumber"
								label="SNAP Number"
								disabled={readOnly}
								required={isSnapApproved}
								maxLength={7}
								validate={value =>
									!/^[0-9]*$/.test(value) ? 'Numeric only' : undefined
								}
							/>
						)}
					</Box>
				</Box>
			</>
		);
};
