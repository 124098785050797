import { Box, Stepper as MuiStepper, Step as MuiStep, StepLabel as MuiStepLabel } from '@mui/material';
import { PropsWithChildren } from 'react';

interface StepperProps {
	activeStep: number;
	stepNames: string[];
}

export const Stepper = ({ stepNames, activeStep, children }: StepperProps & PropsWithChildren) => {
	return (
		<>
			<MuiStepper sx={{ mb: '20px' }} activeStep={activeStep}>
				{stepNames.map(label => {
					return (
						<MuiStep key={label}>
							<MuiStepLabel>{label}</MuiStepLabel>
						</MuiStep>
					);
				})}
			</MuiStepper>
			<Box>{children}</Box>
		</>
	);
};
