import { CircularProgress, ListItem, ListItemText, Theme, Tooltip, Typography } from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

type FileListItemProps = {
    name: string
    isSuccess?: boolean
    isFailure?: boolean
    isUploading?: boolean
}
export const FileListItem = ({ name, isSuccess = false, isFailure = false, isUploading = false }: FileListItemProps) => {

    const style = {
        padding: "4px 10px",
        margin: "5px 0",
        borderRadius: "10px",
        background: (theme: Theme) => theme.palette.grey["800"],
    };

    return (
        <ListItem key={name} sx={style} >
            <ListItemText sx={{ height: 20 }}>
                <Typography>{name}</Typography>
            </ListItemText>
            {isUploading && <CircularProgress color="secondary" size={20} />}
            {isSuccess && <CheckCircleOutlineIcon color="success" />}
            {isFailure && <Tooltip title="Failed to upload file."><ErrorOutlineIcon color="error" /></Tooltip>}
        </ListItem>
    )
}