import { Box, Typography } from "@mui/material";

import { TextField } from "../../Fields/TextField";

interface PhysicalAddressProps {
    readOnly: boolean;
}

export const PhysicalAddress = ({ readOnly }: PhysicalAddressProps) => {
    return (
        <>
            <Typography variant="subtitle2" sx={{ fontWeight: "bold", marginTop: "15px" }}>
                Physical address of store
            </Typography>
            <Box>
                <TextField
                    id="physicalAddressStreetLine1"
                    label="Address line 1"
                    disabled={readOnly}
                    required
                    maxLength={60}
                />
            </Box>
            <Box display="flex" gap={2}>
                <Box flex={3}>
                    <TextField id="physicalAddressCity" label="City" disabled={readOnly} required maxLength={25} />
                </Box>
                <Box flex={1}>
                    <TextField
                        id="physicalAddressState"
                        label="State"
                        disabled={readOnly}
                        required
                        maxLength={2}
                        validate={(value) => (!/^[A-Z]{2}$/.test(value) ? "Please use format XX." : undefined)}
                    />
                </Box>
                <Box flex={1}>
                    <TextField
                        id="physicalAddressZip"
                        label="Zipcode"
                        disabled={readOnly}
                        required
                        maxLength={9}
                        validate={(value) =>
                            !/^[0-9]{5}(?:-[0-9]{4})?$/.test(value)
                                ? "Please use format XXXXX or XXXXX-XXXX."
                                : undefined
                        }
                    />
                </Box>
            </Box>
            <Box display="flex" gap={2}>
                <TextField id="physicalAddressCountry" label="Country" disabled={readOnly} required maxLength={60} />
                <TextField id="physicalAddressCounty" label="County" disabled={readOnly} required maxLength={50} />
            </Box>
        </>
    );
};
