import { Address, DEFAULT_ADDRESS_VALUES } from './Address';
import { Contact, DEFAULT_CONTACT_VALUES } from './Contact';
import { DEFAULT_STORE_HOURS_VALUES, StoreHours } from './Vendor';

export enum NewApplicationSteps {
	Instructions,
	Corporate,
	Vendor,
	Attestation,
	Confirmation
}

export interface ApplicationBase {
	id: string;
	name: string;
	type: string;
	subType: string;
	status: string;
	createdOn: Date;
}

export interface Application extends ApplicationBase {
	corporationId?: string;
	vendorType?: string;
	storeHours?: StoreHours;
	physicalAddress?: Address;
	mailingAddress?: Address;
	complianceContact?: Contact;
	primaryContact?: Contact;
	aboveFifty?: string;
	attested?: boolean;
	accessToComputerInternetEmail?: string;
	agreementsWithWicProgram?: string;
	annualGrossFoodSales?: number;
	annualGrossNonFoodSales?: number;
	annualGrossSales?: number;
	bankAccountNumberForAchProcessing?: string;
	bankRoutingNumberForAchProcessing?: string;
	percentOfBusinessOwned?: number;
	civilMonetaryPenalty?: string;
	commoditiesDistributorDetails?: string;
	convictionCivilJudgment?: string;
	convictionDetails?: string;
	dairySupplierLicenseNumber?: string;
	splitTenderTransactions?: string;
	vendorEmail?: string;
	federalTaxId?: string;
	foodSalesRevenuePercentage?: string;
	formulaPurchasedFromManufacturer?: string;
	posWicCertified?: string;
	legalNameOfCorporate?: string;
	accessible?: string;
	locationPriorToCurrentOwnership?: string;
	nameOfOwner?: string;
	nameOfPosProvider?: string;
	nameOfPosSystem?: string;
	wicCorporateId?: string;
	numberEbtSnapEnabledRegisters?: number;
	numberOfUnapprovedSelfCheckoutRegisters?: number;
	numberOfWicEnabledRegisters?: number;
	numberOfWicEnabledSelfCheckoutRegisters?: number;
	officerDetails?: string;
	twentyFourSeven?: string;
	otherVendorLocations?: string;
	ownershipType?: string;
	ownerSsn?: string;
	partnerDetails?: string;
	expirationDate?: string;
	permitNumber?: string;
	permitType?: string;
	phone?: string;
	previousVendorDetails?: string;
	previousWicVendorAgreementDetails?: string;
	purchasedFromBloodRelativeOrMarriage?: string;
	registeredAgentDetails?: string;
	mailingSameAsPhysical?: string;
	signedBy?: string;
	signedOn?: Date;
	snapAuthorizationNumber?: string;
	snapAuthorized?: string;
	squareFootage?: number;
	stateOfTexas?: string;
	stateTaxId?: string;
	itemizedReceipts?: string;
	storeSales?: string;
	texasSosCharterNumber?: string;
	totalNumberOfRegisters?: number;
	usdaOrTxSuspensionList?: string;
	inOperation?: string;
	vendorName?: string;
	curbsidePickup?: string;
	onlineOrdering?: string;
	vendorOpeningDate?: string;
	violationDetails?: string;
	wholesaleDistributorDetails?: string;
	children?: ApplicationBase[];
	primaryContactAddressSameAsMailing?: string;
	complianceContactAddressSameAsMailing?: string;
}

export const DEFAULT_APPLICATION_VALUES: Application = {
	id: '',
	name: '',
	type: '',
	subType: '',
	status: '',
	storeHours: DEFAULT_STORE_HOURS_VALUES,
	physicalAddress: DEFAULT_ADDRESS_VALUES,
	mailingAddress: DEFAULT_ADDRESS_VALUES,
	complianceContact: DEFAULT_CONTACT_VALUES,
	primaryContact: DEFAULT_CONTACT_VALUES,
	aboveFifty: 'no',
	attested: false,
	accessToComputerInternetEmail: 'no',
	agreementsWithWicProgram: 'no',
	annualGrossFoodSales: 0,
	annualGrossNonFoodSales: 0,
	annualGrossSales: 0,
	bankAccountNumberForAchProcessing: '',
	bankRoutingNumberForAchProcessing: '',
	percentOfBusinessOwned: 0,
	civilMonetaryPenalty: 'no',
	commoditiesDistributorDetails: '',
	convictionCivilJudgment: 'no',
	convictionDetails: '',
	createdOn: new Date(),
	dairySupplierLicenseNumber: '',
	splitTenderTransactions: 'no',
	vendorEmail: '',
	federalTaxId: '',
	foodSalesRevenuePercentage: '',
	formulaPurchasedFromManufacturer: 'no',
	posWicCertified: 'no',
	legalNameOfCorporate: '',
	accessible: 'no',
	locationPriorToCurrentOwnership: 'no',
	nameOfOwner: '',
	nameOfPosProvider: '',
	nameOfPosSystem: '',
	wicCorporateId: '',
	numberEbtSnapEnabledRegisters: 0,
	numberOfUnapprovedSelfCheckoutRegisters: 0,
	numberOfWicEnabledRegisters: 0,
	numberOfWicEnabledSelfCheckoutRegisters: 0,
	officerDetails: '',
	twentyFourSeven: 'no',
	otherVendorLocations: '',
	ownerSsn: '',
	partnerDetails: '',
	expirationDate: '',
	permitNumber: '',
	phone: '',
	previousVendorDetails: '',
	previousWicVendorAgreementDetails: '',
	purchasedFromBloodRelativeOrMarriage: 'no',
	registeredAgentDetails: '',
	mailingSameAsPhysical: 'no',
	signedBy: '',
	signedOn: new Date(),
	snapAuthorizationNumber: '',
	snapAuthorized: 'no',
	squareFootage: 0,
	stateOfTexas: 'no',
	stateTaxId: '',
	itemizedReceipts: 'no',
	texasSosCharterNumber: '',
	totalNumberOfRegisters: 0,
	usdaOrTxSuspensionList: 'no',
	inOperation: '',
	vendorName: '',
	curbsidePickup: 'no',
	onlineOrdering: 'no',
	vendorOpeningDate: '',
	violationDetails: '',
	wholesaleDistributorDetails: '',
	children: [],
	primaryContactAddressSameAsMailing: 'no',
	complianceContactAddressSameAsMailing: 'no'
};

export const APPLICATION_STATUS = {
	RENEWAL: 'Renewal'
};
