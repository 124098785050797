import axios from 'axios';

import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Container, Divider, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { EmptyPreScreening as DefaultValues, PreScreening } from '../../../models/PreScreening';
import { Response, parseResponse } from '../../../models/Response';
import { usePreScreening } from '../../../contexts/PreScreeningContext';
import {
	CorporateDetails,
	FullServiceGrocer,
	MailingAddress,
	PhysicalAddress,
	SnapApproval,
	VendorDetails
} from '../../PreScreening/Sections';
import { PreScreeningStep } from '../PreScreeningStep';
import { TaskList } from '../../TaskList/TaskList';
import { EntityType } from '../../../models/EntityType';

export const PreScreeningForm = () => {
	const { preScreening, setPreScreening } = usePreScreening();
	const data = preScreening;

	const savePreScreening = async ({ data }: { data: PreScreening; action: 'save' | 'submit' }) => {
		const url = preScreening?.id ? `/api/prescreenings/${preScreening.id}` : '/api/prescreenings';

		return await axios.post<Response<PreScreening>>(url, data).then(parseResponse);
	};

	const form = useForm<PreScreening>({
		mode: 'onChange',
		defaultValues: DefaultValues
	});

	const mutation = useMutation(savePreScreening, {
		onSuccess: async (response, request) => {
			setPreScreening(response);

			if (request.action === 'save') {
				enqueueSnackbar('Saved successfully', { variant: 'success' });
			}
		},
		onError: async (e: Error) => {
			enqueueSnackbar(e.message, { variant: 'error', autoHideDuration: 10000 });
		}
	});

	const [formOptions, setFormOptions] = useState({
		isVendorInfoSameAsCorporate: false,
		isMailingSameAsPhysical: false,
		isSnapApproved: false,
		readOnly: false
	});

	useEffect(() => {
		if (data) {
			form.reset(data);
			setFormOptions({
				isVendorInfoSameAsCorporate: !!data.corporateVendorContactMatch,
				isMailingSameAsPhysical: !!data.physicalMailingAddressMatch,
				isSnapApproved: !!data.storeIsSnapApproved,
				readOnly: !data.editable
			});
		}
	}, [data, form]);

	const handleCheckboxChange = (stateKey: keyof typeof formOptions) => (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.checked;

		setFormOptions(prevStates => ({
			...prevStates,
			[stateKey]: newValue
		}));

		if (stateKey === 'isSnapApproved' && !newValue) {
			form.setValue('snapNumber', '', {
				shouldValidate: true,
				shouldDirty: true
			});
		}
	};

	const [
		corporatePrimaryContactFirstName,
		corporatePrimaryContactLastName,
		corporatePrimaryContactEmail,
		corporatePrimaryContactPhone,
		physicalAddressStreetLine1,
		physicalAddressCity,
		physicalAddressCountry,
		physicalAddressCounty,
		physicalAddressState,
		physicalAddressZip
	] = form.watch([
		'corporatePrimaryContactFirstName',
		'corporatePrimaryContactLastName',
		'corporatePrimaryContactEmail',
		'corporatePrimaryContactPhone',
		'physicalAddressStreetLine1',
		'physicalAddressCity',
		'physicalAddressCountry',
		'physicalAddressCounty',
		'physicalAddressState',
		'physicalAddressZip'
	]);

	useEffect(() => {
		if (formOptions.isVendorInfoSameAsCorporate) {
			form.setValue('vendorPrimaryContactFirstName', corporatePrimaryContactFirstName, {
				shouldValidate: true,
				shouldDirty: true
			});
			form.setValue('vendorPrimaryContactLastName', corporatePrimaryContactLastName, {
				shouldValidate: true,
				shouldDirty: true
			});
			form.setValue('vendorPrimaryContactEmail', corporatePrimaryContactEmail, {
				shouldValidate: true,
				shouldDirty: true
			});
			form.setValue('vendorPrimaryContactPhone', corporatePrimaryContactPhone, {
				shouldValidate: true,
				shouldDirty: true
			});
		}
		if (formOptions.isMailingSameAsPhysical) {
			form.setValue('mailingAddressStreetLine1', physicalAddressStreetLine1, {
				shouldValidate: true,
				shouldDirty: true
			});
			form.setValue('mailingAddressCity', physicalAddressCity, {
				shouldValidate: true,
				shouldDirty: true
			});
			form.setValue('mailingAddressCountry', physicalAddressCountry, {
				shouldValidate: true,
				shouldDirty: true
			});
			form.setValue('mailingAddressCounty', physicalAddressCounty, {
				shouldValidate: true,
				shouldDirty: true
			});
			form.setValue('mailingAddressState', physicalAddressState, {
				shouldValidate: true,
				shouldDirty: true
			});
			form.setValue('mailingAddressZip', physicalAddressZip, {
				shouldValidate: true,
				shouldDirty: true
			});
		}
	}, [
		formOptions,
		form,
		corporatePrimaryContactFirstName,
		corporatePrimaryContactLastName,
		corporatePrimaryContactEmail,
		corporatePrimaryContactPhone,
		physicalAddressStreetLine1,
		physicalAddressCity,
		physicalAddressCountry,
		physicalAddressCounty,
		physicalAddressState,
		physicalAddressZip
	]);

	const onSubmit = (action: 'save' | 'submit') =>
		form.handleSubmit(data => {
			if (action === 'submit') {
				data.submitted = true;
			}
			mutation.mutate({ data, action });
		});

	return (
		<PreScreeningStep
			onClickBack={onSubmit('save')}
			backText="Save"
			backProps={{ variant: 'text' }}
			isBackLoading={mutation.isLoading && mutation.variables?.action === 'save'}
			isBackDisabled={!form.formState.isDirty || !form.formState.isValid || formOptions.readOnly || mutation.isLoading}
			onClickNext={onSubmit('submit')}
			nextText="Submit"
			isNextLoading={mutation.isLoading && mutation.variables?.action === 'submit'}
			isNextDisabled={formOptions.readOnly || !form.formState.isValid || mutation.isLoading}
		>
			<FormProvider {...form}>
				<Box component="form" data-testid="prescreening">
					<Container>
						<Box display="flex" gap={5} flexWrap="wrap">
							<Box flex={2}>
								<Typography variant="h2">Pre-Screening Form</Typography>
								<Typography variant="subtitle2">
									Complete and submit the form below to start new WIC vendor application. * Marks all required fields.
								</Typography>
								<CorporateDetails readOnly={formOptions.readOnly} />
								<VendorDetails
									isVendorInfoSameAsCorporate={formOptions.isVendorInfoSameAsCorporate}
									handleCorporateVendorContactMatch={handleCheckboxChange('isVendorInfoSameAsCorporate')}
									readOnly={formOptions.readOnly}
								/>
								<PhysicalAddress readOnly={formOptions.readOnly} />
								<MailingAddress
									isMailingSameAsPhysical={formOptions.isMailingSameAsPhysical}
									handleMailingSameAsPhysical={handleCheckboxChange('isMailingSameAsPhysical')}
									readOnly={formOptions.readOnly}
								/>
								<SnapApproval
									isSnapApproved={formOptions.isSnapApproved}
									handleSnapApprovedChange={handleCheckboxChange('isSnapApproved')}
									readOnly={formOptions.readOnly}
								/>
								<FullServiceGrocer readOnly={formOptions.readOnly} />
							</Box>
							{preScreening?.pendingClarification && (
								<>
									<Divider orientation="vertical" sx={{ display: { xs: 'none', md: 'block' } }} flexItem />
									<Box width={300}>
										<TaskList
											parentType={EntityType.PreScreening}
											parentId={preScreening.id!}
											includeChildren={false}
										/>
									</Box>
								</>
							)}
						</Box>
					</Container>
				</Box>
			</FormProvider>
		</PreScreeningStep>
	);
};
