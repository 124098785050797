import React, { useState } from 'react';
import { Box, Radio, FormControlLabel, Typography, Button, Grid, RadioGroup } from '@mui/material';
import ActionDialog from '../Dialog/ActionDialog';
import { LoadingButton } from '@mui/lab';
import { useCreateApplicationMutation } from './queries';
import { Application } from '../../models';
import { useQueryClient } from '@tanstack/react-query';
import { useUser } from '../../contexts/UserContext';

interface NewApplicationProps {
	onHandleCancelClick: () => void;
	onHandleSubmit: (data: Application) => void;
}

export const APPLICATION_TYPE = {
	CORP_VENDOR: 'Corporate',
	VENDOR: 'Vendor'
};
const NewApplicationModal = ({ onHandleCancelClick, onHandleSubmit }: NewApplicationProps) => {
	const [selectedOption, setSelectedOption] = useState<string>(APPLICATION_TYPE.CORP_VENDOR);

	const user = useUser();

	const queryClient = useQueryClient();

	const createApplicationMutate = useCreateApplicationMutation({
		onSuccess: (applicationData: Application) => {
			queryClient.setQueryData<Application[]>(['applications'], prev => {
				if (prev) {
					return [...prev, applicationData];
				} else {
					return [applicationData];
				}
			});

			onHandleSubmit(applicationData);
		}
	});

	const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedOption(event?.target?.value);
	};

	const handleSubmit = async () => {
		createApplicationMutate.mutate({ type: selectedOption });
	};

	return (
		<ActionDialog
			open
			skipHeader={false}
			onClose={() => {}}
			buttons={[
				<Grid container justifyContent="space-around">
					<Grid item>
						<Button color="secondary" onClick={onHandleCancelClick} data-testid="cancel-new-application-btn">
							Cancel
						</Button>
					</Grid>
					<Grid>
						<LoadingButton
							type="submit"
							data-testid="submit-new-application-btn"
							variant="contained"
							color="secondary"
							disabled={createApplicationMutate.isLoading}
							loading={createApplicationMutate.isLoading}
							onClick={handleSubmit}
						>
							Start application
						</LoadingButton>
					</Grid>
				</Grid>
			]}
		>
			<Box style={{ width: '450px' }} data-testid="new-application-modal">
				<Typography variant="subtitle1">New application</Typography>
				<RadioGroup value={selectedOption} onChange={handleRadioChange}>
					<FormControlLabel
						value={APPLICATION_TYPE.CORP_VENDOR}
						control={<Radio data-testid="corp-vendor-app-radio-btn" />}
						label={
							<div data-testid="corp-vendor-app-btn">
								<Typography fontWeight={700} mt={3}>
									Corporate/Bus Ent Application
								</Typography>
								<Typography>
									Some copy here about how we are creating a new corporation application along with vendor(s).
								</Typography>
							</div>
						}
					/>
					{user.relationships.some(x => !x.parentId && !x.isVirtual) && (
						<FormControlLabel
							value={APPLICATION_TYPE.VENDOR}
							control={<Radio data-testid="vendor-app-radio-btn" />}
							label={
								<div data-testid="vendor-app-btn">
									<Typography fontWeight={700} mt={3}>
										Vendor Application
									</Typography>
									<Typography>
										Some copy here about how we are only adding a new vendor application for the currently selected
										corporation.
									</Typography>
								</div>
							}
						/>
					)}
				</RadioGroup>
			</Box>
		</ActionDialog>
	);
};

export default NewApplicationModal;
