import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useApplication } from '../../../contexts/ApplicationContext';
import { ApplicationStep } from '../ApplicationStep';

const Confirmation = () => {
	const application = useApplication();

	return (
		<ApplicationStep>
			<Box sx={{ backgroundColor: '#F7F7FB', p: '20px', borderRadius: '10px' }}>
				<Typography variant="h2">Successfully submitted!</Typography>
				<Typography>
					Your application ({application.name}) was successfully submitted. We will reach out to you if there are any
					changes that need to be made.
				</Typography>
			</Box>
		</ApplicationStep>
	);
};
export default Confirmation;
