import { Box, Typography } from "@mui/material";

import { TextField } from "../../Fields/TextField";
import { Checkbox } from "../../Fields";

interface MailingAddressProps {
    isMailingSameAsPhysical: boolean;
    handleMailingSameAsPhysical: (event: React.ChangeEvent<HTMLInputElement>) => void;
    readOnly: boolean;
}

export const MailingAddress = ({
    isMailingSameAsPhysical,
    handleMailingSameAsPhysical,
    readOnly,
}: MailingAddressProps) => {
    return (
        <>
            <Typography variant="subtitle2" sx={{ fontWeight: "bold", marginTop: "15px" }}>
                Mailing address of store
            </Typography>
            <Checkbox
                onChange={handleMailingSameAsPhysical}
                id="physicalMailingAddressMatch"
                label="Mailing address is same as physical address"
                disabled={readOnly}
            />
            <Box>
                <TextField
                    id="mailingAddressStreetLine1"
                    label="Address line 1"
                    disabled={isMailingSameAsPhysical || readOnly}
                    required
                    maxLength={60}
                />
            </Box>
            <Box display="flex" gap={2}>
                <Box flex={3}>
                    <TextField
                        id="mailingAddressCity"
                        label="City"
                        disabled={isMailingSameAsPhysical || readOnly}
                        required
                        maxLength={25}
                    />
                </Box>
                <Box flex={1}>
                    <TextField
                        id="mailingAddressState"
                        label="State"
                        disabled={isMailingSameAsPhysical || readOnly}
                        required
                        maxLength={2}
                        validate={(value) => (!/^[A-Z]{2}$/.test(value) ? "Please use format XX." : undefined)}
                    />
                </Box>
                <Box flex={1}>
                    <TextField
                        id="mailingAddressZip"
                        label="Zipcode"
                        disabled={isMailingSameAsPhysical || readOnly}
                        required
                        maxLength={9}
                        validate={(value) =>
                            !/^[0-9]{5}(?:-[0-9]{4})?$/.test(value)
                                ? "Please use format XXXXX or XXXXX-XXXX."
                                : undefined
                        }
                    />
                </Box>
            </Box>
            <Box display="flex" gap={2}>
                <TextField
                    id="mailingAddressCountry"
                    label="Country"
                    disabled={isMailingSameAsPhysical || readOnly}
                    required
                    maxLength={60}
                />
                <TextField
                    id="mailingAddressCounty"
                    label="County"
                    disabled={isMailingSameAsPhysical || readOnly}
                    required
                    maxLength={50}
                />
            </Box>
        </>
    );
};
