import axios from 'axios';

import { Application, Contact, Corporation, Response, User, parseResponse, RefDocument } from '../../models';
import { useMutation } from './../../utils/react-query';
import { ContactProps } from '../Contact/types';

export const useUpdateApplicationMutation = (options?: any) => {
	const mutationFn = ({ id, payload }: any) =>
		axios.post<Response<Application>>(`/api/Applications/${id}`, { ...payload }).then(parseResponse);
	return useMutation(mutationFn, options);
};

export const useCreateApplicationMutation = (options?: any) => {
	const mutationFn = (payload: any) =>
		axios.post<Response<Application>>('/api/Applications', payload).then(parseResponse);
	return useMutation(mutationFn, options);
};

export const useCreateApplicationContactMutation = (appId?: string, options?: any) => {
	const mutationFn = (payload: ContactProps) =>
		axios.post<Response<User>>(`/api/Applications/${appId}/contacts`, payload).then(parseResponse);
	return useMutation(mutationFn, options);
};

export const useUpdateApplicationContactMutation = (appId?: string, options?: any) => {
	const mutationFn = (payload: ContactProps) =>
		axios.post<Response<User>>(`/api/Applications/${appId}/contacts/${payload?.id}`, payload).then(parseResponse);
	return useMutation(mutationFn, options);
};

export const useDeleteApplicationContactMutation = (appId?: string, options?: any) => {
	const mutationFn = (id: string) =>
		axios.delete<Response<User>>(`/api/Applications/${appId}/contacts/${id}`).then(parseResponse);
	return useMutation(mutationFn, options);
};

const queries = {
	getCorporateDetails: (id: string) => ({
		queryKey: ['corporateDetails', id],
		queryFn: (): Promise<Corporation> => axios.get<Response<Corporation>>(`/api/corporations/${id}`).then(parseResponse)
	}),
	getDocuments: () => ({
		queryKey: ['documents'],
		queryFn: (): Promise<RefDocument[]> =>
			axios.get<Response<RefDocument[]>>(`/api/Configuration/documents`).then(parseResponse)
	}),
	getApplications: () => ({
		queryKey: ['applications'],
		queryFn: (): Promise<Application[]> => axios.get<Response<Application[]>>('/api/Applications').then(parseResponse)
	}),
	getApplication: (id: string) => ({
		queryKey: ['applications', id],
		queryFn: (): Promise<Application> => axios.get<Response<Application>>(`/api/Applications/${id}`).then(parseResponse)
	}),
	getMetaData: () => ({
		queryKey: ['metaData'],
		queryFn: (): Promise<Application> =>
			axios.get<Response<Application>>('api/Configuration/metadata').then(parseResponse)
	}),
	getVendorTypes: () => ({
		queryKey: ['vendorTypes'],
		queryFn: (): Promise<string[]> =>
			axios.get<Response<string[]>>('/api/Configuration/vendorTypes').then(parseResponse),
		select: (res: string[]) => res?.map(type => ({ key: type, label: type }))
	}),
	getContacts: (id: string) => ({
		queryKey: ['applicationContact', id],
		queryFn: (): Promise<Application> =>
			axios.get<Response<Application>>(`/api/applications/${id}/contacts`).then(parseResponse),
		select: (res: Contact[]) =>
			res?.map(({ firstName, lastName, ...rest }) => ({
				...rest,
				firstName,
				lastName,
				name: `${firstName} ${lastName}`
			}))
	})
};
export default queries;
