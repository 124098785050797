import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import { StepProps } from '../../NewApplicationFlow';
import { ContactList } from '../../../Contact/ContactList/ContactList';
import { useRelationship } from '../../../../contexts/RelationshipContext';
import queries, {
	useCreateApplicationContactMutation,
	useDeleteApplicationContactMutation,
	useUpdateApplicationContactMutation,
	useUpdateApplicationMutation
} from '../../queries';
import { useApplication } from '../../../../contexts/ApplicationContext';
import { AddEditContact } from '../../../Contact/AddEditContact/AddEditContact';
import { useQueryClient } from '@tanstack/react-query';
import { ContactProps, HandleAddEditClickParams } from '../../../Contact/types';
import { Application, DEFAULT_ADDRESS_VALUES, User } from '../../../../models';
import { CorporateDocumentation } from './CorporateDocumentation';
import { MetadataProvider, useMetadata } from '../../../../contexts/MetadataContext';
import { MetadataObj } from '../../../../models/Metadata';
import { buildDefaultFormValues, buildMetadata } from '../../utils';
import { ApplicationStep } from '../../ApplicationStep';
import { FormProvider, useForm } from 'react-hook-form';
import { Direction } from '../../../Direction';
import { PortalContent } from '../../../PortalContent';
import CorporateDetailsForm from './CorporateDetailsForm';
import HorizontalAccordion from '../../../../surfaces/Accordion/HorizontalAccordion';
import { TaskList } from '../../../TaskList/TaskList';
import { EntityType } from '../../../../models/EntityType';
import { yupResolver } from '@hookform/resolvers/yup';
import { fieldConfig } from './FieldConfig';
import { enqueueSnackbar } from 'notistack';

const TAB_NAMES = {
	DETAILS: 'vendor-details',
	CONTACT_LIST: 'contact-list',
	DOCUMENTS: 'documents'
};

const DEFAULT_VALUES = {
	legalNameOfCorporate: '',
	email: '',
	phone: '',
	physicalAddress: DEFAULT_ADDRESS_VALUES,
	mailingSameAsPhysical: 'no',
	mailingAddress: DEFAULT_ADDRESS_VALUES,
	primaryContact: {
		firstName: '',
		lastName: '',
		title: '',
		email: '',
		phone: '',
		address: DEFAULT_ADDRESS_VALUES
	},
	primaryContactAddressSameAsMailing: 'no',
	complaineContactAddressSameAsMailing: 'no',
	complianceContact: {
		firstName: '',
		lastName: '',
		title: '',
		email: '',
		phone: '',
		address: DEFAULT_ADDRESS_VALUES
	},
	ownershipType: 'SoleProprietorship',
	convictionCivilJudgment: 'no',
	nameOfOwner: ''
};

const CorporateDetails = ({ onPrevClick, onNextClick }: StepProps) => {
	const { corporationId, vendorId } = useRelationship();
	const [addEditContact, setAddEditContact] = useState<ContactProps>();
	const [addEditContactVisible, setAddEditContactVisible] = useState(false);
	const [tabValue, setTabValue] = useState(TAB_NAMES.DETAILS);
	const [isDataLoading, setIsDataLoading] = useState(true);

	const { metadata } = useMetadata();
	const [metaDataObj, setMetaDataObj] = useState<{ [key: string]: MetadataObj }>({});
	const [fieldMetaData, setFieldMetaData] = useState<{ [key: string]: any }>({});
	const [validationSchema, setValidationSchema] = useState<any>({});
	const [defaultFormValues, setDefaultFormValues] = useState({});

	const application = useApplication();

	const queryClient = useQueryClient();
	const mutation = useUpdateApplicationMutation({
		onSuccess: (applicationData: Application) => {
			queryClient.setQueryData<Application>(['applications', application.id], () => applicationData);

			if (onNextClick) {
				onNextClick();
			}
		},
		onError: () => {
			enqueueSnackbar('Error updating application', { variant: 'error' });
		}
	});

	const processApplicationData = (appData: Application) => {
		const { mailingAddress, primaryContact, complianceContact } = appData;

		// TODO: Refactor address handling
		const mailingAddressStr = [
			mailingAddress?.line1,
			mailingAddress?.line2,
			mailingAddress?.city,
			mailingAddress?.state,
			mailingAddress?.zipCode
		]
			.filter(Boolean)
			.join('');
		const primaryContactAddressStr = `${primaryContact?.address?.line1}${primaryContact?.address?.line2}${primaryContact?.address?.city}${primaryContact?.address?.state}${primaryContact?.address?.zipCode}`;
		const complianceContactAddressStr = `${complianceContact?.address?.line1}${complianceContact?.address?.line2}${complianceContact?.address?.city}${complianceContact?.address?.state}${complianceContact?.address?.zipCode}`;

		const isPrimaryContactAddressSameAsMailing =
			mailingAddressStr !== '' && mailingAddressStr === primaryContactAddressStr;
		const isComplianceContactAddressSameAsMailing =
			mailingAddressStr !== '' && mailingAddressStr === complianceContactAddressStr;

		return {
			...appData,
			mailingSameAsPhysical: appData.mailingSameAsPhysical ? 'yes' : 'no',
			primaryContactAddressSameAsMailing: isPrimaryContactAddressSameAsMailing ? 'yes' : 'no',
			complianceContactAddressSameAsMailing: isComplianceContactAddressSameAsMailing ? 'yes' : 'no',
			convictionCivilJudgment: appData.convictionCivilJudgment ? 'yes' : 'no',
			stateOfTexas: appData.stateOfTexas ? 'yes' : 'no'
		};
	};

	const methods = useForm({
		defaultValues: { ...defaultFormValues, ...processApplicationData(application) },
		resolver: yupResolver(validationSchema),
		mode: 'all'
	});

	const selectedBusinessOwnershipType = methods.watch('ownershipType');
	const isConvictionCivilJudgment = methods.watch('convictionCivilJudgment') === 'yes';
	const isMailingSameAsPhysical = methods.watch('mailingSameAsPhysical') === 'yes';
	const isPrimaryContactAddressSameAsMailing = methods.watch('primaryContactAddressSameAsMailing') === 'yes';
	const isComplianceContactAddressSameAsMailing = methods.watch('complianceContactAddressSameAsMailing') === 'yes';

	const physicalAddressLine1 = methods.watch('physicalAddress.line1');
	const physicalAddressLine2 = methods.watch('physicalAddress.line2');
	const physicalAddressCity = methods.watch('physicalAddress.city');
	const physicalAddressState = methods.watch('physicalAddress.state');
	const physicalAddressZip = methods.watch('physicalAddress.zipCode');
	const physicalAddress = `${physicalAddressLine1}${physicalAddressLine2}${physicalAddressCity}${physicalAddressState}${physicalAddressZip}`;
	const mailingAddressLine1 = methods.watch('mailingAddress.line1');
	const mailingAddressLine2 = methods.watch('mailingAddress.line2');
	const mailingAddressCity = methods.watch('mailingAddress.city');
	const mailingAddressState = methods.watch('mailingAddress.state');
	const mailingAddressZip = methods.watch('mailingAddress.zipCode');
	const mailingAddress = `${mailingAddressLine1}${mailingAddressLine2}${mailingAddressCity}${mailingAddressState}${mailingAddressZip}`;

	useEffect(() => {
		setMetaDataObj(metadata?.reduce((result, mDataRecord) => ({ ...result, [mDataRecord.name]: mDataRecord }), {}));
	}, [metadata]);

	useEffect(() => {
		if (isMailingSameAsPhysical && methods.setValue && !isDataLoading) {
			methods.setValue('mailingAddress', methods.getValues('physicalAddress'), {
				shouldValidate: true,
				shouldDirty: true
			});
		}
	}, [physicalAddress, isMailingSameAsPhysical, methods, isDataLoading]);

	useEffect(() => {
		if (isPrimaryContactAddressSameAsMailing && methods.setValue && !isDataLoading) {
			methods.setValue('primaryContact.address', methods.getValues('mailingAddress'), {
				shouldValidate: true,
				shouldDirty: true
			});
		}
	}, [mailingAddress, isPrimaryContactAddressSameAsMailing, methods, isDataLoading]);

	useEffect(() => {
		if (isComplianceContactAddressSameAsMailing && methods.setValue && !isDataLoading) {
			methods.setValue('complianceContact.address', methods.getValues('mailingAddress'), {
				shouldValidate: true,
				shouldDirty: true
			});
		}
	}, [mailingAddress, isComplianceContactAddressSameAsMailing, methods, isDataLoading]);

	useEffect(() => {
		if (Object.values(metaDataObj)?.length > 0) {
			const result = buildMetadata(fieldConfig, metaDataObj);
			setDefaultFormValues(buildDefaultFormValues(DEFAULT_VALUES, result, ''));

			setFieldMetaData(result);
			setIsDataLoading(false);
		}
	}, [defaultFormValues, metaDataObj]);

	const createSchema = (obj: any) => {
		const schema: any = {};

		for (const key in obj) {
			if (obj[key]?.fieldName) {
				if (
					selectedBusinessOwnershipType === 'SoleProprietorship' &&
					(fieldMetaData?.stateOfTexas?.fieldName === obj[key]?.fieldName ||
						fieldMetaData?.texasSosCharterNumber?.fieldName === obj[key]?.fieldName ||
						fieldMetaData?.registeredAgentDetails?.fieldName === obj[key]?.fieldName ||
						fieldMetaData?.officerDetails?.fieldName === obj[key]?.fieldName ||
						fieldMetaData?.partnerDetails?.fieldName === obj[key]?.fieldName ||
						fieldMetaData?.federalTaxId?.fieldName === obj[key]?.fieldName)
				) {
				} else if (
					selectedBusinessOwnershipType === 'CorporationOrLlc' &&
					(fieldMetaData?.nameOfOwner?.fieldName === obj[key]?.fieldName ||
						fieldMetaData?.ownerSsn?.fieldName === obj[key]?.fieldName ||
						fieldMetaData?.partnerDetails?.fieldName === obj[key]?.fieldName)
				) {
				} else if (
					selectedBusinessOwnershipType === 'Partnership' &&
					(fieldMetaData?.nameOfOwner?.fieldName === obj[key]?.fieldName ||
						fieldMetaData?.ownerSsn?.fieldName === obj[key]?.fieldName ||
						fieldMetaData?.stateOfTexas?.fieldName === obj[key]?.fieldName ||
						fieldMetaData?.officerDetails?.fieldName === obj[key]?.fieldName)
				) {
				} else if (!isConvictionCivilJudgment && fieldMetaData?.convictionDetails?.fieldName === obj[key]?.fieldName) {
				} else if (obj[key]?.required) {
					schema[key] = obj[key]?.validation;
				}
			} else {
				schema[key] = createSchema(obj[key]);
			}
		}

		return yup.object().shape(schema);
	};

	useEffect(
		() => setValidationSchema(createSchema(fieldMetaData)),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[fieldMetaData, selectedBusinessOwnershipType, isConvictionCivilJudgment]
	);

	const onSubmit = (data: any) => {
		const formattedData = {
			...data,
			mailingSameAsPhysical: data.mailingSameAsPhysical === 'yes',
			primaryContactAddressSameAsMailing: data.primaryContactAddressSameAsMailing === 'yes',
			complianceContactAddressSameAsMailing: data.complianceContactAddressSameAsMailing === 'yes',
			convictionCivilJudgment: data.convictionCivilJudgment === 'yes',
			stateOfTexas: data.stateOfTexas === 'yes'
		};

		if (
			formattedData.primaryContact &&
			!Object.keys(formattedData.primaryContact).some(x => formattedData.primaryContact[x] !== undefined)
		) {
			delete formattedData.primaryContact;
		}

		mutation.mutate({
			id: application.id,
			payload: formattedData
		});
	};

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setTabValue(newValue);
	};

	const onSuccess = async (user: any) => {
		queryClient.setQueryData<User[]>(['applicationContact', application.id], oldData => {
			var users = JSON.parse(JSON.stringify(oldData ?? [])) as User[];
			var userIndex = users?.findIndex(x => x?.id === user?.id);
			if (userIndex >= 0) {
				return [...users?.slice(0, userIndex), user, ...users?.slice(userIndex + 1)];
			}

			return [...users, user];
		});

		setAddEditContactVisible(false);
	};

	const createContactMutation = useCreateApplicationContactMutation(application.id, {
		onSuccess
	});

	const updateContactMutation = useUpdateApplicationContactMutation(application.id, {
		onSuccess
	});

	const deleteContactMutation = useDeleteApplicationContactMutation(application.id, {
		onSuccess: (_: any, context: any) => {
			queryClient.setQueryData<User[]>(['applicationContact', application.id], oldData =>
				oldData?.filter(record => record?.id !== context)
			);
		}
	});

	const handleOnUpdate = (isEdit: boolean, formData: any) => {
		if (isEdit) {
			updateContactMutation.mutate(formData);
			return;
		}

		createContactMutation.mutate(formData);
	};

	const handleAddEditContactClose = () => {
		setAddEditContact(undefined);
		setAddEditContactVisible(false);
	};

	const isLoading =
		updateContactMutation?.isLoading || deleteContactMutation?.isLoading || createContactMutation?.isLoading;
	const isError = updateContactMutation?.isError || createContactMutation?.isError;
	const errorMessage = updateContactMutation?.error || createContactMutation?.error;

	const handleAddEditClick = ({ isOpen, data }: HandleAddEditClickParams) => {
		if (isOpen) {
			setAddEditContactVisible(true);
		}

		if (data) {
			setAddEditContact(data);
		}
	};

	const handleContactDelete = (contactId: string) => {
		deleteContactMutation.mutate(contactId);
	};

	return (
		<ApplicationStep
			onClickBack={onPrevClick}
			onClickNext={methods.handleSubmit(onSubmit)}
			isNextLoading={mutation.isLoading}
			isNextDisabled={mutation.isLoading}
		>
			<MetadataProvider>
				<Box display="flex" flex={1} justifyContent="space-between" data-testid="vendor-step-details-tab">
					<Box display="flex" flex={1} pr={3} flexDirection={'column'}>
						<Tabs
							value={tabValue}
							onChange={handleChange}
							textColor="secondary"
							indicatorColor="secondary"
							data-testid="corporate-tabs"
							sx={{ marginBottom: 2, position: 'sticky', top: 0, zIndex: 2, backgroundColor: 'white' }}
						>
							<Tab value={TAB_NAMES.DETAILS} label="Details" data-testid={`${TAB_NAMES.DETAILS}-tab`} />
							<Tab value={TAB_NAMES.CONTACT_LIST} label="Contacts" data-testid={`${TAB_NAMES.CONTACT_LIST}-tab`} />
							{application.type === 'Corporate' && (
								<Tab value={TAB_NAMES.DOCUMENTS} label="Documents" data-testid={`${TAB_NAMES.DOCUMENTS}-tab`} />
							)}
						</Tabs>
						<Box display="flex" flexDirection="column" flex={1}>
							{tabValue === TAB_NAMES.DETAILS && (
								<FormProvider {...methods}>
									<Direction
										title="Directions"
										content={<PortalContent contentKey="VendorPortal.CorporateApplication.CorporateStep.Direction" />}
									/>
									<CorporateDetailsForm fieldMetaData={fieldMetaData} />
								</FormProvider>
							)}
							{tabValue === TAB_NAMES.CONTACT_LIST && (
								<Box flex={1}>
									<ContactList
										canManageUsers
										fetchQuery={queries.getContacts(application.id)}
										contactAccessToggle={{ applicationId: application?.id, corporationId, vendorId }}
										handleAddEditClick={handleAddEditClick}
										actions={{ delete: true, onDelete: handleContactDelete }}
									/>
								</Box>
							)}
							{tabValue === TAB_NAMES.DOCUMENTS && <CorporateDocumentation />}
						</Box>
					</Box>
					{addEditContactVisible && (
						<AddEditContact
							applicationContact
							record={{ ...addEditContact, editable: true }}
							isOpen={addEditContactVisible}
							onClose={handleAddEditContactClose}
							onUpdate={handleOnUpdate}
							isLoading={isLoading}
							isError={isError}
							errorMessage={errorMessage}
							skipFields={{ additionalNotes: true }}
						/>
					)}
					<HorizontalAccordion title="Tasks" initialExpanded={application.status === 'Pending Clarification'}>
						<TaskList parentType={EntityType.Application} parentId={application.id} includeChildren={false} />
					</HorizontalAccordion>
				</Box>
			</MetadataProvider>
		</ApplicationStep>
	);
};
export default CorporateDetails;
