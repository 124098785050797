import { Button, IconButton, InputAdornment, Link } from '@mui/material';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';

import { Search } from '@mui/icons-material';
import { FEATURES } from '../../../constants/features';
import { useConfiguration } from '../../../contexts/ConfigurationContext';
import { useQuery } from '../../../utils/react-query';
import { ContactAccessToggle } from '../../ContactAccessToggle';
import { ContactListProps } from '../types';
import { CustomNoRowsOverlay } from '../../CustomNoRowsOverlay/CustomNoRowsOverlay';

export const ContactList = ({
	canManageUsers,
	fetchQuery,
	contactAccessToggle,
	handleAddEditClick,
	actions
}: ContactListProps) => {
	const { features } = useConfiguration();
	const { isFetching, data }: any = useQuery(fetchQuery);

	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: 'Name',
			minWidth: 200,
			renderCell: ({ row }) => (
				<Link
					href="#"
					data-testid="contact-name"
					onClick={() => {
						if (handleAddEditClick) {
							handleAddEditClick({ isOpen: true, data: row });
						}
					}}
				>
					<div>{row?.name}</div>
				</Link>
			)
		},
		{
			field: 'email',
			headerName: 'Email',
			minWidth: 150
		},
		{
			field: 'types',
			headerName: 'Contact Types',
			minWidth: 200,
			valueGetter: params => (params.value as string[])?.join(', ')
		}
	];

	if (!contactAccessToggle?.applicationId) {
		columns.push({
			field: 'registrationStatus',
			headerName: 'Registration Status',
			minWidth: 170
		});
	}

	columns.push({
		field: 'enabled',
		headerName: 'Access Enabled?',
		width: 150,
		renderCell: params =>
			contactAccessToggle?.applicationId ? (
				<ContactAccessToggle applicationId={contactAccessToggle?.applicationId} contactId={params.row.id} />
			) : (
				<ContactAccessToggle
					corporationId={contactAccessToggle?.corporationId}
					vendorId={contactAccessToggle?.vendorId}
					contactId={params.row.id}
				/>
			)
	});

	if (actions) {
		columns.push({
			field: 'actions',
			headerName: '',
			width: 100,
			renderCell: params => (
				<>
					{actions?.delete && (
						<IconButton color="primary">
							<DeleteIcon
								onClick={() => {
									if (actions?.onDelete) {
										actions.onDelete(params?.row?.id);
									}
								}}
							/>
						</IconButton>
					)}
				</>
			)
		});
	}

	const ContactListToolbar = ({ visible = false }) => {
		return (
			<GridToolbarContainer sx={{ mt: 3, mb: 1, p: 0, alignContent: 'center' }}>
				<GridToolbarQuickFilter
					sx={{ pb: 0 }}
					variant="outlined"
					size="small"
					InputProps={{
						startAdornment: null,
						endAdornment: (
							<InputAdornment position="end">
								<Search />
							</InputAdornment>
						)
					}}
				/>
				{visible && (
					<Button
						sx={{ py: '7px' }}
						variant="outlined"
						color="secondary"
						onClick={() => {
							if (handleAddEditClick) {
								handleAddEditClick({ isOpen: true });
							}
						}}
						data-testid="button-open"
					>
						Add contact
					</Button>
				)}
			</GridToolbarContainer>
		);
	};

	return (
		<DataGrid
			sx={{ border: 'none' }}
			rows={data || []}
			columns={columns}
			loading={isFetching}
			disableRowSelectionOnClick
			disableVirtualization
			slots={{
				toolbar: ContactListToolbar,
				noRowsOverlay: () => <CustomNoRowsOverlay message="No contacts to display" />
			}}
			slotProps={{
				toolbar: { visible: canManageUsers }
			}}
			columnVisibilityModel={{
				enabled: features.isEnabled(FEATURES.AccessManagement) && canManageUsers
			}}
			data-testid="contacts"
		/>
	);
};
