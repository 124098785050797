export interface PreScreening {
	id?: string;
	businessContactId: string;
	corporatePrimaryContactName: string;
	corporatePrimaryContactFirstName: string;
	corporatePrimaryContactLastName: string;
	corporatePrimaryContactEmail: string;
	corporatePrimaryContactPhone: string;
	corporationNumber?: string;
	einOrSsn?: string;
	legalEntityBusinessName: string;
	mailingAddressCity: string;
	mailingAddressCountry: string;
	mailingAddressCounty: string;
	mailingAddressState: string;
	mailingAddressStreetLine1: string;
	mailingAddressZip: string;
	name: string;
	nameOfStore: string;
	ownerFullName: string;
	physicalAddressCity: string;
	physicalAddressCountry: string;
	physicalAddressCounty: string;
	physicalAddressState: string;
	physicalAddressStreetLine1: string;
	physicalAddressZip: string;
	snapNumber: string;
	vendorPrimaryContactName: string;
	vendorPrimaryContactFirstName: string;
	vendorPrimaryContactLastName: string;
	vendorPrimaryContactEmail: string;
	vendorPrimaryContactPhone: string;
	storeIsFullServiceGrocer: boolean;
	storeIsSnapApproved: boolean;
	corporateVendorContactMatch: boolean;
	physicalMailingAddressMatch: boolean;
	submittedOn?: Date;
	editable: boolean;
	submitted: boolean;
	pendingClarification: boolean;
}

export const EmptyPreScreening: PreScreening = {
	businessContactId: '00000000-0000-0000-0000-000000000000',
	corporatePrimaryContactName: '',
	corporatePrimaryContactFirstName: '',
	corporatePrimaryContactLastName: '',
	corporatePrimaryContactEmail: '',
	corporatePrimaryContactPhone: '',
	corporationNumber: '',
	einOrSsn: '',
	legalEntityBusinessName: '',
	mailingAddressCity: '',
	mailingAddressCountry: '',
	mailingAddressCounty: '',
	mailingAddressState: '',
	mailingAddressStreetLine1: '',
	mailingAddressZip: '',
	name: '',
	nameOfStore: '',
	ownerFullName: '',
	physicalAddressCity: '',
	physicalAddressCountry: '',
	physicalAddressCounty: '',
	physicalAddressState: '',
	physicalAddressStreetLine1: '',
	physicalAddressZip: '',
	snapNumber: '',
	vendorPrimaryContactName: '',
	vendorPrimaryContactFirstName: '',
	vendorPrimaryContactLastName: '',
	vendorPrimaryContactEmail: '',
	vendorPrimaryContactPhone: '',
	storeIsSnapApproved: false,
	storeIsFullServiceGrocer: false,
	corporateVendorContactMatch: false,
	physicalMailingAddressMatch: false,
	submittedOn: undefined,
	editable: true,
	submitted: false,
	pendingClarification: false
};

export enum StepEnum {
    Introduction,
    Form,
    Confirmation,
};
