import { FormControl, MenuItem, TextField } from '@mui/material';
import { useRelationship } from '../contexts/RelationshipContext';
import { useUser } from '../contexts/UserContext';
import { useMemo } from 'react';
import { UserRelationship } from '../models';

export interface CorporationSelectorProps {
	selectedValue?: string;
	forceShow?: boolean;
	isDisabled?: boolean;
	showVirtual?: boolean
}

export const CorporationSelector = ({
	selectedValue,
	forceShow = false,
	isDisabled = false,
	showVirtual = true,
}: CorporationSelectorProps) => {
	const { relationships } = useUser();
	const { relationship, setRelationship } = useRelationship();

	const corporations = useMemo(() => relationships.filter(x => x.parentId === undefined && (!x.isVirtual || x.isVirtual === showVirtual)), [relationships, showVirtual]);

	// Hide corporation selector if user only has access to one corporation
	if (corporations.length <= 1 && !forceShow) {
		return null;
	}

	// If the user clicks a "virtual" corporation, that means they don't have access. In that case, find a child vendor
	const onClick = (relationship: UserRelationship) => {
		if (relationship.parentId === undefined && relationship.isVirtual) {
			const vendor = relationships.find(x => x.parentId === relationship.id);
			if (vendor !== undefined) {
				setRelationship(vendor);
			} else {
				setRelationship(relationship);
			}
		} else {
			setRelationship(relationship);
		}
	};

	return (
		<FormControl size="small" fullWidth sx={{ pr: 3, mb: 2 }} disabled={isDisabled}>
			<TextField
				id="corporation"
				select
				fullWidth
				size="small"
				variant="outlined"
				label="Corporate/Bus Ent"
				value={selectedValue ?? relationship?.parentId ?? relationship?.id ?? ''}
				inputProps={{ id: 'corporation', 'data-testid': 'corporation' }}
				disabled={isDisabled}
			>
				{corporations.map(x => {
					return (
						<MenuItem key={x.id} value={x.id} onClick={() => onClick(x)}>
							{x.name}
						</MenuItem>
					);
				})}
			</TextField>
		</FormControl>
	);
};
