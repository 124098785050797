import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { RootLayout, AuthenticatedLayout, MainLayout, PreScreeningLayout } from './layouts';
import { HomePage, ErrorPage, LoadingPage, DashboardPage, PreScreeningPage } from './pages';

import { ApplicationLayout } from './layouts/authenticated/ApplicationLayout';
import { ApplicationsPage } from './pages/Applications';
import { ApplicationPage } from './pages/Application';
import { ROUTES } from './constants/routes';

export const routes = [
	{
		element: <RootLayout />,
		errorElement: <ErrorPage />,
		children: [
			{
				path: ROUTES.loginCallback.path,
				element: <LoadingPage />
			},
			{
				path: ROUTES.logoutCallback.path,
				element: <LoadingPage />
			},
			{
				element: <AuthenticatedLayout />,
				children: [
					{
						element: <MainLayout />,
						children: [
							{
								index: true,
								element: <HomePage />
							},
							{
								path: ROUTES.dashboard.path,
								element: <DashboardPage />
							},
							{
								element: <ApplicationLayout />,
								children: [
									{
										path: ROUTES.applications.path,
										element: <ApplicationsPage />,
										children: [
											{
												path: ROUTES.application.path,
												element: <ApplicationPage />
											}
										]
									},

								]
							}
						]
					},
					{
						element: <PreScreeningLayout />,
						children: [
							{
								path: ROUTES.preScreening.path,
								element: <PreScreeningPage />
							}
						]
					}
				]
			},
			{
				path: '*',
				element: <ErrorPage message="Page not found." />
			}
		]
	}
];

export const AppRoutes = () => {
	const router = createBrowserRouter(routes);

	return <RouterProvider router={router} />;
};
