import { PropsWithChildren } from "react"
import { PageTitle } from "./PageTitle"
import { PageContent } from "./PageContent"
import { Box } from "@mui/material"

interface PageProps {
    title?: string
}

export const Page = ({ title, children }: PageProps & PropsWithChildren) => (
    <Box flex={1} maxHeight='100%'>
        {title && <PageTitle>{title}</PageTitle>}
        <PageContent>
            {children}
        </PageContent>
    </Box>
)
