import { useAuth } from "react-oidc-context";
import { Outlet, useLocation, useNavigate } from "react-router";

import { Box } from "@mui/material";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";

import { ROUTES } from "../constants";
import { ErrorPage, LoadingPage } from "../pages";
import { useEffect } from "react";

export const RootLayout = () => {

    const auth = useAuth();
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if (auth.isAuthenticated) {
            if (!auth.user) {
                return;
            }

            if (location.pathname === ROUTES.loginCallback.path) {
                if (auth.user.state) {
                    const path = (auth.user.state as any).path
                    navigate(path)
                } else {
                    navigate(ROUTES.home.path)
                }
            }
        } else {
            if (!auth.isLoading && auth.activeNavigator !== 'signinRedirect') {
                auth.signinRedirect({
                    state: {
                        path: location.pathname
                    }
                })
            }
        }
    }, [auth, navigate, location])

    return (
        <>
            <Header />
            <Main />
            <Footer />
        </>
    );
}

export const Main = () => {

    const auth = useAuth()

    return (
        <Box component='main' display='flex' flex={1} maxHeight='calc(100vh - 96px)'>
            {auth.isLoading || !auth.isAuthenticated ? <LoadingPage /> : auth.error ? <ErrorPage /> : <Outlet />}
        </Box>
    )
}