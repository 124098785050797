export interface Contact {
	parentId: string;
	id: string;
	name: string;
	firstName: string;
	lastName: string;
	email: string;
	fax?: string;
	phone: string;
	phone2?: string;
	types: string[];
	enabled: boolean;
	editable: boolean;
	address?: {
		city?: string;
		line1?: string;
		line2?: string;
		state?: string;
		zipCode?: string;
	};
	notes: string;
}

export const DEFAULT_CONTACT_VALUES: Contact = {
	parentId: '',
	id: '',
	name: '',
	firstName: '',
	lastName: '',
	email: '',
	fax: '',
	phone: '',
	phone2: '',
	types: [],
	enabled: false,
	editable: false,
	address: {
		city: '',
		line1: '',
		line2: '',
		state: '',
		zipCode: ''
	},
	notes: ''
};
