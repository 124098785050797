import * as yup from 'yup';
import { Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Application, DEFAULT_ADDRESS_VALUES, User } from '../../../../models';
import VendorDetailsForm from './VendorDetailsForm';
import { ContactList } from '../../../Contact/ContactList/ContactList';
import { useRelationship } from '../../../../contexts/RelationshipContext';
import queries, {
	useCreateApplicationContactMutation,
	useDeleteApplicationContactMutation,
	useUpdateApplicationContactMutation,
	useUpdateApplicationMutation
} from '../../queries';
import { useApplication } from '../../../../contexts/ApplicationContext';
import { useQueryClient } from '@tanstack/react-query';
import { ContactProps, HandleAddEditClickParams } from '../../../Contact/types';
import { AddEditContact } from '../../../Contact/AddEditContact/AddEditContact';
import { VendorDocumentation } from './VendorDocumentation';
import { useMetadata } from '../../../../contexts/MetadataContext';
import { MetadataObj } from '../../../../models/Metadata';
import { buildDefaultFormValues, buildMetadata } from '../../utils';
import { enqueueSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { fieldConfig } from './FieldConfig';
import { DEFAULT_STORE_HOURS_VALUES } from '../../../../models/Vendor';
import { ApplicationStep } from '../../ApplicationStep';
import HorizontalAccordion from '../../../../surfaces/Accordion/HorizontalAccordion';
import { TaskList } from '../../../TaskList/TaskList';
import { EntityType } from '../../../../models/EntityType';
import { Direction } from '../../../Direction';
import { PortalContent } from '../../../PortalContent';

export interface FieldMetaData {
	key?: string;
	title?: string;
	label?: string;
	name?: string;
	fieldName?: string;
	requiredValidationMessage?: string;
	visible?: string;
}

export interface AddEditVendorProps {
	parentApplicationId?: string;
	isDialog?: boolean;
	informDialogAboutDirtyState?: (isDirty: boolean) => void;
	onClickBackSuccess?: () => void;
	onClickNextSuccess?: () => void;
}

const DEFAULT_VALUES = {
	vendorName: '',
	vendorOpeningDate: 'dd/mm/yyyy',
	vendorType: '',
	phone: '',
	federalTaxId: '',
	stateTaxId: '',
	itemizedReceipts: 'no',
	splitTenderTransactions: 'no',
	ownerSsn: '',
	accessToComputerInternetEmail: 'no',
	dairySupplierLicenseNumber: '',
	accessible: 'no',
	otherVendorLocations: '',
	physicalAddress: DEFAULT_ADDRESS_VALUES,
	mailingSameAsPhysical: 'no',
	mailingAddress: DEFAULT_ADDRESS_VALUES,
	primaryContact: {
		firstName: '',
		lastName: '',
		title: '',
		email: '',
		phone: '',
		address: DEFAULT_ADDRESS_VALUES
	},
	twentyFourSeven: 'no',
	storeHours: DEFAULT_STORE_HOURS_VALUES,
	permitType: 'RetailFoodOperation',
	permitNumber: '',
	expirationDate: '',
	formulaPurchasedFromManufacturer: 'no',
	commoditiesDistributorDetails: '',
	aboveFifty: 'no',
	foodSalesRevenuePercentage: '',
	onlineOrdering: 'no',
	curbsidePickup: 'no',
	snapAuthorized: 'no',
	snapAuthorizationNumber: '',
	civilMonetaryPenalty: 'no',
	violationDetails: '',
	agreementsWithWicProgram: 'no',
	previousWicVendorAgreementDetails: '',
	locationPriorToCurrentOwnership: 'no',
	previousVendorDetails: '',
	inOperation: '',
	purchasedFromBloodRelativeOrMarriage: 'no',
	usdaOrTxSuspensionList: 'no',
	bankAccountNumberForAchProcessing: '',
	bankRoutingNumberForAchProcessing: '',
	nameOfPosProvider: '',
	nameOfPosSystem: '',
	posWicCertified: 'no'
};

const TAB_NAMES = {
	DETAILS: 'general-info',
	CONTACTS: 'contacts',
	DOCUMENTS: 'documents'
};

const VendorDetails = ({
	isDialog,
	informDialogAboutDirtyState,
	parentApplicationId,
	onClickBackSuccess,
	onClickNextSuccess
}: AddEditVendorProps) => {
	const [tabValue, setTabValue] = useState(TAB_NAMES.DETAILS);
	const { corporationId, vendorId } = useRelationship();
	const application = useApplication();
	const queryClient = useQueryClient();
	const [addEditContact, setAddEditContact] = useState<ContactProps>();
	const [addEditContactVisible, setAddEditContactVisible] = useState(false);
	const [isDataLoading, setIsDataLoading] = useState(true);

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setTabValue(newValue);
	};

	const onSuccess = async (user: any) => {
		queryClient.setQueryData<User[]>(['applicationContact', application.id], oldData => {
			var users = JSON.parse(JSON.stringify(oldData ?? [])) as User[];
			var userIndex = users?.findIndex(x => x?.id === user?.id);
			if (userIndex >= 0) {
				return [...users?.slice(0, userIndex), user, ...users?.slice(userIndex + 1)];
			}

			return [...users, user];
		});

		setAddEditContactVisible(false);
	};

	const createContactMutation = useCreateApplicationContactMutation(application.id, {
		onSuccess
	});

	const updateContactMutation = useUpdateApplicationContactMutation(application.id, {
		onSuccess
	});

	const deleteContactMutation = useDeleteApplicationContactMutation(application.id, {
		onSuccess: (_: any, context: any) => {
			queryClient.setQueryData<User[]>(['applicationContact', application.id], oldData =>
				oldData?.filter(record => record?.id !== context)
			);
		}
	});

	const handleOnUpdate = (isEdit: boolean, formData: any) => {
		if (isEdit) {
			updateContactMutation.mutate(formData);
			return;
		}

		createContactMutation.mutate(formData);
	};

	const handleAddEditContactClose = () => {
		setAddEditContact(undefined);
		setAddEditContactVisible(false);
	};

	const isLoading =
		updateContactMutation?.isLoading || deleteContactMutation?.isLoading || createContactMutation?.isLoading;
	const isError = updateContactMutation?.isError || createContactMutation?.isError;
	const errorMessage = updateContactMutation?.error || createContactMutation?.error;

	const handleAddEditClick = ({ isOpen, data }: HandleAddEditClickParams) => {
		if (isOpen) {
			setAddEditContactVisible(true);
		}

		if (data) {
			setAddEditContact(data);
		}
	};

	const handleContactDelete = (contactId: string) => {
		deleteContactMutation.mutate(contactId);
	};

	const { metadata } = useMetadata();
	const [metaDataObj, setMetaDataObj] = useState<{ [key: string]: MetadataObj }>({});
	const [fieldMetaData, setFieldMetaData] = useState<{ [key: string]: any }>({});
	const [validationSchema, setValidationSchema] = useState<any>({});
	const [defaultFormValues, setDefaultFormValues] = useState({});

	const mutation = useUpdateApplicationMutation({
		onSuccess: (applicationData: Application) => {
			queryClient.setQueryData<Application>(['applications', application.id], () => applicationData);

			if (onClickNextSuccess) {
				onClickNextSuccess();
			}
		},
		onError: () => {
			enqueueSnackbar('Error updating application', { variant: 'error' });
		}
	});
	const formatDateForInput = (dateString: string) => {
		if (!dateString) return '';
		const date = new Date(dateString);
		const year = date.getFullYear();
		const month = `0${date.getMonth() + 1}`.slice(-2);
		const day = `0${date.getDate()}`.slice(-2);
		return `${year}-${month}-${day}`;
	};

	const processApplicationData = (appData: Application) => {
		return {
			...appData,
			aboveFifty: appData?.aboveFifty ? 'yes' : 'no',
			accessible: appData?.accessible ? 'yes' : 'no',
			accessToComputerInternetEmail: appData?.accessToComputerInternetEmail ? 'yes' : 'no',
			agreementsWithWicProgram: appData?.agreementsWithWicProgram ? 'yes' : 'no',
			civilMonetaryPenalty: appData?.civilMonetaryPenalty ? 'yes' : 'no',
			convictionCivilJudgment: appData?.convictionCivilJudgment ? 'yes' : 'no',
			curbsidePickup: appData?.curbsidePickup ? 'yes' : 'no',
			formulaPurchasedFromManufacturer: appData?.formulaPurchasedFromManufacturer ? 'yes' : 'no',
			mailingSameAsPhysical: appData?.mailingSameAsPhysical ? 'yes' : 'no',
			onlineOrdering: appData?.onlineOrdering ? 'yes' : 'no',
			purchasedFromBloodRelativeOrMarriage: appData?.purchasedFromBloodRelativeOrMarriage ? 'yes' : 'no',
			snapAuthorized: appData?.snapAuthorized ? 'yes' : 'no',
			twentyFourSeven: appData?.twentyFourSeven ? 'yes' : 'no',
			usdaOrTxSuspensionList: appData?.usdaOrTxSuspensionList ? 'yes' : 'no',
			posWicCertified: appData?.posWicCertified ? 'yes' : 'no',
			splitTenderTransactions: appData?.splitTenderTransactions ? 'yes' : 'no',
			locationPriorToCurrentOwnership: appData?.locationPriorToCurrentOwnership ? 'yes' : 'no',
			stateOfTexas: appData?.stateOfTexas ? 'yes' : 'no',
			itemizedReceipts: appData?.itemizedReceipts ? 'yes' : 'no',
			expirationDate: formatDateForInput(appData?.expirationDate || ''),
			vendorOpeningDate: formatDateForInput(appData?.vendorOpeningDate || '')
		};
	};

	const form = useForm<Application>({
		defaultValues: { ...defaultFormValues, ...processApplicationData(application) },
		resolver: yupResolver(validationSchema),
		mode: 'all'
	});

	useEffect(() => {
		const isFormDirty = form.formState.isDirty;
		if (informDialogAboutDirtyState) {
			informDialogAboutDirtyState(isFormDirty);
		}
	}, [form.formState, informDialogAboutDirtyState]);

	const isFormulaPurchasedFromManufacturer = form.watch('formulaPurchasedFromManufacturer');
	const isSnapAuthorized = form.watch('snapAuthorized');
	const isCivilMonetaryPenalty = form.watch('civilMonetaryPenalty');

	const createSchema = (obj: any) => {
		const schema: any = {};

		for (const key in obj) {
			if (obj[key]?.fieldName) {
				if (
					(isFormulaPurchasedFromManufacturer !== 'yes' &&
						fieldMetaData?.wholesaleDistrubutorDetails?.fieldName === obj[key]?.fieldName) ||
					(isSnapAuthorized !== 'yes' &&
						fieldMetaData?.snapAuthorized &&
						fieldMetaData?.snapAuthorizationNumber?.fieldName === obj[key]?.fieldName) ||
					(isCivilMonetaryPenalty !== 'yes' && fieldMetaData?.violationDetails?.fieldName === obj[key]?.fieldName)
				) {
				} else if (obj[key]?.required) {
					schema[key] = obj[key]?.validation;
				}
			} else {
				schema[key] = createSchema(obj[key]);
			}
		}

		return yup.object().shape(schema);
	};

	useEffect(() => {
		setValidationSchema(createSchema(fieldMetaData));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fieldMetaData, isFormulaPurchasedFromManufacturer, isSnapAuthorized, isCivilMonetaryPenalty]);

	const isOpenTwentyFourSeven = form.watch('twentyFourSeven') === 'yes';

	const isMailingSameAsPhysical = form.watch('mailingSameAsPhysical') === 'yes';
	const physicalAddressLine1 = form.watch('physicalAddress.line1');
	const physicalAddressLine2 = form.watch('physicalAddress.line2');
	const physicalAddressCity = form.watch('physicalAddress.city');
	const physicalAddressCounty = form.watch('physicalAddress.county');
	const physicalAddressState = form.watch('physicalAddress.state');
	const physicalAddressZip = form.watch('physicalAddress.zipCode');
	const physicalAddress = `${physicalAddressLine1}${physicalAddressLine2}${physicalAddressCity}${physicalAddressCounty}${physicalAddressState}${physicalAddressZip}`;

	useEffect(() => {
		if (isMailingSameAsPhysical && form.setValue && !isDataLoading) {
			form.setValue('mailingAddress', form.getValues('physicalAddress'), {
				shouldValidate: true,
				shouldDirty: true
			});
		}
	}, [physicalAddress, isMailingSameAsPhysical, form, isDataLoading]);

	useEffect(() => {
		if (isOpenTwentyFourSeven && form.setValue && !isDataLoading) {
			[
				'storeHours.sunday',
				'storeHours.monday',
				'storeHours.tuesday',
				'storeHours.wednesday',
				'storeHours.thursday',
				'storeHours.friday',
				'storeHours.saturday'
			].forEach((record: any) =>
				form.setValue(record, '24/7', {
					shouldValidate: true,
					shouldDirty: true
				})
			);
		}
	}, [isOpenTwentyFourSeven, form, isDataLoading]);

	useEffect(() => {
		setMetaDataObj(metadata?.reduce((result, mDataRecord) => ({ ...result, [mDataRecord.name]: mDataRecord }), {}));
	}, [metadata]);

	useEffect(() => {
		if (Object.values(metaDataObj)?.length > 0) {
			const result = buildMetadata(fieldConfig, metaDataObj);
			setDefaultFormValues(buildDefaultFormValues(DEFAULT_VALUES, result, ''));

			setFieldMetaData(result);
			setIsDataLoading(false);
		}
	}, [metaDataObj]);

	const onSubmit = (values: any) => {
		const formattedValues = {
			...values,
			aboveFifty: values.aboveFifty === 'yes',
			accessible: values.accessible === 'yes',
			accessToComputerInternetEmail: values.accessToComputerInternetEmail === 'yes',
			agreementsWithWicProgram: values.agreementsWithWicProgram === 'yes',
			civilMonetaryPenalty: values.civilMonetaryPenalty === 'yes',
			convictionCivilJudgment: values.convictionCivilJudgment === 'yes',
			curbsidePickup: values.curbsidePickup === 'yes',
			formulaPurchasedFromManufacturer: values.formulaPurchasedFromManufacturer === 'yes',
			mailingSameAsPhysical: values.mailingSameAsPhysical === 'yes',
			onlineOrdering: values.onlineOrdering === 'yes',
			purchasedFromBloodRelativeOrMarriage: values.purchasedFromBloodRelativeOrMarriage === 'yes',
			snapAuthorized: values.snapAuthorized === 'yes',
			twentyFourSeven: values.twentyFourSeven === 'yes',
			usdaOrTxSuspensionList: values.usdaOrTxSuspensionList === 'yes',
			posWicCertified: values.posWicCertified === 'yes',
			splitTenderTransactions: values.splitTenderTransactions === 'yes',
			locationPriorToCurrentOwnership: values.locationPriorToCurrentOwnership === 'yes',
			stateOfTexas: values.stateOfTexas === 'yes',
			itemizedReceipts: values.itemizedReceipts === 'yes'
		};
		if (formattedValues.expirationDate === '') {
			delete formattedValues.expirationDate;
		}
		if (formattedValues.vendorOpeningDate === '') {
			delete formattedValues.vendorOpeningDate;
		}
		if (formattedValues.primaryContact) {
			if (
				formattedValues.primaryContact.firstName === '' &&
				formattedValues.primaryContact.lastName === '' &&
				formattedValues.primaryContact.title === '' &&
				formattedValues.primaryContact.email === '' &&
				formattedValues.primaryContact.phone
			) {
				delete formattedValues.primaryContact;
			}
		}

		if (
			formattedValues.primaryContact &&
			!Object.keys(formattedValues.primaryContact).some(x => formattedValues.primaryContact[x] !== undefined)
		) {
			delete formattedValues.primaryContact;
		}

		if (formattedValues.complianceContact) {
			if (
				formattedValues.complianceContact.firstName === '' &&
				formattedValues.complianceContact.lastName === '' &&
				formattedValues.complianceContact.title === '' &&
				formattedValues.complianceContact.email === '' &&
				formattedValues.complianceContact.phone
			) {
				delete formattedValues.complianceContact;
			}
		}

		if (
			formattedValues.complianceContact &&
			!Object.keys(formattedValues.complianceContact).some(x => formattedValues.complianceContact[x] !== undefined)
		) {
			delete formattedValues.complianceContact;
		}

		if (formattedValues.numberEbtSnapEnabledRegisters === '') {
			delete formattedValues.numberEbtSnapEnabledRegisters
		}
		if (formattedValues.numberOfUnapprovedSelfCheckoutRegisters === '') {
			delete formattedValues.numberOfUnapprovedSelfCheckoutRegisters
		}
		if (formattedValues.numberOfWicEnabledRegisters === '') {
			delete formattedValues.numberOfWicEnabledRegisters
		}
		if (formattedValues.numberOfWicEnabledSelfCheckoutRegisters === '') {
			delete formattedValues.numberOfWicEnabledSelfCheckoutRegisters
		}
		if (formattedValues.totalNumberOfRegisters === '') {
			delete formattedValues.totalNumberOfRegisters
		}

		mutation.mutate({
			id: application.id,
			payload: formattedValues
		});
	};

	return (
		<ApplicationStep
			backText={isDialog ? 'Cancel' : 'Back'}
			onClickBack={onClickBackSuccess}
			nextText={isDialog ? 'Save & Close' : 'Next'}
			onClickNext={form.handleSubmit(onSubmit)}
			isNextDisabled={mutation.isLoading}
			isNextLoading={mutation.isLoading}
		>
			<FormProvider {...form}>
				<Box display="flex" flex={1} justifyContent="space-between" data-testid="vendor-step-details-tab">
					<Box display="flex" flex={1} pr={3} flexDirection={'column'}>
						<Tabs
							value={tabValue}
							onChange={handleChange}
							textColor="secondary"
							indicatorColor="secondary"
							data-testid="add-edit-vendor"
							sx={{ marginBottom: 2, position: 'sticky', top: 0, zIndex: 2, backgroundColor: 'white' }}
						>
							<Tab value={TAB_NAMES.DETAILS} label="Details" data-testid={`${TAB_NAMES.DETAILS}-tab`} />
							<Tab value={TAB_NAMES.CONTACTS} label="Contacts" data-testid={`${TAB_NAMES.CONTACTS}-tab`} />
							<Tab value={TAB_NAMES.DOCUMENTS} label="Documents" data-testid={`${TAB_NAMES.DOCUMENTS}-tab`} />
						</Tabs>
						<Box display="flex" flexDirection="column" flex={1}>
							{tabValue === TAB_NAMES.DETAILS && (
								<>
									<Direction
										title="Directions"
										content={<PortalContent contentKey="VendorPortal.VendorApplication.VendorStep.Direction" />}
									/>
									<VendorDetailsForm fieldMetaData={fieldMetaData} />
								</>
							)}
							{tabValue === TAB_NAMES.CONTACTS && (
								<ContactList
									applicationContacts
									canManageUsers
									fetchQuery={queries.getContacts(application.id)}
									contactAccessToggle={{ applicationId: application?.id, corporationId, vendorId }}
									handleAddEditClick={handleAddEditClick}
									actions={{ delete: true, onDelete: handleContactDelete }}
								/>
							)}
							{tabValue === TAB_NAMES.DOCUMENTS && <VendorDocumentation />}
						</Box>
						{addEditContactVisible && (
							<AddEditContact
								applicationContact
								record={{ ...addEditContact, editable: true }}
								isOpen={addEditContactVisible}
								onClose={handleAddEditContactClose}
								onUpdate={handleOnUpdate}
								isLoading={isLoading}
								isError={isError}
								errorMessage={errorMessage}
							/>
						)}
					</Box>
					{!isDialog && (
						<HorizontalAccordion title="Tasks" initialExpanded={application.status === 'Pending Clarification'}>
							<TaskList parentType={EntityType.Application} parentId={application.id} includeChildren={false} />
						</HorizontalAccordion>
					)}
				</Box>
			</FormProvider>
		</ApplicationStep>
	);
};
export default VendorDetails;
