import { FormControl, FormGroup, FormLabel, Switch } from '@mui/material';
import { Controller } from 'react-hook-form';

interface FormInputProps {
	name: string;
	label: string;
	setValue?: any;
	control?: any;
	disabled?: boolean;
	testId?: string;
	onChange?: () => void;
}

export const FormSwitch = ({ name, testId, control, disabled, label }: FormInputProps) => {
	return (
		<FormControl fullWidth margin="normal" variant="standard">
			<FormLabel>{label}</FormLabel>
			<FormGroup sx={{ ml: -1 }}>
				<Controller
					name={name}
					data-testid={testId}
					control={control}
					render={({ field: { onChange, value } }) => (
						<Switch color="success" disabled={disabled} checked={value} onChange={onChange} />
					)}
				/>
			</FormGroup>
		</FormControl>
	);
};
