import axios from "axios";
import { PortalContent as Content, Response, parseResponse } from '../models';
import { useQuery } from "@tanstack/react-query";

interface PortalContentProps {
    contentKey: string
}

export const PortalContent = ({ contentKey }: PortalContentProps) => {
    const fetchContent = () =>
        axios.get<Response<Content>>(`/api/portalcontent/${contentKey}`).then(parseResponse);

    const { isLoading, isError, data } = useQuery({
        queryKey: ['portalcontent', contentKey],
        queryFn: () => fetchContent(),
        cacheTime: Infinity,
        staleTime: Infinity,
    });

    if (isLoading){
        return (<div>Loading...</div>)
    }

    if (isError) {
        return (<div>Error fetching content.</div>)
    }

    return (
        <div dangerouslySetInnerHTML={{ __html: data.content ?? '' }} />
    );
}