import { useParams } from 'react-router';
import { NewApplicationFlow } from '../components/Applications/NewApplicationFlow';
import { ApplicationProvider } from '../contexts/ApplicationContext';

export const ApplicationPage = () => {

	const params = useParams()

	return (
		<ApplicationProvider applicationId={params.id!}>
			<NewApplicationFlow type='Corporate' />
		</ApplicationProvider>
	);
};
