import axios from 'axios';
import { createContext, useContext, PropsWithChildren } from 'react';
import { useQuery } from '@tanstack/react-query';

import { Application } from '../models/Application';
import { ErrorPage } from '../pages/Error';
import { LoadingPage } from '../pages/Loading';
import { Response, parseResponse } from '../models';

export const ApplicationsContext = createContext<Application[]>([]);

export const ApplicationsProvider: React.FC<PropsWithChildren> = ({ children }) => {

	const fetchApplications = () =>
		axios
			.get<Response<Application[]>>('/api/applications')
			.then(parseResponse);

	const applications = useQuery({
		queryKey: ['applications'],
		queryFn: () => fetchApplications()
	});

	if (applications.isFetching) {
		return <LoadingPage />;
	}

	if (!applications.isSuccess) {
		return <ErrorPage retry={applications.refetch} />;
	}

	return <ApplicationsContext.Provider value={applications.data}>{children}</ApplicationsContext.Provider>;
};

export const useApplications = () => useContext(ApplicationsContext);
