import { useState } from 'react';
import { Box } from '@mui/system';

import { useApplication } from '../../../../contexts/ApplicationContext';
import { MetadataProvider } from '../../../../contexts/MetadataContext';
import { ApplicationBase } from '../../../../models';
import { StepProps } from '../../NewApplicationFlow';
import { APPLICATION_TYPE } from '../../NewApplicationModal';
import { VendorList } from './VendorList';
import VendorDetails from './VendorDetails';
import { TaskList } from '../../../TaskList/TaskList';
import HorizontalAccordion from '../../../../surfaces/Accordion/HorizontalAccordion';
import { EntityType } from '../../../../models/EntityType';
import { ApplicationStep } from '../../ApplicationStep';
import { VendorDialog } from './VendorDialog';

export type HandleAddEditVendorClickParams = {
	data?: ApplicationBase;
};

const VendorStep = ({ onNextClick, onPrevClick }: StepProps) => {
	const application = useApplication();
	const [dialogVisible, setDialogVisible] = useState(false);
	const [vendorApplicationId, setVendorApplicationId] = useState<string>();

	const onAddVendorClickSuccess = (applicationId: string) => {
		setVendorApplicationId(applicationId);
		setDialogVisible(true);
	};

	const handleDialogClose = () => {
		setVendorApplicationId(undefined);
		setDialogVisible(false);
	};

	return (
		<>
			{application.type === APPLICATION_TYPE.CORP_VENDOR && (
				<ApplicationStep onClickBack={onPrevClick} onClickNext={onNextClick}>
					<Box display="flex" flex={1} justifyContent="space-between" data-testid="vendor-step-details-tab">
						<Box display="flex" flex={1} pr={3}>
							<>
								<VendorList data={application.children} onAddVendorClickSuccess={onAddVendorClickSuccess} />
								{dialogVisible && vendorApplicationId && (
									<VendorDialog
										parentApplicationId={application.id}
										applicationId={vendorApplicationId}
										handleDialogClose={handleDialogClose}
									/>
								)}
							</>
						</Box>
						<HorizontalAccordion title="Tasks" initialExpanded={application.status === 'Pending Clarification'}>
							<TaskList parentType={EntityType.Application} parentId={application.id} includeChildren isChild />
						</HorizontalAccordion>
					</Box>
				</ApplicationStep>
			)}
			{application.type === APPLICATION_TYPE.VENDOR && (
				<MetadataProvider>
					<VendorDetails onClickBackSuccess={onPrevClick} onClickNextSuccess={onNextClick} />
				</MetadataProvider>
			)}
		</>
	);
};
export default VendorStep;
