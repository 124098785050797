import { Outlet } from 'react-router';

import { Box } from '@mui/material';
import { ApplicationsProvider } from '../../contexts/ApplicationsContext';

export const ApplicationLayout = () => {
	return (
		<ApplicationsProvider>
			<Box display="flex" flexDirection="column" flex={1} overflow="auto">
				<Outlet />
			</Box>
		</ApplicationsProvider>
	);
};
