import { AppBar, Box, Button, Toolbar, Typography } from "@mui/material";
import { ReactComponent as Logo } from '../assets/images/logo.svg';
import { useAuth } from "react-oidc-context";

export const Header = () => {

    const auth = useAuth()

    const Actions = () => {
        if (auth.isLoading || auth.error) {
            return null
        }

        if (auth.isAuthenticated) {
            return (
                <Button variant="text" sx={{ color: (theme) => theme.palette.primary.light, fontWeight: 400 }} onClick={() => auth.signoutRedirect()}>Log out</Button>
            )
        }

        return null
    }

    return (
        <AppBar position="relative" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar>
                <Logo title="myWIC" />
                <Typography sx={{ px: 2, color: '#81629D' }}>|</Typography>
                <Typography variant="h1">
                    Vendor Portal
                </Typography>
                <Box flex={1}></Box>
                <Actions />
            </Toolbar>
        </AppBar>
    )
}