import { useState, useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Box, Typography, Grid, Button, Divider } from '@mui/material';

import { EditVendorDialog } from './EditVendorDialog';
import { Response, addressToString, parseResponse } from '../../models';
import { Vendor } from '../../models/Vendor';
import { LoadingPage } from '../../pages/Loading';
import { GridCell } from './GridCell';
import { useMetadata } from '../../contexts/MetadataContext';

interface VendorDetailsProps {
	corporationId: string;
	vendorId: string;
}

const formatDate = (rawDate?: string) => {
	if (!rawDate) return ''; // Handle undefined or null case
	const date = new Date(rawDate);
	return date.toLocaleDateString();
};

export const VendorProfileDetails = ({ corporationId, vendorId }: VendorDetailsProps) => {
	const { metadata } = useMetadata();

	const [editableVendorField, setEditableVendorField] = useState<Vendor>();
	const [editVendorDetails, setEditVendorDetails] = useState(false);

	const fetchVendorProfileDetails = (corporationId: string, vendorId: string) =>
		axios.get<Response<Vendor>>(`/api/corporations/${corporationId}/vendors/${vendorId}`).then(parseResponse);

	const { data, isFetching } = useQuery({
		queryKey: ['vendors', corporationId, vendorId],
		queryFn: () => fetchVendorProfileDetails(corporationId, vendorId)
	});

	const profileData: any = useMemo(() => {
		if (data) {
			const formattedData: Vendor = {
				...data,
				contractStartDate: formatDate(data?.contractStartDate),
				contractEndDate: formatDate(data?.contractEndDate)
			};
			return formattedData;
		}
		return null;
	}, [data]);

	const mailingAddressDetails = addressToString(profileData?.mailingAddress)
	const storeAddressDetails = addressToString(profileData?.storeAddress)

	if (isFetching) {
		return <LoadingPage />;
	}

	const handleVendorDialogClose = () => {
		setEditableVendorField(undefined);
		setEditVendorDetails(false);
	};

	return (
		<>
			<Box display="flex" flexDirection="column">
				<Box display="flex" gap={4} alignItems="center">
					<Typography variant="h2" fontSize={18} color="#B84787">
						Basic Information
					</Typography>
					<Button
						variant="outlined"
						color="secondary"
						size="medium"
						sx={{ py: '3px', mb: 2 }}
						onClick={() => setEditVendorDetails(true)}
						data-testid="button-open"
					>
						Edit Vendor Details
					</Button>
				</Box>
				<Grid container spacing={2}>
					<GridCell title="Vendor ID" value={profileData?.vendorId} />
					<GridCell title="Corporate ID" value={profileData?.corporateId} />
					<GridCell title="Vendor Type" value={profileData?.vendorType} />
					<GridCell title="Corporate Name" value={profileData?.corporateName} />
					<GridCell title="Vendor Name" value={profileData?.name} />
					<GridCell title="Primary Contact" value={profileData?.primaryContact} />
					<GridCell title="Primary Phone" value={profileData?.primaryPhone} />
					<GridCell title="Fax Number" value={profileData?.faxNumber} />
					<GridCell title="Email" value={profileData?.email} />
					<GridCell title="Mail Code" value={profileData?.mailCode} />
					<GridCell title="Store Address" value={storeAddressDetails} />
					<GridCell title="Store Square Footage" value={profileData?.storeSquareFootage} />
					<GridCell title="Website" value={profileData?.storeWebsite} />
					<GridCell title="Mailing Address" value={mailingAddressDetails} />
					<GridCell
						title="Training Completed"
						value={
							profileData?.trainingCompleted !== undefined ? (profileData.trainingCompleted ? 'True' : 'False') : '--'
						}
					/>
				</Grid>
			</Box>
			<Divider sx={{ my: 2 }} />
			<Box display="flex" flexDirection="column">
				<Box display="flex" gap={5}>
					<Typography variant="h2" fontSize={18} color="#B84787">
						Transaction Information
					</Typography>
				</Box>
				<Grid container spacing={2}>
					<GridCell title="Total Registers" value={profileData?.totalNoRegisters} />
					<GridCell title="WIC Enabled Registers" value={profileData?.wicEnabledRegisters} />
					<GridCell title="Annual Gross food sales" value={profileData?.annualGrossFoodSales} />
					<GridCell title="Total Annual Sales" value={profileData?.totalAnnualSales} />
					<GridCell title="Snap ID" value={profileData?.snapId} />
					<GridCell title="State ID" value={profileData?.stateId} />
					<GridCell title="Peer Group" value={profileData?.peerGroup} />
					<GridCell title="Price Region" value={profileData?.priceRegion} />
					<GridCell title="Volume Band" value={profileData?.salesVolumeBand} />
					{metadata.some(item => item.name === 'VendorPortal.VendorDetails.ContractStartDate' && item.visible) && (
						<GridCell title="Start Date" value={profileData?.contractStartDate} />
					)}
					{metadata.some(item => item.name === 'VendorPortal.VendorDetails.ContractEndDate' && item.visible) && (
						<GridCell title="End Date" value={profileData?.contractEndDate} />
					)}
				</Grid>
			</Box>
			<Divider sx={{ my: 2 }} />
			<Box display="flex" flexDirection="column">
				<Box display="flex" gap={5}>
					<Typography variant="h2" fontSize={18} color="#B84787">
						Store Hours
					</Typography>
				</Box>
				<Grid container spacing={2}>
					<GridCell title="Sunday" value={profileData?.storeHours?.sunday} />
					<GridCell title="Monday" value={profileData?.storeHours?.monday} />
					<GridCell title="Tuesday" value={profileData?.storeHours?.tuesday} />
					<GridCell title="Wednesday" value={profileData?.storeHours?.wednesday} />
					<GridCell title="Thursday" value={profileData?.storeHours?.thursday} />
					<GridCell title="Friday" value={profileData?.storeHours?.friday} />
					<GridCell title="Saturday" value={profileData?.storeHours?.saturday} />
				</Grid>
			</Box>
			<EditVendorDialog
				profileData={profileData}
				corporationId={corporationId}
				vendorId={vendorId}
				vendor={editableVendorField}
				isOpen={editVendorDetails}
				onClose={handleVendorDialogClose}
			/>
		</>
	);
};
