import { Switch, Tooltip } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useUser } from '../contexts/UserContext';
import { Response, parseResponse } from '../models';
import { Contact } from '../models/Contact';

interface ContactAccessToggleProps {
	applicationId?: string;
	corporationId?: string;
	vendorId?: string;
	contactId: string;
}

export const ContactAccessToggle = ({
	applicationId,
	corporationId,
	vendorId,
	contactId
}: ContactAccessToggleProps) => {
	const { enqueueSnackbar } = useSnackbar();
	const user = useUser();
	const queryClient = useQueryClient();

	const queryKey = applicationId ? ['applicationContact', applicationId] : ['contacts', corporationId, vendorId];

	const contacts = queryClient.getQueryData<Contact[]>(queryKey);
	const contact = contacts?.find(x => x.id === contactId);

	const updateContact = (enable: boolean) => {
		const url = applicationId
			? `/api/applications/${applicationId}/contacts/${contactId}`
			: `/api/corporations/${corporationId}${
					vendorId === undefined ? '' : '/vendors/' + vendorId
			  }/contacts/${contactId}`;

		return axios.post<Response<Contact>>(url, { enabled: enable }).then(parseResponse);
	};

	const { mutate, isLoading } = useMutation(updateContact, {
		mutationKey: queryKey,
		onMutate: async (isEnabled: boolean) => {
			// Cancel any outgoing queries so they don't overwrite our optimistic update
			await queryClient.cancelQueries({ queryKey });

			queryClient.setQueryData<Contact[]>(queryKey, oldData => {
				var contacts = JSON.parse(JSON.stringify(oldData ?? [])) as Contact[];
				var contact = contacts.find(x => x.id === contactId);
				if (contact) {
					contact.enabled = isEnabled;
				}

				return contacts;
			});
		},
		onError: (err, enabled) => {
			// Revert the optimistic update from the onMutate callback
			queryClient.setQueryData<Contact[]>(queryKey, oldData => {
				var contacts = JSON.parse(JSON.stringify(oldData ?? [])) as Contact[];
				var contact = contacts.find(x => x.id === contactId);
				if (contact) {
					contact.enabled = !enabled;
				}
				return contacts;
			});

			const message = (err as Error)?.message ?? 'Please try again later.';
			enqueueSnackbar(`Update failed for ${contact?.firstName}. ${message}`, { variant: 'error' });
		}
	});

	return (
		<Tooltip
			title={
				contactId === user.id
					? 'You cannot disable yourself.'
					: !applicationId && !contact?.editable
					? 'This contact is read-only.'
					: null
			}
		>
			<div>
				<Switch
					color="success"
					data-testid={`contact-switch-${contactId}`}
					name={contactId}
					checked={contact?.enabled}
					disabled={isLoading || contactId === user.id || (!applicationId && !contact?.editable)}
					onChange={event => mutate(event.target.checked)}
				/>
			</div>
		</Tooltip>
	);
};
