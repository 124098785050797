import { useEffect, useMemo, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';

import { VendorSelector } from '../components/VendorSelector';
import { Page } from '../components/Page';
import { useRelationship } from '../contexts/RelationshipContext';
import { useConfiguration } from '../contexts/ConfigurationContext';
import { FEATURES } from '../constants/features';
import { ContactList } from '../components/Contact/ContactList/ContactList';
import { ProfileDetails } from '../components/ProfileDetails';
import { TrainingList } from '../components/Training/TrainingList/TrainingList';
import { VendorProfileDetails } from '../components/Vendor/VendorProfileDetails';
import { MetadataProvider } from '../contexts/MetadataContext';
import { VendorDisqualificationList } from '../components/Disqualification/DisqualificationList/VendorDisqualificationList';
import { useUser } from '../contexts/UserContext';
import { PRIVILEGES } from '../constants/privileges';
import queries, { useCreateContactMutation, useUpdateContactMutation } from '../components/Contact/queries';
import { User } from '../models';
import { useQueryClient } from '@tanstack/react-query';
import { AddEditContact } from '../components/Contact/AddEditContact/AddEditContact';
import { ContactProps, HandleAddEditClickParams } from '../components/Contact/types';

const TAB_NAMES = {
	PROFILE_DETAILS: 'profile-details',
	CONTACT_LIST: 'contact-list',
	TRAINING: 'training',
	DISQUALIFICATIONS: 'disqualification'
};

export const DashboardPage = () => {
	const { features } = useConfiguration();
	const { corporationId, vendorId } = useRelationship();
	const user = useUser();
	const queryClient = useQueryClient();
	const canManageUsers = useMemo(() => {
		const corporation = user.relationships.find(x => x.id === corporationId);
		return corporation?.privileges.includes(PRIVILEGES.ManageContacts) ?? false;
	}, [user, corporationId]);

	const defaultTab = useMemo(() => {
		if (features.isEnabled(FEATURES.Profile)) {
			return TAB_NAMES.PROFILE_DETAILS;
		} else if (features.isEnabled(FEATURES.ContactManagement)) {
			return TAB_NAMES.CONTACT_LIST;
		} else if (vendorId && features.isEnabled(FEATURES.DashboardTraining)) {
			return TAB_NAMES.TRAINING;
		} else if (vendorId && undefined && features.isEnabled(FEATURES.DashboardDisqualification)) {
			return TAB_NAMES.DISQUALIFICATIONS;
		}
	}, [features, vendorId]);

	const [selectedTab, setSelectedTab] = useState(defaultTab);

	useEffect(() => {
		setSelectedTab(defaultTab);
	}, [defaultTab, vendorId]);

	const [addEditContact, setAddEditContact] = useState<ContactProps>();
	const [addEditContactVisible, setAddEditContactVisible] = useState(false);

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setSelectedTab(newValue);
	};

	const onSuccess = async (user: any) => {
		queryClient.setQueryData<User[]>(['contacts', corporationId, vendorId], oldData => {
			var users = JSON.parse(JSON.stringify(oldData ?? [])) as User[];
			var userIndex = users?.findIndex(x => x?.id === user?.id);
			if (userIndex >= 0) {
				return [...users?.slice(0, userIndex), user, ...users?.slice(userIndex + 1)];
			}

			return [...users, user];
		});

		setAddEditContactVisible(false);
	};

	const createContactMutation = useCreateContactMutation(corporationId, vendorId, {
		onSuccess
	});

	const updateContactMutation = useUpdateContactMutation(corporationId, vendorId, {
		onSuccess
	});

	const handleOnUpdate = (isEdit: boolean, formData: any) => {
		if (isEdit) {
			updateContactMutation.mutate(formData);
			return;
		}

		createContactMutation.mutate(formData);
	};

	const handleAddEditContactClose = () => {
		setAddEditContact(undefined);
		setAddEditContactVisible(false);
	};

	const isLoading = updateContactMutation?.isLoading || createContactMutation?.isLoading;
	const isError = updateContactMutation?.isError || createContactMutation?.isError;
	const errorMessage = updateContactMutation?.error || createContactMutation?.error;

	const handleAddEditClick = ({ isOpen, data }: HandleAddEditClickParams) => {
		if (isOpen) {
			setAddEditContactVisible(true);
		}

		if (data) {
			setAddEditContact(data);
		}
	};

	return (
		<Page>
			<VendorSelector>
				{corporationId && (
					<>
						<Tabs
							value={selectedTab}
							onChange={handleChange}
							textColor="secondary"
							indicatorColor="secondary"
							sx={{ py: 3 }}
							data-testid="dashboard-page"
						>
							{features.isEnabled(FEATURES.Profile) && (
								<Tab
									value={TAB_NAMES.PROFILE_DETAILS}
									label="Details"
									data-testid={`${TAB_NAMES.PROFILE_DETAILS}-tab`}
								/>
							)}
							{features.isEnabled(FEATURES.ContactManagement) && (
								<Tab value={TAB_NAMES.CONTACT_LIST} label="Contacts" data-testid={`${TAB_NAMES.CONTACT_LIST}-tab`} />
							)}
							{vendorId !== undefined && features.isEnabled(FEATURES.DashboardTraining) && (
								<Tab value={TAB_NAMES.TRAINING} label="Training" data-testid={`${TAB_NAMES.TRAINING}-tab`} />
							)}
							{vendorId !== undefined && features.isEnabled(FEATURES.DashboardDisqualification) && (
								<Tab
									value={TAB_NAMES.DISQUALIFICATIONS}
									label="Disqualifications"
									data-testid={`${TAB_NAMES.DISQUALIFICATIONS}-tab`}
								/>
							)}
						</Tabs>
						<Box display="flex" flexDirection="column" flex={1}>
							{
								<MetadataProvider>
									{selectedTab === TAB_NAMES.PROFILE_DETAILS && corporationId && !vendorId && (
										<ProfileDetails
											corporationId={corporationId}
											data-testid={`${TAB_NAMES.PROFILE_DETAILS}-tab-detail`}
										/>
									)}
									{vendorId && selectedTab === TAB_NAMES.PROFILE_DETAILS && corporationId && (
										<VendorProfileDetails
											corporationId={corporationId}
											vendorId={vendorId}
											data-testid={`${TAB_NAMES.PROFILE_DETAILS}-tab-detail`}
										/>
									)}
								</MetadataProvider>
							}
							{selectedTab === TAB_NAMES.CONTACT_LIST && (
								<ContactList
									canManageUsers={canManageUsers}
									fetchQuery={queries.getContacts(corporationId, vendorId)}
									contactAccessToggle={{ corporationId, vendorId }}
									handleAddEditClick={handleAddEditClick}
									data-testid={`${TAB_NAMES.CONTACT_LIST}-tab-detail`}
								/>
							)}
							{vendorId && selectedTab === TAB_NAMES.TRAINING && (
								<TrainingList
									corporationId={corporationId}
									vendorId={vendorId ?? ''}
									data-testid={`${TAB_NAMES.TRAINING}-tab-detail`}
								/>
							)}
							{vendorId && selectedTab === TAB_NAMES.DISQUALIFICATIONS && (
								<VendorDisqualificationList
									corporationId={corporationId}
									vendorId={vendorId ?? ''}
									data-testid={`${TAB_NAMES.DISQUALIFICATIONS}-tab-detail`}
								/>
							)}
						</Box>
					</>
				)}
			</VendorSelector>
			{addEditContactVisible && (
				<AddEditContact
					record={addEditContact}
					isOpen={addEditContactVisible}
					onClose={handleAddEditContactClose}
					onUpdate={handleOnUpdate}
					isLoading={isLoading}
					isError={isError}
					errorMessage={errorMessage}
				/>
			)}
		</Page>
	);
};
