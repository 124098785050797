import { createContext, useContext, PropsWithChildren, useMemo, useState } from 'react';
import { UserRelationship } from '../models';

type RelationshipContextType = {
    relationship?: UserRelationship
    setRelationship: (relationship?: UserRelationship) => void
    corporationId?: string
    vendorId?: string
}

export const RelationshipContext = createContext<RelationshipContextType>({
    setRelationship: () => { },
});
export const RelationshipProvider: React.FC<PropsWithChildren> = ({ children }) => {

    const [relationship, setRelationship] = useState<UserRelationship>()

    const value = useMemo(() => ({
        relationship: relationship,
        setRelationship: setRelationship,
        corporationId: relationship?.parentId ?? relationship?.id,
        vendorId: relationship?.parentId !== undefined ? relationship.id : undefined
    }), [relationship, setRelationship])

    return (
        <RelationshipContext.Provider value={value}>
            {children}
        </RelationshipContext.Provider>
    );
}

export const useRelationship = () => useContext(RelationshipContext)