import { Box, FormControl, InputAdornment, List, ListItem, ListItemButton, ListItemText, TextField } from "@mui/material"
import { PropsWithChildren, useEffect, useMemo, useState } from "react"
import { CorporationSelector } from "./CorporationSelector"
import { useRelationship } from "../contexts/RelationshipContext"
import { useUser } from "../contexts/UserContext"
import { Search } from "@mui/icons-material"

export const VendorSelector: React.FC<PropsWithChildren> = ({ children }) => {

    const { relationships } = useUser()
    const { relationship, setRelationship } = useRelationship()
    const corporation = useMemo(() => relationships.find(x => x.id === (relationship?.parentId ?? relationship?.id)), [relationships, relationship])

    const [searchText, setSearchText] = useState('')

    useEffect(() => {
        setSearchText('')
    }, [corporation])

    return (
        <Box display='flex' flex={1} flexDirection='row'>
            <Box width={300} minWidth={284} height='100%' borderRight={1} borderColor='#F2EAE2' p={3} pr={0}>
                <CorporationSelector />
                <FormControl size="small" fullWidth sx={{ pr: 3, mb: 2 }}>
                    <TextField size="small" placeholder="Search..." onChange={(event) => setSearchText(event.target.value)} value={searchText}
                        InputProps={{ startAdornment: null, endAdornment: <InputAdornment position="end"><Search /></InputAdornment> }} />
                </FormControl>
                <List >
                    {corporation && !corporation.isVirtual && <ListItem disablePadding sx={{ borderStartStartRadius: '200px' }}>
                        <ListItemButton selected={corporation.id === relationship?.id} onClick={() => setRelationship(corporation)} sx={{ borderStartStartRadius: 90, borderEndStartRadius: 90 }}>
                            <ListItemText>Corporate</ListItemText>
                        </ListItemButton>
                    </ListItem>}
                    {corporation && relationships.filter(x => x.parentId === corporation?.id && x.name.toUpperCase().includes(searchText.toUpperCase())).map(x => (
                        <ListItem key={x.id} disablePadding sx={{ borderStartStartRadius: '200px' }}>
                            <ListItemButton selected={x.id === relationship?.id} onClick={() => setRelationship(x)} sx={{ borderStartStartRadius: 90, borderEndStartRadius: 90 }}>
                                <ListItemText>{x.name}</ListItemText>
                            </ListItemButton>
                        </ListItem>
                    )
                    )}
                </List>
            </Box>
            <Box flex={1} display='flex' flexDirection='column' minWidth={0} pl={3}>
                {children}
            </Box>
        </Box>
    )
}