import * as yup from 'yup';
import { Alert, Box, Button, Divider, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { useQueryClient } from '@tanstack/react-query';

import ActionDialog from '../Dialog/ActionDialog';
import { Vendor } from '../../models/Vendor';
import { FormInputText } from '../Fields/FormInputText';
import { useUpdateVendorDetailsMutation } from './queries';
import { validatePhoneNumber, validateEmail, validateZipCode } from '../../utils/ValidationUtils';

const {
	primaryPhone,
	faxNumber,
	email,
	mailingAddress,
	storeHours,
	storeWebsite,
	totalNoRegisters,
	wicEnabledRegisters,
	annualGrossFoodSales,
	totalAnnualSales
} = {
	primaryPhone: {
		name: 'primaryPhone',
		label: 'Primary Phone',
		value: '',
		requiredValidationMessage: 'Primary Phone is required'
	},
	faxNumber: {
		name: 'faxNumber',
		label: 'Fax Number',
		value: '',
		requiredValidationMessage: 'Fax Number is required'
	},
	email: {
		name: 'email',
		label: 'Email',
		value: '',
		requiredValidationMessage: 'Email is required'
	},
	mailingAddress: {
		line1: {
			name: 'mailingAddress.line1',
			label: 'Mailing Address Line 1'
		},
		line2: {
			name: 'mailingAddress.line2',
			label: 'Mailing Address Line 2'
		},
		city: {
			name: 'mailingAddress.city',
			label: 'City'
		},
		state: {
			name: 'mailingAddress.state',
			label: 'State'
		},
		zipCode: {
			name: 'mailingAddress.zipCode',
			label: 'ZIP Code'
		}
	},
	storeHours: {
		sunday: {
			name: 'storeHours.sunday',
			label: 'Sunday',
			value: ''
		},
		monday: {
			name: 'storeHours.monday',
			label: 'Monday',
			value: ''
		},
		tuesday: {
			name: 'storeHours.tuesday',
			label: 'Tuesday',
			value: ''
		},
		wednesday: {
			name: 'storeHours.wednesday',
			label: 'Wednesday',
			value: ''
		},
		thursday: {
			name: 'storeHours.thursday',
			label: 'Thursday',
			value: ''
		},
		friday: {
			name: 'storeHours.friday',
			label: 'Friday',
			value: ''
		},
		saturday: {
			name: 'storeHours.saturday',
			label: 'Saturday',
			value: ''
		}
	},
	storeWebsite: { name: 'storeWebsite', label: 'Website', value: '' },
	totalNoRegisters: {
		name: 'totalNoRegisters',
		label: 'Total Registers',
		value: ''
	},
	wicEnabledRegisters: {
		name: 'wicEnabledRegisters',
		label: 'WIC Enabled Registers',
		value: ''
	},
	annualGrossFoodSales: {
		name: 'annualGrossFoodSales',
		label: 'Annual Gross food sales',
		value: ''
	},
	totalAnnualSales: {
		name: 'totalAnnualSales',
		label: 'Total Annual Sales',
		value: ''
	}
};

const schema = yup.object().shape({
	primaryPhone: validatePhoneNumber.required(primaryPhone.requiredValidationMessage),
	faxNumber: validatePhoneNumber.required(faxNumber.requiredValidationMessage),
	email: validateEmail.required(email.requiredValidationMessage),
	totalNoRegisters: yup
		.string()
		.matches(/^\d{1,5}$/, `${totalNoRegisters.label} must be a string with at most 5 digits`)
		.required(`${totalNoRegisters.label} is required`),
	wicEnabledRegisters: yup
		.string()
		.matches(/^\d{1,5}$/, `${wicEnabledRegisters.label} must be a string with at most 5 digits`)
		.required(`${wicEnabledRegisters.label} is required`),
	annualGrossFoodSales: yup.number().required(`${annualGrossFoodSales.label} is required`),
	totalAnnualSales: yup.number().required(`${totalAnnualSales.label} is required`),
	mailingAddress: yup.object().shape({
		line1: yup.string().required(`${mailingAddress.line1.label} is required`),
		line2: yup.string(),
		city: yup.string().required(`${mailingAddress.city.label} is required`),
		state: yup.string().required(`${mailingAddress.state.label} is required`),
		zipCode: validateZipCode.required(`${mailingAddress.zipCode.label} is required`)
	})
});

export const EditVendorDialog = ({ corporationId, vendorId, profileData, onClose, isOpen }: any) => {
	const queryClient = useQueryClient();
	const isEdit = !!profileData?.id;

	const defaultValues = {
		primaryPhone: profileData?.primaryPhone || primaryPhone.value,
		faxNumber: profileData?.faxNumber || faxNumber.value,
		email: profileData?.email || email.value,
		storeWebsite: storeWebsite.value,
		totalNoRegisters: totalNoRegisters.value,
		wicEnabledRegisters: wicEnabledRegisters.value,
		annualGrossFoodSales: annualGrossFoodSales.value,
		totalAnnualSales: totalAnnualSales.value,
		storeHours: {
			sunday: profileData?.storeHours?.sunday || storeHours.sunday.value,
			monday: profileData?.storeHours?.monday || storeHours.monday.value,
			tuesday: profileData?.storeHours?.tuesday || storeHours.tuesday.value,
			wednesday: profileData?.storeHours?.wednesday || storeHours.wednesday.value,
			thursday: profileData?.storeHours?.thursday || storeHours.thursday.value,
			friday: profileData?.storeHours?.friday || storeHours.friday.value,
			saturday: profileData?.storeHours?.saturday || storeHours.saturday.value
		}
	};

	const onSuccess = async (updatedVendor: Vendor) => {
		queryClient.setQueryData<Vendor[]>(['vendors', corporationId, vendorId], oldData => {
			if (!Array.isArray(oldData)) {
				return [updatedVendor];
			}

			const existingVendor = oldData.find(vendor => vendor.id === updatedVendor.id);
			if (!existingVendor) {
				return [updatedVendor, ...oldData];
			}

			return oldData.map(vendor => (vendor.id === updatedVendor.id ? updatedVendor : vendor));
		});

		queryClient.invalidateQueries(['vendors', corporationId, vendorId]);
		handleClose();
	};

	const updateVendorMutation = useUpdateVendorDetailsMutation(corporationId, vendorId, {
		onSuccess
	});

	const { handleSubmit, control } = useForm<Vendor | any>({
		defaultValues: profileData ?? defaultValues,
		resolver: yupResolver(schema)
	});

	const handleClose = () => {
		if (onClose) {
			onClose();
		}
	};

	const onSubmit = (formData: Vendor) => {
		if (isEdit) {
			updateVendorMutation.mutate(formData);
			return;
		}
	};

	const isLoading = updateVendorMutation?.isLoading;
	const isError = updateVendorMutation?.isError;
	const errorMessage = updateVendorMutation?.error;

	const btnList = [
		<Button onClick={onClose} color="secondary" autoFocus data-testid="edit-vendor-cancel-btn">
			Cancel
		</Button>,
		<LoadingButton
			type="submit"
			loading={isLoading}
			variant="contained"
			color="secondary"
			data-testid="button-submit"
			onClick={() => handleSubmit(onSubmit)()}
		>
			Submit
		</LoadingButton>
	];

	return (
		<ActionDialog open={isOpen} title={'Edit Vendor Details'} onClose={onClose} buttons={btnList}>
			{isError && (
				<Alert severity="error" sx={{ mb: 3 }}>
					{(errorMessage as Error)?.message}
				</Alert>
			)}
			<form onSubmit={handleSubmit(onSubmit)}>
				<Box display="flex" flexDirection="column">
					<Box display="flex" gap={3}>
						<FormInputText
							required
							name={primaryPhone.name}
							control={control}
							label={primaryPhone.label}
							testId={primaryPhone.name}
						/>
						<FormInputText
							required
							name={faxNumber.name}
							control={control}
							label={faxNumber.label}
							testId={faxNumber.name}
						/>
					</Box>
					<FormInputText required name={email.name} control={control} label={email.label} testId={email.name} />
					<FormInputText
						testId={storeWebsite.name}
						name={storeWebsite.name}
						control={control}
						label={storeWebsite.label}
					/>
					<Box display="flex" gap={3}>
						<FormInputText
							testId={totalNoRegisters.name}
							name={totalNoRegisters.name}
							control={control}
							label={totalNoRegisters.label}
						/>
						<FormInputText
							testId={wicEnabledRegisters.name}
							name={wicEnabledRegisters.name}
							control={control}
							label={wicEnabledRegisters.label}
						/>
					</Box>
					<Box display="flex" gap={3}>
						<FormInputText
							testId={annualGrossFoodSales.name}
							name={annualGrossFoodSales.name}
							control={control}
							label={annualGrossFoodSales.label}
						/>
						<FormInputText
							testId={totalAnnualSales.name}
							name={totalAnnualSales.name}
							control={control}
							label={totalAnnualSales.label}
						/>
					</Box>
					<Grid item xs={12} py={2}>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<Typography variant="body2" fontWeight={700}>
							Mailing Address
						</Typography>
					</Grid>
					<Box display="flex" gap={3}>
						<FormInputText
							required
							name={mailingAddress.line1.name}
							control={control}
							label={mailingAddress.line1.label}
							testId={mailingAddress.line1.name}
						/>
						<FormInputText
							name={mailingAddress.line2.name}
							control={control}
							label={mailingAddress.line2.label}
							testId={mailingAddress.line2.name}
						/>
					</Box>
					<FormInputText
						required
						name={mailingAddress.city.name}
						control={control}
						label={mailingAddress.city.label}
						testId={mailingAddress.city.name}
					/>
					<FormInputText
						required
						name={mailingAddress.state.name}
						control={control}
						label={mailingAddress.state.label}
						testId={mailingAddress.state.name}
					/>
					<FormInputText
						required
						name={mailingAddress.zipCode.name}
						control={control}
						label={mailingAddress.zipCode.label}
						testId={mailingAddress.zipCode.name}
					/>
					<Grid item xs={12} py={2}>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<Typography variant="body2" fontWeight={700}>
							Vendor Hours
						</Typography>
					</Grid>
					<Box display="flex" gap={3}>
						<FormInputText
							testId={storeHours.sunday.name}
							name={storeHours.sunday.name}
							control={control}
							label={storeHours.sunday.label}
						/>
						<FormInputText
							testId={storeHours.monday.name}
							name={storeHours.monday.name}
							control={control}
							label={storeHours.monday.label}
						/>
					</Box>
					<Box display="flex" gap={3}>
						<FormInputText
							testId={storeHours.tuesday.name}
							name={storeHours.tuesday.name}
							control={control}
							label={storeHours.tuesday.label}
						/>
						<FormInputText
							testId={storeHours.wednesday.name}
							name={storeHours.wednesday.name}
							control={control}
							label={storeHours.wednesday.label}
						/>
					</Box>
					<Box display="flex" gap={3}>
						<FormInputText
							testId={storeHours.thursday.name}
							name={storeHours.thursday.name}
							control={control}
							label={storeHours.thursday.label}
						/>
						<FormInputText
							testId={storeHours.friday.name}
							name={storeHours.friday.name}
							control={control}
							label={storeHours.friday.label}
						/>
					</Box>
					<FormInputText
						testId={storeHours.saturday.name}
						name={storeHours.saturday.name}
						control={control}
						label={storeHours.saturday.label}
					/>
					<Grid item xs={12} py={2}>
						<Divider />
					</Grid>
				</Box>
			</form>
		</ActionDialog>
	);
};
