import { AxiosResponse } from "axios"

export class Response<TValue> {
    public success: boolean = true
    public code?: number
    public message?: string
    public value?: TValue

    public static succeeded = <TValue>(value: TValue) => {
        var response = new Response<TValue>()
        response.success = true
        response.value = value

        return response
    }

    public static failed = <TValue>(code: number, message: string) => {
        var response = new Response<TValue>()
        response.success = false
        response.code = code
        response.message = message

        return response
    }
}

export const parseResponse = async <T>(response: AxiosResponse<Response<T>, any>) => {
    const data = response.data

    if (!data.success) {
        throw new Error(data.message)
    }

    return data.value as T
}