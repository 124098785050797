import React from 'react';
import { Box, Typography } from '@mui/material';

import { TextField } from '../../Fields/TextField';
import { Checkbox } from '../../Fields';

interface VendorDetailsProps {
    isVendorInfoSameAsCorporate: boolean;
    handleCorporateVendorContactMatch: (event: React.ChangeEvent<HTMLInputElement>) => void;
    readOnly: boolean;
}

export const VendorDetails = ({ isVendorInfoSameAsCorporate, handleCorporateVendorContactMatch, readOnly }: VendorDetailsProps) => {
    return (
			<>
				<Typography variant="subtitle2" sx={{ fontWeight: 'bold', marginTop: '15px' }}>
					Vendor details
				</Typography>
				<Box>
					<TextField id="nameOfStore" label="Name of store" disabled={readOnly} required />
					<Checkbox
						onChange={handleCorporateVendorContactMatch}
						checked={isVendorInfoSameAsCorporate}
						id="corporateVendorContactMatch"
						label="Vendor information below is same as corporate"
						disabled={readOnly}
					/>
				</Box>
				<Box display="flex" gap={2}>
					<TextField
						id="vendorPrimaryContactFirstName"
						label="Primary Contact First Name"
						disabled={isVendorInfoSameAsCorporate || readOnly}
						required
						maxLength={100}
					/>
					<TextField
						id="vendorPrimaryContactLastName"
						label="Primary Contact Last Name"
						disabled={isVendorInfoSameAsCorporate || readOnly}
						required
						maxLength={100}
					/>
				</Box>
				<Box display="flex" gap={2}>
					<TextField
						id="vendorPrimaryContactEmail"
						label="Primary Contact Email"
						disabled={isVendorInfoSameAsCorporate || readOnly}
						required
						maxLength={100}
					/>
					<TextField
						id="vendorPrimaryContactPhone"
						label="Primary Contact Phone Number"
						disabled={isVendorInfoSameAsCorporate || readOnly}
						required
						validate={value =>
							!/\(([0-9]{3})\) ([0-9]{3})-([0-9]{4})/.test(value) ? 'Please use format (555) 123-4567.' : undefined
						}
						maxLength={14}
					/>
				</Box>
			</>
		);
};
