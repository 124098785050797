import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type DirectionProps = {
	title: string;
	content: JSX.Element | string;
};

export const Direction = ({ title, content }: DirectionProps) => {
	return (
		<Accordion>
			<AccordionSummary sx={{ minHeight: 0 }}
				expandIcon={<ExpandMoreIcon />}
				aria-controls="corporate-direction1-content"
				id="corporate-direction1-header"
			>
				<Typography fontWeight={700}>{title}</Typography>
			</AccordionSummary>
			<AccordionDetails>{typeof content === 'string' ? <Typography>{content}</Typography> : content}</AccordionDetails>
		</Accordion>
	);
};