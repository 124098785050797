import { createContext, useContext, PropsWithChildren, useMemo, useEffect } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Response, User, parseResponse } from '../models';
import { useRelationship } from './RelationshipContext';
import { appInsights } from '../App';
import { LoadingPage } from '../pages/Loading';
import { ErrorPage } from '../pages/Error';
import { Guid } from '../constants/resources';

type UserContextType = User

export const UserContext = createContext<UserContextType | undefined>(undefined);
export const UserProvider: React.FC<PropsWithChildren> = ({ children }) => {

    const { relationship, setRelationship } = useRelationship()

    const fetchUser = () => axios.get<Response<User>>('/api/users/me').then(parseResponse)

    const { isFetching, isError, data, refetch } = useQuery({
        queryKey: ['users', 'me'],
        queryFn: () => fetchUser(),
    })

    useEffect(() => {

        if (data) {
            appInsights.setAuthenticatedUserContext(data.id, data.tenantId)
        }

        if (relationship === undefined) {
            const corporation = data?.relationships?.find(x => x.parentId === undefined)
            if (corporation !== undefined) {
                if (corporation.isVirtual) {
                    const vendor = data?.relationships?.find(x => x.parentId === corporation.id)
                    if (vendor !== undefined) {
                        setRelationship(vendor)
                    }
                } else {
                    setRelationship(corporation)
                }
            }
        }
    }, [data, relationship, setRelationship])

    const value = useMemo(
			() => ({
				tenantId: data?.tenantId ?? Guid.Empty,
				id: data?.id ?? Guid.Empty,
				name: data?.name ?? 'Unknown User',
				email: data?.email ?? 'user@example.com',
				isEnabled: data?.isEnabled ?? false,
				relationships: data?.relationships ?? [],
				applications: data?.applications ?? []
			}),
			[data]
		);

    if (isFetching) {
        return (<LoadingPage />)
    }

    if (isError) {
        return (<ErrorPage retry={refetch} />)
    }

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
}

export const useUser = () => useContext(UserContext) as UserContextType