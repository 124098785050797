import * as yup from 'yup';
import { PHONE_NUMBER_REQUIRED_VALIDATION, ZIP_CODE_VALID_REQUIRED_VALIDATION } from '../../../../utils/constant';

export const fieldConfig = [
	{
		key: 'VendorInformation.LegalName',
		fieldName: 'vendorName',
		title: 'Vendor Name',
		value: '',
		validation: yup.string().required('Vendor Name is required')
	},
	{
		key: 'VendorInformation.VendorOpeningDate',
		fieldName: 'vendorOpeningDate',
		title: 'Vendor Opening Date',
		value: '',
		validation: yup.string().required('Vendor Opening Date is required')
	},
	{
		key: 'VendorInformation.VendorName',
		fieldName: 'vendorName',
		title: 'Vendor Name',
		value: '',
		validation: yup.string().required('Vendor Name is required')
	},
	{
		key: 'VendorInformation.VendorType',
		fieldName: 'vendorType',
		title: 'Vendor Type',
		value: '',
		validation: yup.string().required('Vendor Type is required')
	},
	{
		key: 'VendorInformation.PhoneNumber',
		fieldName: 'phone',
		title: 'Vendor Phone Number',
		value: '',
		validation: PHONE_NUMBER_REQUIRED_VALIDATION
	},
	{
		key: 'VendorInformation.FederalTaxNumber',
		fieldName: 'federalTaxId',
		title: 'Federal Tax ID Number',
		validation: yup.string().required('Federal Tax ID Number is required')
	},
	{
		key: 'VendorInformation.StateTaxNumber',
		fieldName: 'stateTaxId',
		title: 'State Tax ID Number',
		validation: yup.string().required('State Tax ID Number is required')
	},
	{
		key: 'VendorInformation.SquareFeetOfRetailSpace',
		fieldName: 'squareFootage',
		title: 'Square Feet of retail space',
		validation: yup.number().required('Square Feet of retail space is required')
	},
	{
		key: 'VendorInformation.ItemizedReceipt',
		fieldName: 'itemizedReceipts',
		title:
			'Does the store provide itemized receipts showing -  Date of purchase, description of each item, quantity of each item purchased, total sale amount',
		validation: yup.string().required('Field is required')
	},
	{
		key: 'VendorInformation.SplitTenderTransaction',
		fieldName: 'splitTenderTransactions',
		title: 'Does store perform split tender transactions?',
		validation: yup.string().required('Field is required')
	},
	{
		key: 'VendorInformation.PercentageBussinessOwned',
		fieldName: 'percentOfBusinessOwned',
		title: '% of business owned',
		validation: yup.number().required('Field is required')
	},
	{
		key: 'VendorInformation.OwnerSocialSecurityNumber',
		fieldName: 'ownerSsn',
		title: `Owner's social security #`,
		validation: yup.string().required('Field is required')
	},
	{
		key: 'VendorInformation.Contacts',
		fieldName: 'vendorContact',
		title: `Contacts`,
		validation: yup.string().required('Contacts is required')
	},
	{
		key: 'VendorInformation.HasAccessToComputerInternetEmail',
		fieldName: 'accessToComputerInternetEmail',
		title: 'Does the Vendor have access to a computer, the internet, an email account?',
		validation: yup.string().required('Field is required')
	},
	{
		key: 'VendorInformation.StoreSaleInclude',
		fieldName: 'storeSales',
		title: 'Store Sales include',
		validation: yup.string().required('Field is required')
	},
	{
		key: 'VendorInformation.DairySupplierOKLicense',
		fieldName: 'dairySupplierLicenseNumber',
		title: 'Is dairy supplier OK licensed?  If yes, include license #',
		validation: yup.string().required('Field is required')
	},
	{
		key: 'VendorInformation.LocationAccessibility',
		fieldName: 'accessible',
		title: 'Is this vendor location accessible to people with handicaps or disabilities?',
		validation: yup.string().required('Field is required')
	},
	{
		key: 'VendorInformation.OtherVendorLocations',
		fieldName: 'otherVendorLocations',
		title: 'Other Vendor Locations',
		validation: yup.string().required('Other Vendor Locations is required')
	},
	{
		key: 'VendorInformation.PhysicalAddress.StreetAddressLine1',
		fieldName: 'physicalAddress.line1',
		title: 'Street Address Line1',
		validation: yup.string().required('Street Address Line 1 is required')
	},
	{
		key: 'VendorInformation.PhysicalAddress.StreetAddressLine2',
		fieldName: 'physicalAddress.line2',
		title: 'Street Address Line2',
		validation: yup.string().required('Street Address Line 2 is required')
	},
	{
		key: 'VendorInformation.PhysicalAddress.City',
		fieldName: 'physicalAddress.city',
		title: 'City',
		validation: yup.string().required('City is required')
	},
	{
		key: 'VendorInformation.PhysicalAddress.County',
		fieldName: 'physicalAddress.county',
		title: 'County',
		validation: yup.string().required('County is required')
	},
	{
		key: 'VendorInformation.PhysicalAddress.State',
		fieldName: 'physicalAddress.state',
		title: 'State',
		validation: yup.string().required('State is required')
	},
	{
		key: 'VendorInformation.PhysicalAddress.ZipCode',
		fieldName: 'physicalAddress.zipCode',
		title: 'ZIP Code',
		validation: ZIP_CODE_VALID_REQUIRED_VALIDATION
	},
	{
		key: 'VendorInformation.SameAsPhysicalAddress',
		fieldName: 'mailingSameAsPhysical',
		title: 'Same as physical address',
		validation: yup.string().required('This is required')
	},
	{
		key: 'VendorInformation.MailingAddress.StreetAddressLine1',
		fieldName: 'mailingAddress.line1',
		title: 'Street Address Line 1',
		validation: yup.string().required('Street Address Line 1 is required')
	},
	{
		key: 'VendorInformation.MailingAddress.StreetAddressLine2',
		fieldName: 'mailingAddress.line2',
		title: 'Street Address Line 2',
		validation: yup.string().required('Street Address Line 2 is required')
	},
	{
		key: 'VendorInformation.MailingAddress.City',
		fieldName: 'mailingAddress.city',
		title: 'City',
		validation: yup.string().required('City is required')
	},
	{
		key: 'VendorInformation.MailingAddress.County',
		fieldName: 'mailingAddress.county',
		title: 'County',
		validation: yup.string().required('County is required')
	},
	{
		key: 'VendorInformation.MailingAddress.State',
		fieldName: 'mailingAddress.state',
		title: 'State',
		validation: yup.string().required('State is required')
	},
	{
		key: 'VendorInformation.MailingAddress.ZipCode',
		fieldName: 'mailingAddress.zipCode',
		title: 'ZIP Code',
		validation: ZIP_CODE_VALID_REQUIRED_VALIDATION
	},
	{
		key: 'VendorInformation.PrimaryContact.FirstName',
		fieldName: 'primaryContact.firstName',
		title: 'First Name',
		validation: yup.string().required('First name is required')
	},
	{
		key: 'VendorInformation.PrimaryContact.LastName',
		fieldName: 'primaryContact.lastName',
		title: 'Last Name',
		validation: yup.string().required('Last name is required')
	},
	{
		key: 'VendorInformation.PrimaryContact.Title',
		fieldName: 'primaryContact.title',
		title: 'Title',
		value: '',
		validation: yup.string().required('Title is required')
	},
	{
		key: 'VendorInformation.PrimaryContact.Email',
		fieldName: 'primaryContact.email',
		title: 'Email',
		value: '',
		validation: yup.string().email('Please enter a valid email.').required('Email is required')
	},
	{
		key: 'VendorInformation.PrimaryContact.Phone',
		fieldName: 'primaryContact.phone',
		title: 'Phone',
		value: '',
		validation: PHONE_NUMBER_REQUIRED_VALIDATION
	},
	{
		key: 'VendorInformation.PrimaryContact.Address.StreetAddressLine1',
		fieldName: 'primaryContact.address.line1',
		title: 'Street Address Line 1',
		validation: yup.string().required('Street Address Line 1 is required')
	},
	{
		key: 'VendorInformation.PrimaryContact.Address.StreetAddressLine2',
		fieldName: 'primaryContact.address.line2',
		title: 'Street Address Line 2',
		validation: yup.string().required('Street Address Line 2 is required')
	},
	{
		key: 'VendorInformation.PrimaryContact.Address.City',
		fieldName: 'primaryContact.address.city',
		title: 'City',
		validation: yup.string().required('City is required')
	},
	{
		key: 'VendorInformation.PrimaryContact.Address.County',
		fieldName: 'primaryContact.address.county',
		title: 'County',
		validation: yup.string().required('County is required')
	},
	{
		key: 'VendorInformation.PrimaryContact.Address.State',
		fieldName: 'primaryContact.address.state',
		title: 'State',
		validation: yup.string().required('State is required')
	},
	{
		key: 'VendorInformation.PrimaryContact.Address.ZipCode',
		fieldName: 'primaryContact.address.zipCode',
		title: 'ZIP Code',
		validation: ZIP_CODE_VALID_REQUIRED_VALIDATION
	},
	{
		key: 'VendorInformation.VendorManager.Name',
		fieldName: 'vendorName',
		title: 'Vendor Name',
		validation: yup.string().required('Vendor Name is required')
	},
	{
		key: 'VendorInformation.VendorManager.Email',
		fieldName: 'email',
		title: 'Vendor Email',
		validation: yup.string().required('Vendor Email is required')
	},
	{
		key: 'VendorInformation.VendorHours.Open',
		fieldName: 'twentyFourSeven',
		title: 'Open 7 days a week - 24 hours per day',
		validation: yup.string().required('This is required')
	},
	{
		key: 'VendorInformation.VendorHours.Sunday',
		fieldName: 'storeHours.sunday',
		title: 'Sunday',
		validation: yup.string().required('This field is required')
	},
	{
		key: 'VendorInformation.VendorHours.Monday',
		fieldName: 'storeHours.monday',
		title: 'Monday',
		validation: yup.string().required('This field is required')
	},
	{
		key: 'VendorInformation.VendorHours.Tuesday',
		fieldName: 'storeHours.tuesday',
		title: 'Tuesday',
		validation: yup.string().required('This field is required')
	},
	{
		key: 'VendorInformation.VendorHours.Wednsday',
		fieldName: 'storeHours.wednesday',
		title: 'Wednesday',
		validation: yup.string().required('This field is required')
	},
	{
		key: 'VendorInformation.VendorHours.Thursday',
		fieldName: 'storeHours.thursday',
		title: 'Thursday',
		validation: yup.string().required('This field is required')
	},
	{
		key: 'VendorInformation.VendorHours.Friday',
		fieldName: 'storeHours.friday',
		title: 'Friday',
		validation: yup.string().required('This field is required')
	},
	{
		key: 'VendorInformation.VendorHours.Saturday',
		fieldName: 'storeHours.saturday',
		title: 'Saturday',
		validation: yup.string().required('This field is required')
	},
	{
		key: 'VendorInformation.PermitInfo.PermitType',
		fieldName: 'permitType',
		title: 'Permit Type',
		value: '',
		validation: yup.string().required('Permit Type is required')
	},
	{
		key: 'VendorInformation.PermitInfo.PermitNumber',
		fieldName: 'permitNumber',
		title: 'Permit Number',
		value: '',
		validation: yup.string().required('Permit Number is required')
	},
	{
		key: 'VendorInformation.PermitInfo.ExpirationDate',
		fieldName: 'expirationDate',
		title: 'Expiration Date',
		value: '',
		validation: yup.string().required('Expiration Date is required')
	},
	{
		key: 'VendorInformation.InfantFormula.PurchaseFromManufacture',
		fieldName: 'formulaPurchasedFromManufacturer',
		title: 'Do you purchase formula from a manufacturer?',
		value: '',
		validation: yup.string().required('This is required')
	},
	{
		key: 'VendorInformation.InfantFormula.WholesaleDistributorDetails',
		fieldName: 'wholesaleDistrubutorDetails',
		title: 'Wholesale Distributor Details',
		value: '',
		validation: yup.string().required('Wholesale Distributor Details is required')
	},
	{
		key: 'VendorInformation.CommoditiesDistributor.Details',
		fieldName: 'commoditiesDistributorDetails',
		title: 'Commodities Distributor Details',
		value: '',
		validation: yup.string().required('Commodities Distributor Details is required')
	},
	{
		key: 'VendorInformation.VendorSales.TotalGrossSales',
		fieldName: 'annualGrossSales',
		title: 'Total Annual Gross Sales (Total Annual Gross Non-Food Sales + Annual Gross Food Sales)',
		value: '',
		validation: yup.number().required('This Field is required')
	},
	{
		key: 'VendorInformation.VendorSales.AnnualGrossNonFoodSales',
		fieldName: 'annualGrossNonFoodSales',
		validation: yup.number().required('This Field is required'),
		title: 'Annual Gross Non-Food Sales'
	},
	{
		key: 'VendorInformation.VendorSales.AnnualGrossFoodSales',
		fieldName: 'annualGrossFoodSales',
		validation: yup.number().required('This Field is required'),
		title: 'Annual Gross Food Sales'
	},
	{
		key: 'VendorInformation.VendorSales.WICSalesToBeMoreThan50VendorAnnualFoodSalesRevenue',
		fieldName: 'aboveFifty',
		validation: yup.string().required('This is required'),
		title: `Do you expect WIC sales to be more than 50% of vendor's annual food sales revenue?`
	},
	{
		key: 'VendorInformation.VendorSales.FoodSalesRevenuePercentage',
		fieldName: 'foodSalesRevenuePercentage',
		validation: yup.string().required('This Field is required'),
		title: 'Food Sales Revenue %'
	},
	{
		key: 'VendorInformation.VendorSales.TotalNumberOfRegisters',
		fieldName: 'totalNumberOfRegisters',
		validation: yup.number().required('This Field is required'),
		title: 'Total number of registers'
	},
	{
		key: 'VendorInformation.VendorSales.NumberOfWICEnabledRegisters',
		fieldName: 'numberOfWicEnabledRegisters',
		validation: yup.number().required('This Field is required'),
		title: 'Number of WIC enababled registers'
	},
	{
		key: 'VendorInformation.VendorSales.NumberOfEBTSNAPEnabledRegisters',
		fieldName: 'numberEbtSnapEnabledRegisters',
		validation: yup.number().required('This Field is required'),
		title: 'Number of EBT SNAP enabled registers'
	},
	{
		key: 'VendorInformation.VendorSales.NumberofUnapprovedSelfCheckoutRegisters',
		fieldName: 'numberOfUnapprovedSelfCheckoutRegisters',
		validation: yup.number().required('This Field is required'),
		title: 'Number of Unapproved Self-Checkout registers'
	},
	{
		key: 'VendorInformation.VendorSales.NumberofWICEnabledSelfCheckoutRegisters',
		fieldName: 'numberOfWicEnabledSelfCheckoutRegisters',
		validation: yup.number().required('This Field is required'),
		title: 'Number of WIC enabled self-checkout registers'
	},
	{
		key: 'VendorInformation.VendorSales.VendorOfferWICOnlineOrdering',
		fieldName: 'onlineOrdering',
		validation: yup.string().required('This is required'),
		title: 'Does the Vendor offer WIC online ordering?'
	},
	{
		key: 'VendorInformation.VendorSales.VendorWICCurbsidePickup',
		fieldName: 'curbsidePickup',
		validation: yup.string().required('This is required'),
		title: 'Will this Vendor be offering WIC curbside pickup?'
	},
	{
		key: 'VendorInformation.VendorSales.SNAPAuthorized',
		fieldName: 'snapAuthorized',
		validation: yup.string().required('This is required'),
		title: 'SNAP authorized?'
	},
	{
		key: 'VendorInformation.VendorSales.SNAPSevenDigitAuthorizationNumber',
		fieldName: 'snapAuthorizationNumber',
		validation: yup
			.string()
			.matches(/^[0-9]+$/, 'Must be only numbers')
			.min(7, 'Must be exactly 7 digits')
			.max(7, 'Must be exactly 7 digits')
			.required('This Field is required'),
		title: 'SNAP 7-digit authorization number'
	},
	{
		key: 'VendorInformation.VendorSales.HasBeenDiqualified',
		fieldName: 'civilMonetaryPenalty',
		validation: yup.string().required('This is required'),
		title:
			'Has the Corporation/Vendor ever been disqualified or given a civil monetary penalty by the SNAP Program, WIC program or any other USDA program?'
	},
	{
		key: 'VendorInformation.VendorSales.VoiolationDetails',
		fieldName: 'violationDetails',
		validation: yup.string().required('This Field is required'),
		title: 'Violation Details'
	},
	{
		key: 'VendorInformation.VendorSales.PreviousWICVendorAgreement',
		fieldName: 'agreementsWithWicProgram',
		validation: yup.string().required('This is required'),
		title:
			'Has Corporation/Vendor had any previous WIC vendor agreements with the WIC program prior to this application?'
	},
	{
		key: 'VendorInformation.VendorSales.PreviousWICVendorAgreementDetails',
		fieldName: 'previousWicVendorAgreementDetails',
		validation: yup.string().required('This Field is required'),
		title: 'Previous WIC Vendor Agreement Details'
	},
	{
		key: 'VendorInformation.VendorSales.VendorLocation',
		fieldName: 'locationPriorToCurrentOwnership',
		validation: yup.string().required('This Field is required'),
		title: 'Was there a Vendor at this location prior to current ownership?'
	},
	{
		key: 'VendorInformation.VendorSales.PreviousVendorDetails',
		fieldName: 'previousVendorDetails',
		validation: yup.string().required('This Field is required'),
		title: 'Previous Vendor Details'
	},
	{
		key: 'VendorInformation.VendorSales.VendorOperationTime',
		fieldName: 'inOperation',
		validation: yup.string().required('This Field is required'),
		title: 'How long has this Vendor been in operation?'
	},
	{
		key: 'VendorInformation.VendorSales.BusinessPurchasedBy',
		fieldName: 'purchasedFromBloodRelativeOrMarriage',
		validation: yup.string().required('This is required'),
		title: 'Was this business purchased from a relative by blood or marriage?'
	},
	{
		key: 'VendorInformation.VendorSales.DoNotAppearInUSDAOrSTSDList',
		fieldName: 'usdaOrTxSuspensionList',
		validation: yup.string().required('This is required'),
		title:
			'By selecting YES, the vendor applicant certifies that they do not appear on the USDA or State of Texas suspension or debarment list.'
	},
	{
		key: 'VendorInformation.VendorSales.BankAccountNumber',
		fieldName: 'bankAccountNumberForAchProcessing',
		validation: yup
			.string()
			.matches(/^[0-9]+$/, 'Must be only nubmers.')
			.required('This Field is required'),
		title: 'Provide Bank Account Number for ACH processing:'
	},
	{
		key: 'VendorInformation.VendorSales.BankRoutingNumber',
		fieldName: 'bankRoutingNumberForAchProcessing',
		validation: yup
			.string()
			.matches(/^[0-9]+$/, 'Must be only nubmers.')
			.required('This Field is required'),
		title: 'Provide Bank Routing Number for ACH processing:'
	},
	{
		key: 'VendorInformation.VendorSales.NameOfPOSProvider',
		fieldName: 'nameOfPosProvider',
		validation: yup.string().required('This Field is required'),
		title: 'Name of POS Provider'
	},
	{
		key: 'VendorInformation.VendorSales.NameOfPOSSystem',
		fieldName: 'nameOfPosSystem',
		validation: yup.string().required('This Field is required'),
		title: 'Name of POS System'
	},
	{
		key: 'VendorInformation.VendorSales.POSWICVerified',
		fieldName: 'posWicCertified',
		validation: yup.string().required('This Field is required'),
		title: 'Is POS System WIC certified?'
	}
];
