import { SvgIconComponent, Home } from '@mui/icons-material';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';

import { ROUTES } from './routes';
import { FeatureManager, SettingManager } from '../contexts/ConfigurationContext';
import { User } from '../models/User';
import { FEATURES } from './features';

export interface MenuItem {
	name: string;
	path?: string;
	icon?: SvgIconComponent;
	items?: MenuItem[];
	isVisible?: (features: FeatureManager, settings: SettingManager, user: User) => boolean;
}

export const MENU: MenuItem[] = [
	{
		name: ROUTES.home.title,
		path: ROUTES.home.path,
		icon: Home,
		isVisible: (features, _, user) => user.relationships.length === 0 && !features.isEnabled(FEATURES.PreScreeningProcess)
	},
	{
		name: ROUTES.dashboard.title,
		path: ROUTES.dashboard.path,
		icon: Home,
		isVisible: (features, _, user) => user.relationships.length > 0 && features.isEnabled(FEATURES.Dashboard)
	},
	{
		name: ROUTES.applications.title,
		path: ROUTES.applications.path,
		isVisible: (features, _, user) => {
			var newUserWithApplications = user.relationships.length === 0 && user.applications.length > 0
			var existingUserWithCorpAccess = user.relationships.some(x => !x.isVirtual && !x.parentId) && features.isEnabled(FEATURES.ApplicationProcess)

			return newUserWithApplications || existingUserWithCorpAccess
		},
		items: [
			{
				name: ROUTES.applications.title,
				path: ROUTES.applications.path,
				icon: FormatAlignLeftIcon
			}
		]
	}
];
