import { TextField, Tooltip, InputAdornment, IconButton, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import InfoIcon from '@mui/icons-material/Info';
import { FieldMetadata } from '../../models/Metadata';

interface FormInputTextProps {
	name: string;
	label?: string;
	control?: any;
	description?: string;
	disabled?: boolean;
	required?: boolean;
	testId?: string;
	multiline?: boolean;
	rows?: number;
	type?: string;
	metadata?: FieldMetadata;
	maxLength?: number;
}

export const FormInputText = ({
	name,
	metadata,
	control,
	label,
	description,
	disabled,
	required,
	multiline,
	rows,
	type = 'text',
	testId,
	maxLength
}: FormInputTextProps) => {
	const effectiveName = metadata?.fieldName || name;
	const effectiveLabel = metadata?.title || label;
	const effectiveRequired = metadata?.required ?? required;
	const effectiveDescription = metadata?.description || description;
	const effectiveTestId = metadata?.fieldName || testId;

	const inputProps = {
		'data-testid': effectiveTestId,
		maxLength,
		style: type === 'date' ? { marginTop: '20px' } : undefined
	};

	return (
		<Controller
			name={effectiveName}
			control={control}
			render={({ field: { onChange, value }, fieldState: { error } }) => (
				<TextField
					inputProps={inputProps}
					helperText={error?.message || null}
					error={!!error}
					onChange={onChange}
					value={value || ''}
					type={type}
					fullWidth
					label={effectiveLabel}
					variant="standard"
					margin="normal"
					disabled={disabled}
					required={effectiveRequired}
					multiline={multiline}
					rows={rows}
					InputProps={{
						endAdornment: effectiveDescription ? (
							<InputAdornment position="end">
								<Tooltip title={<Typography dangerouslySetInnerHTML={{ __html: effectiveDescription }} />}>
									<IconButton edge="end" size="small">
										<InfoIcon fontSize="small" />
									</IconButton>
								</Tooltip>
							</InputAdornment>
						) : undefined
					}}
				/>
			)}
		/>
	);
};
