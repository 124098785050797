import * as yup from 'yup';

export const createSchema = (obj: any) => {
	const schema: any = {};

	for (const key in obj) {
		if (obj[key]?.fieldName) {
			if (obj[key]?.required) {
				schema[key] = obj[key]?.validation;
			}
		} else {
			schema[key] = createSchema(obj[key]);
		}
	}

	return yup.object().shape(schema);
};

interface FieldConfig {
	[key: string]: any;
}

export interface MetaDataObj {
	[key: string]: any;
}

export const buildMetadata = (fieldConfig: FieldConfig, metaDataObj: MetaDataObj) => {
	const result = fieldConfig?.reduce((result: any, field: any) => {
		if (metaDataObj?.[`${field.key}`]?.visible) {
			const fieldNameArray = field?.fieldName?.split('.');

			if (fieldNameArray?.length > 1) {
				let fieldKey = {};
				let current: any = fieldKey;

				for (let i = 0; i < fieldNameArray?.length; i++) {
					const key = fieldNameArray[i];

					if (i === fieldNameArray?.length - 1) {
						current[key] = { ...field, ...metaDataObj?.[`${field.key}`] };
					} else {
						current[key] = result[key] ?? current[key] ?? {};
					}
					if (i < fieldNameArray.length - 1) {
						current = current[key];
					}
				}

				return { ...result, ...fieldKey };
			} else {
				return { ...result, [field.fieldName]: { ...field, ...metaDataObj?.[`${field.key}`] } };
			}
		}

		return result;
	}, {});

	return result;
};

const visibleFields: any = {};
export const buildDefaultFormValues = (defaultValues: MetaDataObj, result: any, masterKey?: string) => {
	for (const tKey in result) {
		let key = masterKey ? `${masterKey}.${tKey}` : tKey;
		if (result.hasOwnProperty(tKey) && !result[tKey]?.key) {
			buildDefaultFormValues(defaultValues, result[key], key);
		}

		if (result.hasOwnProperty(tKey) && result[tKey]?.visible) {
			if (masterKey) {
				visibleFields[masterKey] = { ...visibleFields[masterKey], [tKey]: defaultValues[masterKey][tKey] };
			} else {
				visibleFields[key] = defaultValues[tKey];
			}
		}
	}
	return visibleFields;
};
