import { createTheme } from '@mui/material';

const baseTheme = createTheme({
	palette: {
		grey: {
			'100': '#E5E2E9',
			'500': '#BFBFBF',
			'800': '#EFEFF7',
			'900': '#F7F7FB'
		},
		primary: {
			light: '#D1C2E1',
			main: '#4A2E62',
			dark: '#290E41'
		},
		secondary: {
			main: '#007599',
			light: '#007599',
			dark: '#006c8d'
		},
		error: {
			main: '#b95564'
		},
		success: {
			light: '#e8f5e9',
			main: '#30824e'
		},
		warning: {
			main: '#df8665'
		},
		info: {
			light: '#fff',
			main: '#fceacf',
			dark: '#ffb74d'
		}
	},
	typography: {
		fontFamily: 'Arial'
	},
	mixins: {
		toolbar: {
			minHeight: 48
		}
	}
});

baseTheme.shadows[1] = '0px 3px 6px 0px #00000026';

export const theme = createTheme(baseTheme, {
	typography: {
		h1: {
			color: baseTheme.palette.common.white,
			fontSize: 16
		},
		h2: {
			color: baseTheme.palette.secondary.main,
			fontSize: 22,
			fontWeight: 700,
			paddingTop: 0,
			paddingBottom: baseTheme.spacing(2)
		},
		subtitle1: {
			color: '#B84787',
			fontSize: 18,
			fontWeight: 700
		}
	},
	components: {
		MuiAccordion: {
			styleOverrides: {
				root: {
					backgroundColor: baseTheme.palette.grey['900'],
					borderRadius: '10px',
					boxShadow: 'none',
					'&.Mui-expanded': {
						margin: '0'
					},
					'&:first-of-type': {
						borderTopLeftRadius: '10px',
						borderTopRightRadius: '10px'
					}
				}
			}
		},
		MuiAccordionSummary: {
			styleOverrides: {
				root: {
					borderRadius: '10px',
					'&.Mui-expanded': {
						minHeight: '32px',
					},
				},
				content: {
					marginTop: '8px',
					marginBottom: '8px',
					'&.Mui-expanded': {
						marginTop: '8px',
						marginBottom: '8px'
					},
				}
			}
		},
		MuiAccordionDetails: {
			styleOverrides: {
				root: {
					borderRadius: '10px'
				}
			}
		},
		MuiButton: {
			styleOverrides: {
				root: {
					fontWeight: 700,
					textTransform: 'none'
				},
				text: {
					color: baseTheme.palette.secondary.main
				},
				contained: {
					backgroundColor: baseTheme.palette.secondary.dark,
					'&:hover': {
						backgroundColor: baseTheme.palette.secondary.light
					}
				}
			}
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					background: 'white',
					border: 'red'
				}
			}
		},
		MuiAppBar: {
			styleOverrides: {
				root: {
					backgroundColor: baseTheme.palette.primary.dark
				}
			}
		},
		MuiDrawer: {
			styleOverrides: {
				paper: {
					backgroundColor: baseTheme.palette.grey['800'],
					borderRight: 'none',
					boxShadow: baseTheme.shadows[3]
				}
			}
		},
		MuiRadio: {
			styleOverrides: {
				root: {
					color: baseTheme.palette.secondary.main,
					'&.Mui-checked': {
						color: baseTheme.palette.secondary.main
					}
				}
			}
		},
		MuiListItemButton: {
			styleOverrides: {
				root: {
					'&:hover': {
						backgroundColor: baseTheme.palette.grey['800']
					},
					'&.Mui-selected': {
						backgroundColor: baseTheme.palette.grey['900'],
						color: baseTheme.palette.common.black
					},
					'&.Mui-selected:hover': {
						backgroundColor: baseTheme.palette.common.white,
						color: baseTheme.palette.common.black
					}
				}
			}
		},
		MuiListItemText: {
			styleOverrides: {
				primary: {
					color: baseTheme.palette.secondary.main,
					fontSize: 13,
					fontWeight: baseTheme.typography.fontWeightMedium
				}
			}
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					color: 'inherit',
					minWidth: 'auto',
					marginRight: baseTheme.spacing(2),
					'& svg': {
						fontSize: 18
					}
				}
			}
		},
		MuiTab: {
			styleOverrides: {
				root: {
					fontWeight: 700
				}
			}
		},
		MuiTableCell: {
			styleOverrides: {
				head: {
					fontWeight: 700
				}
			}
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					borderRadius: 4
				}
			}
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					backgroundColor: 'rgb(255,255,255,0.15)'
				}
			}
		},
		MuiAvatar: {
			styleOverrides: {
				root: {
					width: 32,
					height: 32
				}
			}
		},
		MuiDataGrid: {
			styleOverrides: {
				columnHeaderTitle: {
					fontWeight: 'bold'
				}
			}
		},
		'&.notistack-MuiContent-error': {
			styleOverrides: {
				backgroundColor: 'blue'
			}
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: {
					backgroundColor: baseTheme.palette.secondary.main,
					color: baseTheme.palette.common.white,
					fontSize: 18,
					fontWeight: 700
				}
			}
		},
		MuiStepIcon: {
			styleOverrides: {
				root: {
					color: 'gray',
					'&.Mui-completed': {
						color: '#30824E'
					},
					'&.Mui-active': {
						color: '#B84787'
					}
				}
			}
		},
		MuiStepLabel: {
			styleOverrides: {
				label: {
					'&.Mui-active': {
						fontWeight: 700
					}
				}
			}
		}
	}
});
