import { useNavigate } from "react-router";
import { ROUTES } from "../constants";
import { useEffect } from "react";
import { useUser } from "../contexts/UserContext";
import { useConfiguration } from "../contexts/ConfigurationContext";
import { FEATURES } from "../constants/features";
import { Box, Button, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useCreateApplicationMutation } from "../components/Applications/queries";
import { APPLICATION_TYPE } from "../components/Applications/NewApplicationModal";
import { Application, User } from "../models";
import { Page } from "../components/Page";
import { useQueryClient } from "@tanstack/react-query";

export const HomePage = () => {

  const navigate = useNavigate()
  const user = useUser()
  const { features } = useConfiguration()

  useEffect(() => {
    const hasRelationships = user.relationships.length > 0
    const hasApplications = user.applications.length > 0

    if (hasRelationships) {
      navigate(ROUTES.dashboard.path)
    } else if (features.isEnabled(FEATURES.PreScreeningProcess)) {
      if (hasApplications) {
        navigate(ROUTES.applications.path);
      } else {
        navigate(ROUTES.preScreening.path);
      }
    }
  }, [navigate, user, features])

  const queryClient = useQueryClient()
  const createApplication = useCreateApplicationMutation({

    onSuccess: (applicationData: Application) => {
      queryClient.setQueryData<User>(['users', 'me'], prev => {
        if (prev) {
          return { ...prev, applications: [applicationData] }
        }
      });

      navigate(ROUTES.application.path.replace(':id', applicationData.id))
    }
  });
  const onClick = () => {
    createApplication.mutate({ type: APPLICATION_TYPE.CORP_VENDOR })
  }

  if (user.applications.length > 0) {
    return (
      <Box p={3}>
        <Page title='Welcome!'>
          <Box py={3}>
            <Typography>Your initial application is currently in progress. Please click the button below to continue.</Typography>
          </Box>
          <Box>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => navigate(ROUTES.application.path.replace(':id', user.applications[0].id))}
            >
              Continue application
            </Button>
          </Box>
        </Page >
      </Box >
    )
  }

  return (
    <Box p={3}>
      <Page title='Welcome!'>
        <Box py={3}>
          <Typography>Please note that you are currently not tied to any Corporate/Bus Ent or Vendors. Please click the button below to create a new Corporation/Vendor Application in order to set up your Corporate/Bus Ent and Vendor in the system.</Typography>
        </Box>
        <Box>
          <LoadingButton
            type="submit"
            data-testid="submit-new-application-btn"
            variant="contained"
            color="secondary"
            disabled={createApplication.isLoading}
            loading={createApplication.isLoading}
            onClick={onClick}
          >
            Start application
          </LoadingButton>
        </Box>
      </Page>
    </Box>
  )
}
