import { PortalContent } from "../../PortalContent";
import { SETTINGS } from "../../../constants/settings";
import { PreScreeningStep } from "../PreScreeningStep";

interface IntroductionProps {
  onClickContinue: () => void;
}

export const Introduction = ({ onClickContinue }: IntroductionProps) => {
  return (
    <PreScreeningStep onClickNext={onClickContinue} nextText="Continue">
      <PortalContent contentKey={SETTINGS.PreScreeningIntroduction} />
    </PreScreeningStep>
  );
}