import { Address } from './Address';

export interface StoreHours {
	autoPopulateStoreHours: boolean;
	sunday?: string;
	monday?: string;
	tuesday?: string;
	wednesday?: string;
	thursday?: string;
	friday?: string;
	saturday?: string;
}

export interface Vendor {
	id: string;
	name: string;
	corporateName?: string;
	vendorId?: string;
	vendorType?: string;
	primaryContact?: string;
	primaryPhone?: string;
	faxNumber?: string;
	email?: string;
	storeAddress?: Address;
	mailingAddress?: Address;
	storeHours?: StoreHours;
	storeSquareFootage?: string;
	storeWebsite: string;
	trainingCompleted: boolean;
	totalNoRegisters?: string;
	wicEnabledRegisters?: string;
	annualGrossFoodSales?: number;
	totalAnnualSales?: number;
	snapId?: number;
	peerGroup?: string;
	priceRegion?: string;
	contractStartDate?: string;
	contractEndDate?: string;
	corporateId?: string;
	stateId?: number;
	mailCode?: number;
}

export const DEFAULT_STORE_HOURS_VALUES: StoreHours = {
	autoPopulateStoreHours: false,
	monday: '',
	tuesday: '',
	wednesday: '',
	thursday: '',
	friday: '',
	saturday: '',
	sunday: ''
};
