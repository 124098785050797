import axios from 'axios';
import { createContext, useContext, PropsWithChildren } from 'react';
import { useQuery } from '@tanstack/react-query';

import { Application } from '../models/Application';
import { ErrorPage } from '../pages/Error';
import { LoadingPage } from '../pages/Loading';
import { Response, parseResponse } from '../models';

export const ApplicationContext = createContext<Application | undefined>(undefined);

type ApplicationProviderProps = PropsWithChildren & {
	applicationId: string;
};
export const ApplicationProvider: React.FC<ApplicationProviderProps> = ({ applicationId, children }) => {
	const fetchApplication = () =>
		axios.get<Response<Application>>(`/api/applications/${applicationId}`).then(parseResponse);

	const application = useQuery({
		queryKey: ['applications', applicationId],
		queryFn: () => fetchApplication()
	});

	if (application.isFetching) {
		return <LoadingPage />;
	}

	if (!application.isSuccess) {
		return <ErrorPage retry={application.refetch} />;
	}

	return <ApplicationContext.Provider value={application.data}>{children}</ApplicationContext.Provider>;
};

export const useApplication = () => useContext(ApplicationContext) as Application;
