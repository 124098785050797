import { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Grid } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { Application, addressToString } from '../../../../models';
import { StepProps } from '../../NewApplicationFlow';
import { CorporationSelector } from '../../../CorporationSelector';
import { GridCell } from '../../../Vendor/GridCell';
import { LoadingPage } from '../../../../pages';
import { useRelationship } from '../../../../contexts/RelationshipContext';
import { useApplication } from '../../../../contexts/ApplicationContext';
import queries, { useUpdateApplicationMutation } from '../../queries';
import { ApplicationStep } from '../../ApplicationStep';
import { Direction } from '../../../Direction';
import { PortalContent } from '../../../PortalContent';

const CorporateVendorDetails = ({ onNextClick, onPrevClick }: StepProps) => {
	const application = useApplication();
	const { corporationId } = useRelationship();
	const [selectedCorp, setSelectedCorp] = useState(corporationId);
	const { data, isLoading } = useQuery({
		...queries.getCorporateDetails(selectedCorp ?? ''),
		enabled: !!selectedCorp
	});
	const queryClient = useQueryClient();

	const mutation = useUpdateApplicationMutation({
		onSuccess: (applicationData: Application) => {
			queryClient.setQueryData<Application>(['applications', application.id], () => applicationData);

			if (onNextClick) {
				onNextClick();
			}
		},
		onError: () => {
			enqueueSnackbar('Error updating application', { variant: 'error' });
		}
	});

	useEffect(() => {
		if (application.corporationId) {
			setSelectedCorp(application.corporationId);
		} else if (corporationId) {
			setSelectedCorp(corporationId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [corporationId, application]);

	if (isLoading) {
		return (
			<ApplicationStep isBackDisabled={true} isNextDisabled={true}>
				<LoadingPage />
			</ApplicationStep>
		);
	}

	const handleSubmit = () => {
		if (application.corporationId !== selectedCorp) {
			mutation.mutate({
				id: application.id,
				payload: {
					corporationId: selectedCorp
				}
			});
		} else {
			if (onNextClick) {
				onNextClick();
			}
		}
	};

	return (
		<ApplicationStep
			onClickNext={handleSubmit}
			onClickBack={onPrevClick}
			isNextLoading={mutation.isLoading}
			isNextDisabled={mutation.isLoading}
		>
			<Direction
				title="Directions"
				content={<PortalContent contentKey="VendorPortal.VendorApplication.CorporateStep.Direction" />}
			/>
			<Box mt={3} pl={1}>
				<Grid container>
					<Grid item xs={4}>
						<CorporationSelector
							selectedValue={application.subType === 'Renewal' ? application.corporationId : undefined}
							isDisabled={application.subType === 'Renewal'}
							forceShow
							showVirtual={false}
						/>
					</Grid>
					<Grid item container xs={12} spacing={2}>
						<Grid item xs={12}>
							<GridCell title="Corporate ID" value={data?.corporateId} />
						</Grid>
						<Grid item xs={12}>
							<GridCell title="Corporation Address" value={addressToString(data?.address)} />
						</Grid>
						<Grid item xs={12}>
							<GridCell title="Primary Contact Full Name" value={data?.primaryContact?.name} />
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</ApplicationStep>
	);
};

export default CorporateVendorDetails;
