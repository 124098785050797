import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import ActionDialog from '../../Dialog/ActionDialog';
import { ContactTypeField } from '../../Fields';
import { FormInputText } from '../../Fields/FormInputText';
import { FormSwitch } from '../../Fields/FormSwitch';
import { ContactProps } from '../types';
import { useUser } from '../../../contexts/UserContext';

const { firstName, lastName, email, phone, types, accessEnabled, title, phone2, fax, address, notes } = {
	firstName: { name: 'firstName', label: 'First Name', value: '', validationMessage: 'First name is required' },
	lastName: { name: 'lastName', label: 'Last Name', value: '', validationMessage: 'Last name is required' },
	email: {
		name: 'email',
		label: 'Email',
		value: '',
		validationMessage: 'Please enter a valid email.',
		requiredValidationMessage: 'Email is required'
	},
	phone: {
		name: 'phone',
		label: 'Phone',
		value: '',
		validationMessage: 'Please use format (555) 123-4567.',
		requiredValidationMessage: 'Phone is required'
	},
	types: { name: 'types', label: 'Contact Types', value: [], validationMessage: 'Contact types required' },
	accessEnabled: {
		name: 'enabled',
		label: 'Access Enabled?',
		value: false,
		validationMessage: 'Access enabled required'
	},
	title: {
		name: 'title',
		label: 'Title',
		value: ''
	},
	phone2: {
		name: 'phone2',
		label: 'Alternate Phone',
		validationMessage: 'Please use format (555) 123-4567.',
		requiredValidationMessage: 'Alternate phone is required'
	},
	fax: {
		name: 'fax',
		label: 'Fax',
		validationMessage: 'Please use format (555) 123-4567.',
		requiredValidationMessage: 'Fax is required'
	},
	address: {
		line1: {
			name: 'address.line1',
			label: 'Street Address 1'
		},
		line2: {
			name: 'address.line2',
			label: 'Street Address 2'
		},
		city: {
			name: 'address.city',
			label: 'City'
		},
		state: {
			name: 'address.state',
			label: 'State'
		},
		zipCode: {
			name: 'address.zipCode',
			label: 'ZIP Code',
			validationMessage: 'Please enter valid zip code.'
		}
	},
	notes: {
		name: 'notes',
		label: 'Additional Notes'
	}
};

const defaultValues = {
	firstName: firstName.value,
	lastName: lastName.value,
	email: email.value,
	phone: phone.value,
	accessEnabled: accessEnabled.value,
	types: types.value
};
const phoneNumberRegex = /^(\(([0-9]{3})\)\s([0-9]{3})-([0-9]{4})|)$/;
const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
const schema = yup.object().shape({
	firstName: yup.string().required(firstName.validationMessage),
	lastName: yup.string().required(lastName.validationMessage),
	email: yup.string().email(email.validationMessage).required(email.requiredValidationMessage),
	phone: yup.string().required(phone.requiredValidationMessage).matches(phoneNumberRegex, phone.validationMessage),
	phone2: yup.string().matches(phoneNumberRegex, phone2.validationMessage),
	fax: yup.string().matches(phoneNumberRegex, fax.validationMessage),
	address: yup.object().shape({
		zipCode: yup.string().matches(isValidZip, address.zipCode.validationMessage)
	})
});

export const AddEditContact = ({
	applicationContact,
	isLoading,
	isError,
	errorMessage,
	record,
	onClose,
	isOpen,
	onUpdate,
	skipFields
}: any) => {
	const isEditDisabled = !!(record?.id && !record?.editable);
	const isEdit = !!record?.id;

	const user = useUser();

	const { handleSubmit, control } = useForm<ContactProps | any>({
		defaultValues: record ?? defaultValues,
		resolver: yupResolver(schema)
	});

	const onSubmit = (formData: ContactProps) => {
		onUpdate(isEdit, formData);
	};

	const btnList = isEditDisabled
		? [
				<Button onClick={onClose} color="secondary" autoFocus data-testid="button-close">
					Close
				</Button>
		  ]
		: [
				<Button onClick={onClose} color="secondary" autoFocus data-testid="button-cancel">
					Cancel
				</Button>,
				<LoadingButton
					type="submit"
					loading={isLoading}
					variant="contained"
					color="secondary"
					data-testid="button-submit"
					onClick={() => handleSubmit(onSubmit)()}
				>
					Submit
				</LoadingButton>
		  ];

	return (
		<ActionDialog open={isOpen} title={isEdit ? 'Edit contact' : 'Add new contact'} onClose={onClose} buttons={btnList}>
			{isError && (
				<Alert severity="error" sx={{ mb: 3 }}>
					{(errorMessage as Error)?.message}
				</Alert>
			)}
			<form onSubmit={handleSubmit(onSubmit)}>
				<Box display="flex" flexDirection="column" data-testid="add-edit-contact-dialog">
					<Box display="flex" gap={3}>
						<FormInputText
							required
							name={firstName.name}
							control={control}
							label={firstName.label}
							disabled={isEditDisabled}
							testId={firstName.name}
						/>
						<FormInputText
							required
							name={lastName.name}
							control={control}
							label={lastName.label}
							disabled={isEditDisabled}
							testId={lastName.name}
						/>
					</Box>
					<ContactTypeField
						testId={types.name}
						name={types.name}
						control={control}
						label={types.label}
						disabled={isEditDisabled}
					/>
					<FormInputText
						testId={title.name}
						name={title.name}
						control={control}
						label={title.label}
						disabled={isEditDisabled}
					/>
					<FormInputText
						required
						testId={email.name}
						name={email.name}
						control={control}
						label={email.label}
						disabled={isEdit}
					/>
					<FormInputText
						required
						testId={phone.name}
						name={phone.name}
						control={control}
						label={phone.label}
						disabled={isEditDisabled}
					/>
					<FormInputText
						testId={phone2.name}
						name={phone2.name}
						control={control}
						label={phone2.label}
						disabled={isEditDisabled}
					/>
					<FormInputText
						testId={fax.name}
						name={fax.name}
						control={control}
						label={fax.label}
						disabled={isEditDisabled}
					/>
					<Box display="flex" gap={3}>
						<FormInputText
							name={address.line1.name}
							control={control}
							label={address.line1.label}
							disabled={isEditDisabled}
							testId={address.line1.name}
						/>
						<FormInputText
							name={address.line2.name}
							control={control}
							label={address.line2.label}
							disabled={isEditDisabled}
							testId={address.line2.name}
						/>
					</Box>
					<FormInputText
						name={address.city.name}
						control={control}
						label={address.city.label}
						disabled={isEditDisabled}
						testId={address.city.name}
					/>
					<FormInputText
						name={address.state.name}
						control={control}
						label={address.state.label}
						disabled={isEditDisabled}
						testId={address.state.name}
					/>
					<FormInputText
						name={address.zipCode.name}
						control={control}
						label={address.zipCode.label}
						disabled={isEditDisabled}
						testId={address.zipCode.name}
					/>
					{!skipFields?.additionalNotes && (
						<FormInputText
							testId={notes.name}
							name={notes.name}
							control={control}
							label={notes.label}
							disabled={isEditDisabled}
						/>
					)}
					<FormSwitch
						testId={accessEnabled.name}
						name={accessEnabled.name}
						control={control}
						label={accessEnabled.label}
						disabled={isEditDisabled || user.id === record?.id}
					/>
				</Box>
			</form>
		</ActionDialog>
	);
};
