import { Box, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { Stepper } from '../Stepper';
import { NewApplicationSteps } from '../../models';
import Instructions from './Steps/Instructions';
import CorporateStep from './Steps/Corporate/CorporateStep';
import VendorStep from './Steps/Vendor/VendorStep';
import Attestation from './Steps/Attestation';
import Confirmation from './Steps/Confirmation';
import { useApplication } from '../../contexts/ApplicationContext';

const steps = ['Instructions', 'Corporate/Bus Ent', 'Vendors', 'Attestation', 'Confirmation'];

interface NewApplicationFlowProps {
	type: string;
}

export interface StepProps {
	onPrevClick?: () => void;
	onNextClick?: () => void;
}

export const NewApplicationFlow = ({ type }: NewApplicationFlowProps) => {
	const application = useApplication();

	const defaultStep = useMemo(() => {
		const isPendingClarification = application.status === 'Pending Clarification';

		if (application.attested && !isPendingClarification) {
			return NewApplicationSteps.Confirmation;
		} else if (isPendingClarification) {
			return NewApplicationSteps.Corporate;
		}

		return NewApplicationSteps.Instructions;
	}, [application]);

	const [activeStep, setActiveStep] = useState(defaultStep);

	return (
		<Box display="flex" flexDirection="column" width="100%" pt={3}>
			<Typography variant="h2" data-testid="application-name" ml={3}>
				{application.name}
			</Typography>
			<Box data-testid="new-app-flow-stepper" mx={2}>
				<Stepper stepNames={steps} activeStep={activeStep}></Stepper>
			</Box>
			{activeStep === NewApplicationSteps.Instructions && (
				<Instructions onNextClick={() => setActiveStep(NewApplicationSteps.Corporate)} />
			)}
			{activeStep === NewApplicationSteps.Corporate && (
				<CorporateStep
					onPrevClick={() => setActiveStep(NewApplicationSteps.Instructions)}
					onNextClick={() => setActiveStep(NewApplicationSteps.Vendor)}
				/>
			)}
			{activeStep === NewApplicationSteps.Vendor && (
				<VendorStep
					onPrevClick={() => setActiveStep(NewApplicationSteps.Corporate)}
					onNextClick={() => setActiveStep(NewApplicationSteps.Attestation)}
				/>
			)}
			{activeStep === NewApplicationSteps.Attestation && (
				<Attestation
					onPrevClick={() => setActiveStep(NewApplicationSteps.Vendor)}
					onNextClick={() => setActiveStep(NewApplicationSteps.Confirmation)}
				/>
			)}
			{activeStep === NewApplicationSteps.Confirmation && <Confirmation />}
		</Box>
	);
};
