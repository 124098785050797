import { Box } from '@mui/material';
import { FileList } from '../../../FileList/FileList';
import { EntityType } from '../../../../models/EntityType';
import { useApplication } from '../../../../contexts/ApplicationContext';
import { ReferenceDocuments } from '../../../ReferenceDocuments';
import { Direction } from '../../../Direction';
import { PortalContent } from '../../../PortalContent';

export const CorporateDocumentation = () => {
	const application = useApplication();
	return (
		<>
			<Direction
				title="Directions"
				content={<PortalContent contentKey="VendorPortal.CorporateApplication.Documents.Direction" />}
			/>
			<Box sx={{ mt: '10px', mb: '10px' }}>
				<FileList parentType={EntityType.Application} parentId={application.id} allowUpload={true} />
			</Box>
			<ReferenceDocuments parentType={EntityType.Corporation} />
		</>
	);
};
