import {
	FormControl,
	FormControlLabel,
	FormLabel,
	IconButton,
	Radio,
	RadioGroup,
	Tooltip,
	Typography
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { FormInputProps } from './FormCheckbox';
import InfoIcon from '@mui/icons-material/Info';
import { FieldMetadata } from '../../models/Metadata';

export interface FormRadioProps extends FormInputProps {
	row?: boolean;
	defaultValue?: number;
	yesNo?: boolean;
	list?: any;
	metadata: FieldMetadata;
}

export const FormRadio = ({
	name,
	testId,
	control,
	disabled,
	label,
	required,
	row = false,
	defaultValue,
	yesNo = false,
	list,
	metadata
}: FormRadioProps) => {
	return (
		<FormControl component="fieldset">
			<FormLabel required={required}>{label}</FormLabel>
			<Controller
				name={name}
				control={control}
				defaultValue={yesNo ? 'no' : defaultValue}
				render={({ field }) => (
					<>
						<RadioGroup {...field} row={row} data-testid={testId}>
							{yesNo && (
								<>
									<FormControlLabel value="yes" control={<Radio />} label="Yes" />
									<FormControlLabel value="no" control={<Radio />} label="No" />
								</>
							)}
							{!yesNo &&
								list?.map((item: { value: string; label: string }) => (
									<FormControlLabel value={item?.value} control={<Radio />} label={item?.label} />
								))}
							{metadata.description && (
								<Tooltip title={<Typography dangerouslySetInnerHTML={{ __html: metadata.description }} />}>
									<IconButton edge="end" size="small">
										<InfoIcon fontSize="small" />
									</IconButton>
								</Tooltip>
							)}
						</RadioGroup>
					</>
				)}
			/>
		</FormControl>
	);
};
