import axios from 'axios';

import { Response, User, parseResponse } from '../../models';
import { Contact } from '../../models/Contact';
import { useMutation } from './../../utils/react-query';
import { CONTACTS, UPDATE_CONTACT } from './constants';
import { ContactProps } from './types';

export const useCreateContactMutation = (corpId?: string, vendorId?: string, options?: any) => {
	const mutationFn = (payload: ContactProps) =>
		axios.post<Response<User>>(CONTACTS(corpId, vendorId), payload).then(parseResponse);
	return useMutation(mutationFn, options);
};

export const useUpdateContactMutation = (corpId?: string, vendorId?: string, options?: any) => {
	const mutationFn = (payload: ContactProps) =>
		axios.post<Response<User>>(UPDATE_CONTACT(corpId, vendorId, payload?.id), payload).then(parseResponse);
	return useMutation(mutationFn, options);
};

const queries = {
	getContacts: (corpId?: string, vendorId?: string) => ({
		queryKey: ['contacts', corpId, vendorId],
		queryFn: (): Promise<Contact[]> => axios.get<Response<Contact[]>>(CONTACTS(corpId, vendorId)).then(parseResponse),
		select: (res: Contact[]) =>
			res?.map(({ firstName, lastName, ...rest }) => ({
				...rest,
				firstName,
				lastName,
				name: `${firstName} ${lastName}`
			}))
	})
};

export default queries;
