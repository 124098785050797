import { Container } from "@mui/material";
import { useEffect, useState } from "react";

import { usePreScreening } from "../contexts/PreScreeningContext";
import { Confirmation, Introduction, PreScreeningForm } from "../components/PreScreening/Steps";
import { Page } from "../components/Page";
import { Stepper } from "../components/Stepper";
import { StepEnum } from "../models/PreScreening";
import { useUser } from "../contexts/UserContext";
import { useNavigate } from "react-router";
import { ROUTES } from "../constants";
import { useConfiguration } from "../contexts/ConfigurationContext";
import { FEATURES } from "../constants/features";

const steps = ["Instructions", "Pre-Screening Form", "Confirmation"];

export const PreScreeningPage = () => {
	const user = useUser();
	const navigate = useNavigate();
	const { features } = useConfiguration();
	const { preScreening } = usePreScreening();

	const [activeStep, setActiveStep] = useState<StepEnum>(StepEnum.Introduction);

	useEffect(() => {
		if (preScreening) {
			if (preScreening.submitted) {
				setActiveStep(StepEnum.Confirmation);
			} else {
				setActiveStep(StepEnum.Form);
			}
		} else {
			setActiveStep(StepEnum.Introduction);
		}
	}, [preScreening]);

	// Redirect to the home page if the user should not be able to fill out a new pre-screening form
	if (!features.isEnabled(FEATURES.PreScreeningProcess) || user.relationships.length > 0 || user.applications.length > 0) {
		navigate(ROUTES.home.path);
	}

	if (activeStep === undefined) {
		return null;
	}

	return (
		<>
			<Page title="">
				<Container maxWidth="md" sx={{ pt: 3 }}>
					<Stepper stepNames={steps} activeStep={activeStep}></Stepper>
				</Container>
				{activeStep === StepEnum.Introduction && <Introduction onClickContinue={() => setActiveStep(StepEnum.Form)} />}
				{activeStep === StepEnum.Form && <PreScreeningForm />}
				{activeStep === StepEnum.Confirmation && <Confirmation />}
			</Page>
		</>
	);
};
