import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { PortalTask } from "../../models/PortalTask";
import { Response, parseResponse } from "../../models";
import { enqueueSnackbar } from "notistack";
import { FileList } from "../FileList/FileList";
import { EntityType } from "../../models/EntityType";

interface TaskProps {
    regardingType: string;
    regardingId: string;
    task: PortalTask;
}
export const Task: React.FC<TaskProps> = ({ regardingType, regardingId, task }) => {

    const queryClient = useQueryClient()

    var updateTask = (completed: boolean) => axios.post<Response<PortalTask>>(`/api/tasks/${task.id}`, { completed: completed }).then(parseResponse)
    const { isLoading, mutate } = useMutation(updateTask,
        {
            onMutate: async (completed) => {
                // Cancel any outgoing queries so they don't overwrite our optimistic update
                await queryClient.cancelQueries({ queryKey: ['tasks', regardingType, regardingId] })

                queryClient.setQueryData<PortalTask[]>(['tasks', regardingType, regardingId], (oldData) => {
                    var tasks = JSON.parse(JSON.stringify(oldData ?? [])) as PortalTask[]
                    var existingTask = tasks.find(x => x.id === task.id)
                    if (existingTask) {
                        existingTask.completed = completed
                    }

                    return tasks
                })
            },
            onError: (_, completed) => {
                // Revert the optimistic update from the onMutate callback
                queryClient.setQueryData<PortalTask[]>(['tasks', regardingType, regardingId], (oldData) => {
                    var tasks = JSON.parse(JSON.stringify(oldData ?? [])) as PortalTask[]
                    var existingTask = tasks.find(x => x.id === task.id)
                    if (existingTask) {
                        existingTask.completed = !completed
                    }

                    return tasks
                })

                enqueueSnackbar('Failed to complete the task. Please try again later.', { variant: 'error' })
            },
        }
    );

    const handleCompleteTask = (event: React.ChangeEvent<HTMLInputElement>) => {
        mutate(event.target.checked);
    };

    return (
        <Box p={2} mb={2} sx={{ borderColor: (theme) => theme.palette.grey["100"], backgroundColor: '#ffffff', borderStyle: "solid", borderWidth: "2px", borderRadius: "10px" }}>
            <Typography sx={{ fontWeight: "bold" }}>{task.title}</Typography>
            <Typography>{task.description}</Typography>
            <Box>
                {!task.completed && <FileList parentType={EntityType.Task} parentId={task.id} allowUpload />}
            </Box>
            <FormControlLabel
                control={
                    <Checkbox
                        id={`complete-${task.id}`}
                        data-testid={`complete-${task.id}`}
                        checked={task.completed}
                        onChange={handleCompleteTask}
                        color="primary"
                        disabled={task.completed || isLoading}
                    />
                }
                label="Mark as complete"
            />
        </Box>
    );
};
