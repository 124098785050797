import { APPLICATIONINSIGHTS_CONNECTIONSTRING, APPLICATIONINSIGHTS_COOKIEDOMAIN, ROUTES } from './constants';

import { ThemeProvider, CssBaseline } from '@mui/material';
import { theme } from './themes';

import { ApplicationInsights, PageView, PageViewPerformance } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';

import { AppRoutes } from './AppRoutes';

var reactPlugin = new ReactPlugin();

export const appInsights = new ApplicationInsights({
	config: {
		connectionString: APPLICATIONINSIGHTS_CONNECTIONSTRING,
		autoTrackPageVisitTime: true,
		enableAutoRouteTracking: true,
		enableCorsCorrelation: true,
		cookieDomain: APPLICATIONINSIGHTS_COOKIEDOMAIN,
		extensions: [reactPlugin],
	}
});
// Add telemetry initializer to send the correct PageView name
appInsights.addTelemetryInitializer(item => {
	if (item.baseType === PageView.dataType || item.baseType === PageViewPerformance.dataType) {
		if (!item.baseData) {
			return;
		}
		var guid = /([a-fA-F\d]{8})-([a-fA-F\d]{4})-([a-fA-F\d]{4})-([a-fA-F\d]{4})-([a-fA-F\d]{12})/g;
		for (var routeKey of Object.keys(ROUTES)) {
			var route = ROUTES[routeKey]
			var path = window.location.pathname.replaceAll(guid, ":id")
			if (route.path === path) {
				item.baseData['name'] = route.title
				return true;
			}
		}
	}
})

if (process.env.NODE_ENV !== 'test') {
	appInsights.loadAppInsights();
}

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false
		}
	}
})

const authSettings: AuthProviderProps = {
	loadUserInfo: false,
	authority: process.env.REACT_APP_AUTHORITY ?? '',
	client_id: process.env.REACT_APP_CLIENTID ?? '',
	scope: process.env.REACT_APP_SCOPES ?? '',
	redirect_uri: process.env.REACT_APP_LOGINCALLBACKURI ?? '',
	post_logout_redirect_uri: process.env.REACT_APP_LOGOUTCALLBACKURI,
}

export const App = () => {
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<QueryClientProvider client={queryClient}>
				<AuthProvider {...authSettings}>
					<AppRoutes />
				</AuthProvider>
			</QueryClientProvider>
		</ThemeProvider>
	);
}
