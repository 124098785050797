import { Divider, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useFormContext } from 'react-hook-form';
import { Application } from '../../../../models';
import { FormInputNumber } from '../../../Fields/FormInputNumber';
import { FormInputText } from '../../../Fields/FormInputText';
import { FormSelect } from '../../../Fields/FormSelect';
import { FormRadio } from '../../../Fields/FormRadio';
import { useQuery } from '@tanstack/react-query';
import queries from '../../queries';

export interface VendorDetailsFormProps {
	isEdit?: boolean;
	fieldMetaData: { [key: string]: any };
}

const VendorDetailsForm = ({ fieldMetaData }: VendorDetailsFormProps) => {
	const { data: vendorTypeList } = useQuery(queries.getVendorTypes());
	const {
		control,
		formState: { errors },
		watch
	} = useFormContext<Application>();

	const isFormulaPurchasedFromManufacturer = watch('formulaPurchasedFromManufacturer');
	const isSnapAuthorized = watch('snapAuthorized');
	const isCivilMonetaryPenalty = watch('civilMonetaryPenalty') === 'yes';
	const selectedPermitType = watch('permitType');
	const isOpenTwentyFourSeven = watch('twentyFourSeven') === 'yes';

	const isMailingSameAsPhysical = watch('mailingSameAsPhysical') === 'yes';

	return (
		<Box display="flex" flexDirection="column" flex={1} marginBottom={4}>
			<form>
				<Grid container pt={1} columnSpacing={{ xs: 4 }}>
					<Grid item xs={12}>
						<Typography variant="body2">* Marks all required fields</Typography>
					</Grid>
					{fieldMetaData?.vendorName && (
						<Grid item xs={12} md={6}>
							<FormInputText
								name={fieldMetaData?.vendorName?.fieldName}
								control={control}
								metadata={fieldMetaData?.vendorName}
								maxLength={100}
							/>
						</Grid>
					)}
					{fieldMetaData?.vendorType && (
						<Grid item xs={12} md={6} pt={2}>
							<FormSelect
								required={fieldMetaData?.vendorType?.required}
								name={fieldMetaData?.vendorType?.fieldName}
								control={control}
								label={fieldMetaData?.vendorType?.title}
								testId={fieldMetaData?.vendorType?.fieldName}
								list={vendorTypeList || []}
								errors={errors.vendorType}
								metadata={fieldMetaData?.vendorType}
							/>
						</Grid>
					)}
					{fieldMetaData?.phone && (
						<Grid item xs={12} md={6}>
							<FormInputText
								name={fieldMetaData?.phone?.fieldName}
								control={control}
								metadata={fieldMetaData?.phone}
								maxLength={14}
							/>
						</Grid>
					)}
					{fieldMetaData?.squareFootage && (
						<Grid item xs={12} md={6}>
							<FormInputNumber
								name={fieldMetaData?.squareFootage?.fieldName}
								control={control}
								metadata={fieldMetaData?.squareFootage}
								minValue={0}
								maxValue={100000000000}
							/>
						</Grid>
					)}
					{fieldMetaData?.vendorOpeningDate && (
						<Grid item xs={12} md={6}>
							<FormInputText
								type="date"
								name={fieldMetaData?.vendorOpeningDate?.fieldName}
								control={control}
								metadata={fieldMetaData?.vendorOpeningDate}
							/>
						</Grid>
					)}
					{fieldMetaData?.federalTaxId && (
						<Grid item xs={12} md={6}>
							<FormInputText
								name={fieldMetaData?.federalTaxId?.fieldName}
								control={control}
								metadata={fieldMetaData?.federalTaxId}
								maxLength={9}
							/>
						</Grid>
					)}
					{fieldMetaData?.stateTaxId && (
						<Grid item xs={12} md={6}>
							<FormInputNumber
								name={fieldMetaData?.stateTaxId?.fieldName}
								control={control}
								metadata={fieldMetaData?.stateTaxId}
								maxLength={14}
							/>
						</Grid>
					)}
					{fieldMetaData?.percentOfBusinessOwned && (
						<Grid item xs={12} md={6}>
							<FormInputNumber
								name={fieldMetaData?.percentOfBusinessOwned?.fieldName}
								control={control}
								metadata={fieldMetaData?.percentOfBusinessOwned}
								minValue={0}
								maxValue={100}
							/>
						</Grid>
					)}
					{fieldMetaData?.ownerSsn && (
						<Grid item xs={12} md={6}>
							<FormInputNumber
								name={fieldMetaData?.ownerSsn?.fieldName}
								control={control}
								metadata={fieldMetaData?.ownerSsn}
								maxLength={9}
							/>
						</Grid>
					)}
					{fieldMetaData?.accessToComputerInternetEmail && (
						<Grid item xs={12} pt={2}>
							<FormRadio
								required={fieldMetaData?.accessToComputerInternetEmail?.required}
								name={fieldMetaData?.accessToComputerInternetEmail?.fieldName}
								control={control}
								label={fieldMetaData?.accessToComputerInternetEmail?.title}
								testId={fieldMetaData?.accessToComputerInternetEmail?.fieldName}
								errors={errors?.accessToComputerInternetEmail}
								row
								yesNo
								metadata={fieldMetaData?.accessToComputerInternetEmail}
							/>
						</Grid>
					)}
					{fieldMetaData?.dairySupplierLicenseNumber && (
						<Grid item xs={12} pt={2}>
							<FormInputText
								name={fieldMetaData?.dairySupplierLicenseNumber?.fieldName}
								control={control}
								metadata={fieldMetaData?.dairySupplierLicenseNumber}
								maxLength={100}
							/>
						</Grid>
					)}
					{fieldMetaData?.physicalAddress?.line1 && (
						<>
							<Grid item xs={12} py={2}>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								<Typography variant="body2" fontWeight={700}>
									Physical Address
								</Typography>
							</Grid>
							{fieldMetaData?.physicalAddress?.line1 && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.physicalAddress.line1?.fieldName}
										control={control}
										metadata={fieldMetaData?.physicalAddress?.line1}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.physicalAddress?.line2 && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.physicalAddress?.line2?.fieldName}
										control={control}
										metadata={fieldMetaData?.physicalAddress?.line2}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.physicalAddress?.city && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.physicalAddress?.city?.fieldName}
										control={control}
										metadata={fieldMetaData?.physicalAddress?.city}
										maxLength={25}
									/>
								</Grid>
							)}
							{fieldMetaData?.physicalAddress?.county && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.physicalAddress?.county?.fieldName}
										control={control}
										metadata={fieldMetaData?.physicalAddress?.county}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.physicalAddress?.state && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.physicalAddress?.state?.fieldName}
										control={control}
										metadata={fieldMetaData?.physicalAddress?.state}
										maxLength={2}
									/>
								</Grid>
							)}
							{fieldMetaData?.physicalAddress?.zipCode && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.physicalAddress?.zipCode?.fieldName}
										control={control}
										metadata={fieldMetaData?.physicalAddress?.zipCode}
										maxLength={9}
									/>
								</Grid>
							)}
							{fieldMetaData?.accessible && (
								<Grid item xs={12} md={6}>
									<FormRadio
										required={fieldMetaData?.accessible?.required}
										name={fieldMetaData?.accessible?.fieldName}
										control={control}
										label={fieldMetaData?.accessible?.title}
										testId={fieldMetaData?.accessible?.fieldName}
										errors={errors?.accessible}
										row
										yesNo
										metadata={fieldMetaData?.accessible}
									/>
								</Grid>
							)}
							{fieldMetaData?.otherVendorLocations && (
								<Grid item xs={12}>
									<FormInputText
										name={fieldMetaData?.otherVendorLocations?.fieldName}
										control={control}
										metadata={fieldMetaData?.otherVendorLocations}
										multiline
										rows={4}
										maxLength={2000}
									/>
								</Grid>
							)}
						</>
					)}
					{fieldMetaData?.mailingAddress?.line1 && (
						<>
							<Grid item xs={12} py={2}>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								<Typography variant="body2" fontWeight={700}>
									Mailing Address
								</Typography>
							</Grid>
							{fieldMetaData?.mailingSameAsPhysical && (
								<Grid item xs={12}>
									<FormRadio
										required={fieldMetaData?.mailingSameAsPhysical?.required}
										name={fieldMetaData?.mailingSameAsPhysical?.fieldName}
										control={control}
										label={fieldMetaData?.mailingSameAsPhysical?.title}
										testId={fieldMetaData?.mailingSameAsPhysical?.fieldName}
										errors={errors?.mailingSameAsPhysical}
										row
										yesNo
										metadata={fieldMetaData?.mailingSameAsPhysical}
									/>
								</Grid>
							)}
							{fieldMetaData?.mailingAddress?.line1 && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.mailingAddress?.line1?.fieldName}
										control={control}
										metadata={fieldMetaData?.mailingAddress?.line1}
										disabled={isMailingSameAsPhysical}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.mailingAddress?.line2 && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.mailingAddress?.line2?.fieldName}
										control={control}
										metadata={fieldMetaData?.mailingAddress?.line2}
										disabled={isMailingSameAsPhysical}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.mailingAddress?.city && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.mailingAddress?.city?.fieldName}
										control={control}
										metadata={fieldMetaData?.mailingAddress?.city}
										disabled={isMailingSameAsPhysical}
										maxLength={25}
									/>
								</Grid>
							)}
							{fieldMetaData?.mailingAddress?.county && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.mailingAddress?.county?.fieldName}
										control={control}
										metadata={fieldMetaData?.mailingAddress?.county}
										disabled={isMailingSameAsPhysical}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.mailingAddress?.state && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.mailingAddress?.state?.fieldName}
										control={control}
										metadata={fieldMetaData?.mailingAddress?.state}
										disabled={isMailingSameAsPhysical}
										maxLength={2}
									/>
								</Grid>
							)}
							{fieldMetaData?.mailingAddress?.zipCode && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.mailingAddress?.zipCode?.fieldName}
										control={control}
										metadata={fieldMetaData?.mailingAddress?.zipCode}
										disabled={isMailingSameAsPhysical}
										maxLength={9}
									/>
								</Grid>
							)}
						</>
					)}
					{fieldMetaData?.primaryContact?.email && (
						<>
							<Grid item xs={12} py={2}>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								<Typography variant="body2" fontWeight={700}>
									Primary Contact
								</Typography>
							</Grid>
							{fieldMetaData?.primaryContact?.firstName && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.primaryContact?.firstName?.fieldName}
										control={control}
										metadata={fieldMetaData?.primaryContact?.firstName}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.primaryContact?.lastName && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.primaryContact?.lastName?.fieldName}
										control={control}
										metadata={fieldMetaData?.primaryContact?.lastName}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.primaryContact?.title && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.primaryContact?.title?.fieldName}
										control={control}
										metadata={fieldMetaData?.primaryContact?.title}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.primaryContact?.email && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.primaryContact?.email?.fieldName}
										control={control}
										metadata={fieldMetaData?.primaryContact?.email}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.primaryContact?.phone && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.primaryContact?.phone?.fieldName}
										control={control}
										metadata={fieldMetaData?.primaryContact?.phone}
										maxLength={14}
									/>
								</Grid>
							)}
							{fieldMetaData?.primaryContact?.address?.line1 && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.primaryContact?.address?.line1?.fieldName}
										control={control}
										metadata={fieldMetaData?.primaryContact?.address?.line1}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.primaryContact?.address?.line2 && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.primaryContact?.address?.line2?.fieldName}
										control={control}
										metadata={fieldMetaData?.primaryContact?.address?.line2}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.primaryContact?.address?.city && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.primaryContact?.address?.city?.fieldName}
										control={control}
										metadata={fieldMetaData?.primaryContact?.address?.city}
										maxLength={25}
									/>
								</Grid>
							)}
							{fieldMetaData?.primaryContact?.address?.county && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.primaryContact?.address?.county?.fieldName}
										control={control}
										metadata={fieldMetaData?.primaryContact?.address?.county}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.primaryContact?.address?.state && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.primaryContact?.address?.state?.fieldName}
										control={control}
										metadata={fieldMetaData?.primaryContact?.address?.state}
										maxLength={2}
									/>
								</Grid>
							)}
							{fieldMetaData?.primaryContact?.address?.zipCode && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.primaryContact?.address?.zipCode?.fieldName}
										control={control}
										metadata={fieldMetaData?.primaryContact?.address?.zipCode}
										maxLength={9}
									/>
								</Grid>
							)}
						</>
					)}
					{fieldMetaData?.twentyFourSeven && (
						<>
							<Grid item xs={12} py={2}>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								<Typography variant="body2" fontWeight={700}>
									Vendor Hours
								</Typography>
							</Grid>
							{fieldMetaData?.twentyFourSeven && (
								<Grid item xs={12} pt={2}>
									<FormRadio
										required={fieldMetaData?.twentyFourSeven?.required}
										name={fieldMetaData?.twentyFourSeven?.fieldName}
										control={control}
										label={fieldMetaData?.twentyFourSeven?.title}
										testId={fieldMetaData?.twentyFourSeven?.fieldName}
										errors={errors?.twentyFourSeven}
										row
										yesNo
										metadata={fieldMetaData?.twentyFourSeven}
									/>
								</Grid>
							)}
							{fieldMetaData?.storeHours?.sunday && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.storeHours?.sunday?.fieldName}
										control={control}
										metadata={fieldMetaData?.storeHours?.sunday}
										disabled={isOpenTwentyFourSeven}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.storeHours?.monday && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.storeHours?.monday?.fieldName}
										control={control}
										metadata={fieldMetaData?.storeHours?.monday}
										disabled={isOpenTwentyFourSeven}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.storeHours?.tuesday && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.storeHours?.tuesday?.fieldName}
										control={control}
										metadata={fieldMetaData?.storeHours?.tuesday}
										disabled={isOpenTwentyFourSeven}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.storeHours?.wednesday && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.storeHours?.wednesday?.fieldName}
										control={control}
										metadata={fieldMetaData?.storeHours?.wednesday}
										disabled={isOpenTwentyFourSeven}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.storeHours?.thursday && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.storeHours?.thursday?.fieldName}
										control={control}
										metadata={fieldMetaData?.storeHours?.thursday}
										disabled={isOpenTwentyFourSeven}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.storeHours?.friday && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.storeHours?.friday?.fieldName}
										control={control}
										metadata={fieldMetaData?.storeHours?.friday}
										disabled={isOpenTwentyFourSeven}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.storeHours?.saturday && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.storeHours?.saturday?.fieldName}
										control={control}
										metadata={fieldMetaData?.storeHours?.saturday}
										disabled={isOpenTwentyFourSeven}
										maxLength={100}
									/>
								</Grid>
							)}
						</>
					)}
					{fieldMetaData?.permitType && (
						<>
							<Grid item xs={12} py={2}>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								<Typography variant="body2" fontWeight={700}>
									Permit Information
								</Typography>
							</Grid>
							{fieldMetaData?.permitType && (
								<Grid item xs={12} md={6} pt={2}>
									<FormSelect
										required={fieldMetaData?.permitType?.required}
										name={fieldMetaData?.permitType?.fieldName}
										control={control}
										label={fieldMetaData?.permitType?.title}
										testId={fieldMetaData?.permitType?.fieldName}
										errors={errors.permitType}
										list={[
											{ key: 'RetailFoodOperation', label: 'Retail Food Operation' },
											{ key: 'FoodManufacturing', label: 'Food Manufacturing' },
											{ key: 'FoodDealers', label: 'Food Dealers' }
										]}
										metadata={fieldMetaData?.permitType}
									/>
								</Grid>
							)}
							{fieldMetaData?.permitNumber && selectedPermitType && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.permitNumber?.fieldName}
										control={control}
										metadata={fieldMetaData?.permitNumber}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.expirationDate && selectedPermitType && (
								<Grid item xs={12} md={6}>
									<FormInputText
										type="date"
										name={fieldMetaData?.expirationDate?.fieldName}
										control={control}
										metadata={fieldMetaData?.expirationDate}
									/>
								</Grid>
							)}
						</>
					)}
					{fieldMetaData?.formulaPurchasedFromManufacturer && (
						<>
							<Grid item xs={12} py={2}>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								<Typography variant="body2" fontWeight={700}>
									Infant Formula
								</Typography>
							</Grid>
						</>
					)}
					{fieldMetaData?.formulaPurchasedFromManufacturer && (
						<Grid item xs={12} pt={2}>
							<FormRadio
								required={fieldMetaData?.formulaPurchasedFromManufacturer?.required}
								name={fieldMetaData?.formulaPurchasedFromManufacturer?.fieldName}
								control={control}
								label={fieldMetaData?.formulaPurchasedFromManufacturer?.title}
								testId={fieldMetaData?.formulaPurchasedFromManufacturer?.fieldName}
								errors={errors?.formulaPurchasedFromManufacturer}
								row
								yesNo
								metadata={fieldMetaData?.formulaPurchasedFromManufacturer}
							/>
						</Grid>
					)}
					{fieldMetaData?.wholesaleDistrubutorDetails && isFormulaPurchasedFromManufacturer === 'yes' && (
						<Grid item xs={12} md={6}>
							<FormInputText
								name={fieldMetaData?.wholesaleDistrubutorDetails?.fieldName}
								control={control}
								metadata={fieldMetaData?.wholesaleDistrubutorDetails}
								multiline
								rows={4}
								maxLength={2000}
							/>
						</Grid>
					)}
					{fieldMetaData?.commoditiesDistributorDetails && (
						<>
							<Grid item xs={12} py={2}>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								<Typography variant="body2" fontWeight={700}>
									Commodities Distributor
								</Typography>
							</Grid>
							<Grid item xs={12} md={6}>
								<FormInputText
									name={fieldMetaData?.commoditiesDistributorDetails?.fieldName}
									control={control}
									metadata={fieldMetaData?.commoditiesDistributorDetails}
									multiline
									rows={4}
									maxLength={2000}
								/>
							</Grid>
						</>
					)}
					{fieldMetaData?.annualGrossSales && (
						<>
							<Grid item xs={12} py={2}>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								<Typography variant="body2" fontWeight={700}>
									Vendor Sales
								</Typography>
							</Grid>
							{fieldMetaData?.annualGrossSales && (
								<Grid item xs={12} md={6}>
									<FormInputText
										type="number"
										name={fieldMetaData?.annualGrossSales?.fieldName}
										control={control}
										metadata={fieldMetaData?.annualGrossSales}
									/>
								</Grid>
							)}
							{fieldMetaData?.annualGrossNonFoodSales && (
								<Grid item xs={12} md={6}>
									<FormInputText
										type="number"
										name={fieldMetaData?.annualGrossNonFoodSales?.fieldName}
										control={control}
										metadata={fieldMetaData?.annualGrossNonFoodSales}
									/>
								</Grid>
							)}
							{fieldMetaData?.annualGrossFoodSales && (
								<Grid item xs={12} md={6}>
									<FormInputText
										type="number"
										name={fieldMetaData?.annualGrossFoodSales?.fieldName}
										control={control}
										metadata={fieldMetaData?.annualGrossFoodSales}
									/>
								</Grid>
							)}
							{fieldMetaData?.aboveFifty && (
								<Grid item xs={12}>
									<FormRadio
										required={fieldMetaData?.aboveFifty?.required}
										name={fieldMetaData?.aboveFifty?.fieldName}
										control={control}
										label={fieldMetaData?.aboveFifty?.title}
										testId={fieldMetaData?.aboveFifty?.fieldName}
										errors={errors?.aboveFifty}
										row
										yesNo
										metadata={fieldMetaData?.aboveFifty}
									/>
								</Grid>
							)}
							{fieldMetaData?.foodSalesRevenuePercentage && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.foodSalesRevenuePercentage?.fieldName}
										control={control}
										metadata={fieldMetaData?.foodSalesRevenuePercentage}
										multiline
										rows={4}
									/>
								</Grid>
							)}
							{fieldMetaData?.totalNumberOfRegisters && (
								<Grid item xs={12} md={6}>
									<FormInputNumber
										name={fieldMetaData?.totalNumberOfRegisters?.fieldName}
										control={control}
										metadata={fieldMetaData?.totalNumberOfRegisters}
										minValue={0}
										maxValue={2147483647}
									/>
								</Grid>
							)}
							{fieldMetaData?.numberOfWicEnabledRegisters && (
								<Grid item xs={12} md={6}>
									<FormInputNumber
										name={fieldMetaData?.numberOfWicEnabledRegisters?.fieldName}
										control={control}
										metadata={fieldMetaData?.numberOfWicEnabledRegisters}
										minValue={0}
										maxValue={2147483647}
									/>
								</Grid>
							)}
							{fieldMetaData?.numberEbtSnapEnabledRegisters && (
								<Grid item xs={12} md={6}>
									<FormInputNumber
										name={fieldMetaData?.numberEbtSnapEnabledRegisters?.fieldName}
										control={control}
										metadata={fieldMetaData?.numberEbtSnapEnabledRegisters}
										minValue={0}
										maxValue={2147483647}
									/>
								</Grid>
							)}
							{fieldMetaData?.numberOfUnapprovedSelfCheckoutRegisters && (
								<Grid item xs={12} md={6}>
									<FormInputNumber
										name={fieldMetaData?.numberOfUnapprovedSelfCheckoutRegisters?.fieldName}
										control={control}
										metadata={fieldMetaData?.numberOfUnapprovedSelfCheckoutRegisters}
										minValue={0}
										maxValue={2147483647}
									/>
								</Grid>
							)}
							{fieldMetaData?.numberOfWicEnabledSelfCheckoutRegisters && (
								<Grid item xs={12} md={6}>
									<FormInputNumber
										name={fieldMetaData?.numberOfWicEnabledSelfCheckoutRegisters?.fieldName}
										control={control}
										metadata={fieldMetaData?.numberOfWicEnabledSelfCheckoutRegisters}
										minValue={0}
										maxValue={2147483647}
									/>
								</Grid>
							)}
							{fieldMetaData?.onlineOrdering && (
								<Grid item xs={12}>
									<FormRadio
										required={fieldMetaData?.onlineOrdering?.required}
										name={fieldMetaData?.onlineOrdering?.fieldName}
										control={control}
										label={fieldMetaData?.onlineOrdering?.title}
										testId={fieldMetaData?.onlineOrdering?.fieldName}
										errors={errors?.onlineOrdering}
										row
										yesNo
										metadata={fieldMetaData?.onlineOrdering}
									/>
								</Grid>
							)}
							{fieldMetaData?.curbsidePickup && (
								<Grid item xs={12}>
									<FormRadio
										required={fieldMetaData?.curbsidePickup?.required}
										name={fieldMetaData?.curbsidePickup?.fieldName}
										control={control}
										label={fieldMetaData?.curbsidePickup?.title}
										testId={fieldMetaData?.curbsidePickup?.fieldName}
										errors={errors?.curbsidePickup}
										row
										yesNo
										metadata={fieldMetaData?.curbsidePickup}
									/>
								</Grid>
							)}
							{fieldMetaData?.snapAuthorized && (
								<Grid item xs={12}>
									<FormRadio
										required={fieldMetaData?.snapAuthorized?.required}
										name={fieldMetaData?.snapAuthorized?.fieldName}
										control={control}
										label={fieldMetaData?.snapAuthorized?.title}
										testId={fieldMetaData?.snapAuthorized?.fieldName}
										errors={errors?.snapAuthorized}
										row
										yesNo
										metadata={fieldMetaData?.snapAuthorized}
									/>
								</Grid>
							)}
							{((fieldMetaData?.snapAuthorizationNumber && isSnapAuthorized === 'yes') ||
								(fieldMetaData?.snapAuthorizationNumber && !fieldMetaData?.snapAuthorized)) && (
								<Grid item xs={12} md={6}>
									<FormInputNumber
										name={fieldMetaData?.snapAuthorizationNumber?.fieldName}
										control={control}
										metadata={fieldMetaData?.snapAuthorizationNumber}
										maxLength={7}
									/>
								</Grid>
							)}
							{fieldMetaData?.civilMonetaryPenalty && (
								<Grid item xs={12}>
									<FormRadio
										required={fieldMetaData?.civilMonetaryPenalty?.required}
										name={fieldMetaData?.civilMonetaryPenalty?.fieldName}
										control={control}
										label={fieldMetaData?.civilMonetaryPenalty?.title}
										testId={fieldMetaData?.civilMonetaryPenalty?.fieldName}
										errors={errors?.civilMonetaryPenalty}
										row
										yesNo
										metadata={fieldMetaData?.civilMonetaryPenalty}
									/>
								</Grid>
							)}
							{fieldMetaData?.violationDetails && isCivilMonetaryPenalty && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.violationDetails?.fieldName}
										control={control}
										metadata={fieldMetaData?.violationDetails}
										multiline
										rows={4}
										maxLength={2000}
									/>
								</Grid>
							)}
							{fieldMetaData?.agreementsWithWicProgram && (
								<Grid item xs={12}>
									<FormRadio
										required={fieldMetaData?.agreementsWithWicProgram?.required}
										name={fieldMetaData?.agreementsWithWicProgram?.fieldName}
										control={control}
										label={fieldMetaData?.agreementsWithWicProgram?.title}
										testId={fieldMetaData?.agreementsWithWicProgram?.fieldName}
										errors={errors?.agreementsWithWicProgram}
										row
										yesNo
										metadata={fieldMetaData?.agreementsWithWicProgram}
									/>
								</Grid>
							)}
							{fieldMetaData?.previousWicVendorAgreementDetails && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.previousWicVendorAgreementDetails?.fieldName}
										control={control}
										metadata={fieldMetaData?.previousWicVendorAgreementDetails}
										multiline
										rows={4}
										maxLength={2000}
									/>
								</Grid>
							)}
							{fieldMetaData?.locationPriorToCurrentOwnership && (
								<Grid item xs={12}>
									<FormRadio
										required={fieldMetaData?.locationPriorToCurrentOwnership?.required}
										name={fieldMetaData?.locationPriorToCurrentOwnership?.fieldName}
										control={control}
										label={fieldMetaData?.locationPriorToCurrentOwnership?.title}
										testId={fieldMetaData?.locationPriorToCurrentOwnership?.fieldName}
										errors={errors?.locationPriorToCurrentOwnership}
										row
										yesNo
										metadata={fieldMetaData?.locationPriorToCurrentOwnership}
									/>
								</Grid>
							)}
							{fieldMetaData?.previousVendorDetails && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.previousVendorDetails?.fieldName}
										control={control}
										metadata={fieldMetaData?.previousVendorDetails}
										multiline
										rows={4}
										maxLength={2000}
									/>
								</Grid>
							)}
							{fieldMetaData?.inOperation && (
								<Grid item xs={12}>
									<FormInputText
										name={fieldMetaData?.inOperation?.fieldName}
										control={control}
										metadata={fieldMetaData?.inOperation}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.purchasedFromBloodRelativeOrMarriage && (
								<Grid item xs={12}>
									<FormRadio
										required={fieldMetaData?.purchasedFromBloodRelativeOrMarriage?.required}
										name={fieldMetaData?.purchasedFromBloodRelativeOrMarriage?.fieldName}
										control={control}
										label={fieldMetaData?.purchasedFromBloodRelativeOrMarriage?.title}
										testId={fieldMetaData?.purchasedFromBloodRelativeOrMarriage?.fieldName}
										errors={errors?.purchasedFromBloodRelativeOrMarriage}
										row
										yesNo
										metadata={fieldMetaData?.purchasedFromBloodRelativeOrMarriage}
									/>
								</Grid>
							)}
							{fieldMetaData?.usdaOrTxSuspensionList && (
								<Grid item xs={12}>
									<FormRadio
										required={fieldMetaData?.usdaOrTxSuspensionList?.required}
										name={fieldMetaData?.usdaOrTxSuspensionList?.fieldName}
										control={control}
										label={fieldMetaData?.usdaOrTxSuspensionList?.title}
										testId={fieldMetaData?.usdaOrTxSuspensionList?.fieldName}
										errors={errors?.usdaOrTxSuspensionList}
										row
										yesNo
										metadata={fieldMetaData?.usdaOrTxSuspensionList}
									/>
								</Grid>
							)}
							{fieldMetaData?.bankAccountNumberForAchProcessing && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.bankAccountNumberForAchProcessing?.fieldName}
										control={control}
										metadata={fieldMetaData?.bankAccountNumberForAchProcessing}
										maxLength={17}
									/>
								</Grid>
							)}
							{fieldMetaData?.bankRoutingNumberForAchProcessing && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.bankRoutingNumberForAchProcessing?.fieldName}
										control={control}
										metadata={fieldMetaData?.bankRoutingNumberForAchProcessing}
										maxLength={9}
									/>
								</Grid>
							)}
							{fieldMetaData?.nameOfPosProvider && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.nameOfPosProvider?.fieldName}
										control={control}
										metadata={fieldMetaData?.nameOfPosProvider}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.nameOfPosSystem && (
								<Grid item xs={12} md={6}>
									<FormInputText
										name={fieldMetaData?.nameOfPosSystem?.fieldName}
										control={control}
										metadata={fieldMetaData?.nameOfPosSystem}
										maxLength={100}
									/>
								</Grid>
							)}
							{fieldMetaData?.posWicCertified && (
								<Grid item xs={12}>
									<FormRadio
										required={fieldMetaData?.posWicCertified?.required}
										name={fieldMetaData?.posWicCertified?.fieldName}
										control={control}
										label={fieldMetaData?.posWicCertified?.title}
										testId={fieldMetaData?.posWicCertified?.fieldName}
										errors={errors?.posWicCertified}
										row
										yesNo
										metadata={fieldMetaData?.posWicCertified}
									/>
								</Grid>
							)}
							{fieldMetaData?.itemizedReceipts && (
								<Grid item xs={12}>
									<FormRadio
										required={fieldMetaData?.itemizedReceipts?.required}
										name={fieldMetaData?.itemizedReceipts?.fieldName}
										control={control}
										label={fieldMetaData?.itemizedReceipts?.title}
										testId={fieldMetaData?.itemizedReceipts?.fieldName}
										errors={errors?.itemizedReceipts}
										row
										yesNo
										metadata={fieldMetaData?.itemizedReceipts}
									/>
								</Grid>
							)}
							{fieldMetaData?.splitTenderTransactions && (
								<Grid item xs={12} pt={2}>
									<FormRadio
										required={fieldMetaData?.splitTenderTransactions?.required}
										name={fieldMetaData?.splitTenderTransactions?.fieldName}
										control={control}
										label={fieldMetaData?.splitTenderTransactions?.title}
										testId={fieldMetaData?.splitTenderTransactions?.fieldName}
										errors={errors?.splitTenderTransactions}
										row
										yesNo
										metadata={fieldMetaData?.splitTenderTransactions}
									/>
								</Grid>
							)}

							{fieldMetaData?.storeSales && (
								<Grid item xs={12} md={6} pt={2}>
									<FormSelect
										required={fieldMetaData?.storeSales?.required}
										name={fieldMetaData?.storeSales?.fieldName}
										control={control}
										label={fieldMetaData?.storeSales?.title}
										testId={fieldMetaData?.storeSales?.fieldName}
										list={[
											{ key: 'Gasoline', label: 'Gasoline' },
											{ key: 'Beer/Wine', label: 'Beer/Wine' },
											{ key: 'Grill/Deli', label: 'Grill/Deli' },
											{ key: 'Bait/Tackle', label: 'Bait/Tackle' },
											{ key: 'Hardware', label: 'Hardware' },
											{ key: 'HouseholdItems', label: 'Household Items' },
											{ key: 'FoodOnly', label: 'Food Only' }
										]}
										multiSelect
										metadata={fieldMetaData?.storeSales}
									/>
								</Grid>
							)}
						</>
					)}
				</Grid>
			</form>
		</Box>
	);
};

export default VendorDetailsForm;
