import { Controller, FieldError, RegisterOptions, useFormContext } from 'react-hook-form';
import { Checkbox as MuiCheckbox, CheckboxProps as MuiCheckboxProps, FormControlLabel } from '@mui/material';

export interface CheckboxProps {
  id: string;
  label: string;
  options?: RegisterOptions;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox = ({ id, label, options, onChange, ...other }: CheckboxProps & MuiCheckboxProps) => {
  const { control, formState: { errors } } = useFormContext();

  return (
    <>
      <Controller
        name={id}
        control={control}
        rules={options}
        render={({ field }) => (
          <FormControlLabel
            control={
              <MuiCheckbox
                {...other}
                data-testid={id}
                checked={field.value === true}
                onChange={(e) => {
                    field.onChange(e.target.checked);
                    if (onChange) {
                      onChange(e)
                    }
                }}
                onBlur={field.onBlur}
                color="primary"
              />
            }
            label={label}
          />
        )}
      />
      {errors[id] && (
        <span style={{ color: 'red', marginLeft: '8px' }}>{(errors[id] as FieldError)?.message}</span>
      )}
    </>
  );
};
