import React, { PropsWithChildren, useState } from 'react';
import {
	Box,
	Button,
	Chip,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Tooltip,
	Typography,
	styled
} from '@mui/material';
import { Application } from '../../models';
import { useApplications } from '../../contexts/ApplicationsContext';
import { useNavigate, useParams } from 'react-router';
import { ROUTES } from '../../constants';
import NewApplicationModal from './NewApplicationModal';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const AnimatedBox = styled(Box)(({ theme }) => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen
	}),
	overflowY: 'auto'
}));

export const ApplicationVendorList: React.FC<PropsWithChildren> = ({ children }) => {
	const navigate = useNavigate();
	const params = useParams();
	const applications = useApplications();

	const corporationList = applications.filter(x => x.type === 'Corporate');
	const vendorList = applications.filter(x => x.type === 'Vendor');

	const [isListExpanded, setIsListExpanded] = useState(true);
	const toggleListVisibility = () => setIsListExpanded(!isListExpanded);

	const onApplicationChange = (application: Application) => {
		navigate(ROUTES.application.path.replace(':id', application.id));
	};

	const [showNewApplicationModal, setShowNewApplicationModal] = useState(false);

	const handleCreateNewApp = (data: Application) => {
		setShowNewApplicationModal(false);
		onApplicationChange(data);
	};

	const renderListItem = (item: Application) => {
		return (
			<ListItem key={item.id} disablePadding sx={{ borderStartStartRadius: '200px' }}>
				<ListItemButton
					selected={item.id === params.id}
					onClick={() => onApplicationChange(item)}
					sx={{ borderStartStartRadius: 90, borderEndStartRadius: 90 }}
				>
					<ListItemText>{item?.name}</ListItemText>
					{item.subType === 'Renewal' && <Chip label="Renewal" size="small" />}
					{(item.status === 'Submitted' || item.status === 'Under Review') && (
						<Tooltip title={item.status}>
							<Box
								sx={{
									display: 'inline-flex',
									justifyContent: 'center',
									alignItems: 'center',
									marginLeft: '5px',
									borderRadius: '50%',
									backgroundColor: theme => theme.palette.success.light,
									width: 27,
									height: 27,
									color: theme => theme.palette.success.dark
								}}
							>
								<AccessTimeIcon />
							</Box>
						</Tooltip>
					)}
					{item.status === 'Pending Clarification' && (
						<Tooltip title="Pending Clarification">
							<Box
								sx={{
									display: 'inline-flex',
									justifyContent: 'center',
									alignItems: 'center',
									marginLeft: '5px',
									borderRadius: '50%',
									backgroundColor: theme => theme.palette.info.main,
									width: 27,
									height: 27,
									color: theme => theme.palette.info.dark
								}}
							>
								<HelpOutlineIcon />
							</Box>
						</Tooltip>
					)}
				</ListItemButton>
			</ListItem>
		);
	};

	return (
		<Box display="flex" flexDirection="row" data-testid="application-vendor-list">
			<AnimatedBox
				width={isListExpanded ? 300 : 50}
				sx={{
					overflowY: 'auto',
					borderRight: isListExpanded ? 1 : 0,
					borderColor: 'divider',
					paddingTop: isListExpanded ? 3 : 0,
					paddingLeft: isListExpanded ? 2 : 0,
					position: 'relative'
				}}
			>
				<Box
					sx={{
						position: 'absolute',
						right: 0,
						top: theme => theme.spacing(3)
					}}
				>
					<Button
						onClick={toggleListVisibility}
						variant="outlined"
						color="secondary"
						sx={{
							position: 'absolute',
							right: isListExpanded ? 10 : 0,
							minWidth: '40px',
							maxWidth: '40px'
						}}
					>
						{isListExpanded ? <ChevronLeftIcon /> : <ChevronRightIcon />}
					</Button>
				</Box>
				{isListExpanded && (
					<>
						<Button
							variant="outlined"
							color="secondary"
							data-testid="create-new-application-btn"
							onClick={() => setShowNewApplicationModal(true)}
							sx={{ mb: 2 }}
						>
							New Application
						</Button>
						<List>
							<ListItem sx={{ marginLeft: -2 }}>
								<Typography fontWeight={700}>Corporate/Bus Ent</Typography>
							</ListItem>
							{corporationList.map(renderListItem)}
						</List>
						<List>
							<ListItem sx={{ marginLeft: -2 }}>
								<Typography fontWeight={700}>Vendor</Typography>
							</ListItem>
							{vendorList.map(renderListItem)}
						</List>
						{showNewApplicationModal && (
							<NewApplicationModal
								onHandleCancelClick={() => setShowNewApplicationModal(false)}
								onHandleSubmit={handleCreateNewApp}
							/>
						)}
					</>
				)}
			</AnimatedBox>
		</Box>
	);
};
