import axios from 'axios';
import { Response, parseResponse } from '../../models';
import { Disqualification } from '../../models/Disqualification';

export const DISQUALIFICATION = (corpId: string, vendorId: string) =>
	`/api/corporations/${corpId}/vendors/${vendorId}/disqualifications`;

const queries = {
	getDisqualificationList: (corpId: string, vendorId: string) => ({
		queryKey: ['disqualificationList', corpId, vendorId],
		queryFn: (): Promise<Disqualification[]> =>
			axios.get<Response<Disqualification[]>>(DISQUALIFICATION(corpId, vendorId)).then(parseResponse)
	})
};
export default queries;
