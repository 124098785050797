import { Box } from '@mui/system';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Collapse, Typography } from '@mui/material';
import { PropsWithChildren, useState } from 'react';

export interface HorizontalAccordionProps extends PropsWithChildren {
	initialExpanded?: boolean
	title: string;
}

export default function HorizontalAccordion({ initialExpanded = false, title, children }: HorizontalAccordionProps) {
	const [expanded, setExpanded] = useState(initialExpanded);

	const handleChange = () => {
		setExpanded(prevState => !prevState);
	};

	return (
		<Box display="flex" flexDirection="column">
			<Box
				display="flex"
				flexDirection="column"
				p={1}
				pt={2}
				mb={2}
				sx={{
					backgroundColor: 'grey.900',
					position: 'sticky',
					top: '0',
					borderRadius: '10px',
					height: '70vh',
					overflowY: expanded ? 'auto' : 'hidden'
				}}
			>
				<Box
					display="flex"
					flexDirection={expanded ? 'row' : 'column'}
					alignItems="center"
					alignContent="center"
					gap={1}
					onClick={handleChange}
					sx={{ cursor: 'pointer' }}
				>
					{expanded ? <ChevronRightIcon /> : <ChevronLeftIcon />}
					<Typography variant="subtitle1" sx={{ writingMode: expanded ? 'inherit' : 'vertical-rl' }}>
						{title}
					</Typography>
				</Box>
				<Collapse in={expanded} orientation="horizontal" unmountOnExit>
					<Box px={1} minWidth={400}>
						{children}
					</Box>
				</Collapse>
			</Box>
		</Box>
	);
}
