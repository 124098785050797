import { Controller, FieldError, useFormContext } from "react-hook-form";
import { TextField as MuiTextField } from "@mui/material";

interface TextFieldProps {
    id: string;
    label: string;
    disabled?: boolean;
    maxLength?: number;
    required?: boolean;
    validate?: (value: string) => string | undefined;
}

export const TextField = ({ id, label, disabled, maxLength, required, validate }: TextFieldProps) => {
    const { control, formState: { errors } } = useFormContext();

    return (
        <Controller
            name={id}
            control={control}
            rules={{
                required: required ? `${label} is required.` : undefined,
                validate: (value) => value !== '' ? validate?.(value) : undefined
            }}
            defaultValue={''}
            render={({ field }) => (
                <MuiTextField
                    {...field}
                    fullWidth
                    margin='normal'
                    variant="standard"
                    id={id}
                    label={label}
                    required={!!required}
                    disabled={disabled}
                    inputProps={{
                        'data-testid': id,
                        'maxLength': maxLength
                    }}
                    error={errors[id] !== undefined}
                    helperText={(errors[id] as FieldError)?.message}
                />
            )}
        />
    );
};
