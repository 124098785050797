import React from 'react';
import { TextField as MuiTextField, Tooltip, InputAdornment, IconButton, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import InfoIcon from '@mui/icons-material/Info';
import { FieldMetadata } from '../../models/Metadata';

interface FormInputNumberProps {
    name: string;
    label?: string;
    control: any;
    description?: string;
    disabled?: boolean;
    required?: boolean;
    testId?: string;
    metadata: FieldMetadata;
    maxLength?: number;
    minValue?: number;
    maxValue?: number;
}

export const FormInputNumber = ({
    name,
    label,
    control,
    description,
    disabled,
    required,
    testId,
    metadata,
    maxLength,
    minValue,
    maxValue
}: FormInputNumberProps) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue=''
            render={({ field: { onChange, value }, fieldState: { error } }) => {
                const handleNumericChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                    const inputValue = e.target.value;
                    if (!/^\d*$/.test(inputValue)) return; 

                    if (inputValue) {
                        const numericValue = parseInt(inputValue, 10);
                        if ((minValue !== undefined && numericValue < minValue) || 
                            (maxValue !== undefined && numericValue > maxValue)) {
                            return; 
                        }
                    }

                    onChange(inputValue || null); 
                };

                return (
                    <MuiTextField
                        id={metadata?.fieldName || name}
                        type="text"
                        onChange={handleNumericChange}
                        value={value || ''}
                        fullWidth
                        margin="normal"
                        variant="standard"
                        label={metadata?.title || label}
                        required={required || metadata?.required}
                        disabled={disabled}
                        inputProps={{
                            maxLength,
                            inputMode: 'numeric',
                            pattern: '[0-9]*',
                            'data-testid': testId || metadata?.fieldName,
                        }}
                        InputProps={{
                            endAdornment:
                                metadata?.description || description ? (
                                    <InputAdornment position="end">
                                        <Tooltip title={<Typography>{metadata?.description || description}</Typography>}>
                                            <IconButton edge="end" size="small">
                                                <InfoIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                ) : null
                        }}
                        error={!!error}
                        helperText={error ? error.message : null}
                    />
                );
            }}
        />
    );
};

export default FormInputNumber;
