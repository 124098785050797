import * as yup from 'yup';
import { PHONE_NUMBER_REQUIRED_VALIDATION, ZIP_CODE_VALID_REQUIRED_VALIDATION } from '../../../../utils/constant';

export const fieldConfig = [
	{
		key: 'CorporateInformation.LegalNameOfCorporate',
		fieldName: 'legalNameOfCorporate',
		title: 'Legal Name of Corporate/Business Entity',
		value: '',
		validation: yup.string().required('Legal Name of Corporate/Business Entity')
	},
	{
		key: 'CorporateInformation.Email',
		fieldName: 'email',
		title: 'Email',
		value: '',
		validation: yup.string().email('Please enter a valid email.').required('Email is required')
	},
	{
		key: 'CorporateInformation.HHSCPayeeID',
		fieldName: 'stateTaxId',
		title: 'HHSC Payee ID',
		value: '',
		validation: yup.string().required('HHSC Payee ID is required')
	},
	{
		key: 'CorporateInformation.Phone',
		fieldName: 'phone',
		title: 'Phone',
		value: '',
		validation: PHONE_NUMBER_REQUIRED_VALIDATION
	},
	{
		key: 'CorporateInformation.PhysicalAddress.StreetAddressLine1',
		fieldName: 'physicalAddress.line1',
		title: 'Street Address Line1',
		validation: yup.string().required('Street Address Line 1 is required')
	},
	{
		key: 'CorporateInformation.PhysicalAddress.StreetAddressLine2',
		fieldName: 'physicalAddress.line2',
		title: 'Street Address Line2',
		validation: yup.string().required('Street Address Line 2 is required')
	},
	{
		key: 'CorporateInformation.PhysicalAddress.City',
		fieldName: 'physicalAddress.city',
		title: 'City',
		validation: yup.string().required('City is required')
	},
	{
		key: 'CorporateInformation.PhysicalAddress.State',
		fieldName: 'physicalAddress.state',
		title: 'State',
		validation: yup.string().required('State is required')
	},
	{
		key: 'CorporateInformation.PhysicalAddress.ZipCode',
		fieldName: 'physicalAddress.zipCode',
		title: 'ZIP Code',
		validation: ZIP_CODE_VALID_REQUIRED_VALIDATION
	},
	{
		key: 'CorporateInformation.MailingAddress.SameAsPhysicalAddress',
		fieldName: 'mailingSameAsPhysical',
		title: 'Same as physical address',
		validation: yup.string().required('This is required')
	},
	{
		key: 'CorporateInformation.MailingAddress.StreetAddressLine1',
		fieldName: 'mailingAddress.line1',
		title: 'Street Address Line 1',
		validation: yup.string().required('Street Address Line 1 is required')
	},
	{
		key: 'CorporateInformation.MailingAddress.StreetAddressLine2',
		fieldName: 'mailingAddress.line2',
		title: 'Street Address Line 2',
		validation: yup.string().required('Street Address Line 2 is required')
	},
	{
		key: 'CorporateInformation.MailingAddress.City',
		fieldName: 'mailingAddress.city',
		title: 'City',
		validation: yup.string().required('City is required')
	},
	{
		key: 'CorporateInformation.MailingAddress.State',
		fieldName: 'mailingAddress.state',
		title: 'State',
		validation: yup.string().required('State is required')
	},
	{
		key: 'CorporateInformation.MailingAddress.ZipCode',
		fieldName: 'mailingAddress.zipCode',
		title: 'ZIP Code',
		validation: ZIP_CODE_VALID_REQUIRED_VALIDATION
	},
	{
		key: 'CorporateInformation.PrimaryContact.FirstName',
		fieldName: 'primaryContact.firstName',
		title: 'First Name',
		validation: yup.string().required('First name is required')
	},
	{
		key: 'CorporateInformation.PrimaryContact.LastName',
		fieldName: 'primaryContact.lastName',
		title: 'Last Name',
		validation: yup.string().required('Last name is required')
	},
	{
		key: 'CorporateInformation.PrimaryContact.Title',
		fieldName: 'primaryContact.title',
		title: 'Title',
		value: '',
		validation: yup.string().required('Title is required')
	},
	{
		key: 'CorporateInformation.PrimaryContact.Email',
		fieldName: 'primaryContact.email',
		title: 'Email',
		value: '',
		validation: yup.string().email('Please enter a valid email.').required('Email is required')
	},
	{
		key: 'CorporateInformation.PrimaryContact.Phone',
		fieldName: 'primaryContact.phone',
		title: 'Phone',
		value: '',
		validation: PHONE_NUMBER_REQUIRED_VALIDATION
	},
	{
		key: 'CorporateInformation.PrimaryContact.MailingAddress.SameAsCorporateMailingAddress',
		fieldName: 'primaryContactAddressSameAsMailing',
		title: 'Same as mailing address',
		value: '',
		validation: yup.string().required('This is required')
	},
	{
		key: 'CorporateInformation.PrimaryContact.Address.StreetAddressLine1',
		fieldName: 'primaryContact.address.line1',
		title: 'Street Address Line 1',
		validation: yup.string().required('Street Address Line 1 is required')
	},
	{
		key: 'CorporateInformation.PrimaryContact.Address.StreetAddressLine2',
		fieldName: 'primaryContact.address.line2',
		title: 'Street Address Line 2',
		validation: yup.string().required('Street Address Line 2 is required')
	},
	{
		key: 'CorporateInformation.PrimaryContact.Address.City',
		fieldName: 'primaryContact.address.city',
		title: 'City',
		validation: yup.string().required('City is required')
	},
	{
		key: 'CorporateInformation.PrimaryContact.Address.State',
		fieldName: 'primaryContact.address.state',
		title: 'State',
		validation: yup.string().required('State is required')
	},
	{
		key: 'CorporateInformation.PrimaryContact.Address.ZipCode',
		fieldName: 'primaryContact.address.zipCode',
		title: 'ZIP Code',
		validation: ZIP_CODE_VALID_REQUIRED_VALIDATION
	},
	{
		key: 'CorporateInformation.ComplianceContact.FirstName',
		fieldName: 'complianceContact.firstName',
		title: 'First Name',
		validation: yup.string().required('First Name is required')
	},
	{
		key: 'CorporateInformation.ComplianceContact.LastName',
		fieldName: 'complianceContact.lastName',
		title: 'Last Name',
		validation: yup.string().required('Last Name is required')
	},
	{
		key: 'CorporateInformation.ComplianceContact.Title',
		fieldName: 'complianceContact.title',
		title: 'Title',
		validation: yup.string().required('Title is required')
	},
	{
		key: 'CorporateInformation.ComplianceContact.Email',
		fieldName: 'complianceContact.email',
		title: 'Email',
		validation: yup.string().email('Please enter a valid email.').required('Email is required')
	},
	{
		key: 'CorporateInformation.ComplianceContact.Phone',
		fieldName: 'complianceContact.phone',
		title: 'Phone',
		validation: PHONE_NUMBER_REQUIRED_VALIDATION,
		value: ''
	},
	{
		key: 'CorporateInformation.ComplianceContact.MailingAddress.SameAsCorporateMailingAddress',
		fieldName: 'complianceContactAddressSameAsMailing',
		title: 'Same as mailing address',
		value: '',
		validation: yup.string().required('This is required')
	},
	{
		key: 'CorporateInformation.ComplianceContact.Address.StreetAddressLine1',
		fieldName: 'complianceContact.address.line1',
		title: 'Street Address Line 1',
		validation: yup.string().required('Street Address Line 1 is required')
	},
	{
		key: 'CorporateInformation.ComplianceContact.Address.StreetAddressLine2',
		fieldName: 'complianceContact.address.line2',
		title: 'Street Address Line 2',
		validation: yup.string().required('Street Address Line 2 is required')
	},
	{
		key: 'CorporateInformation.ComplianceContact.Address.City',
		fieldName: 'complianceContact.address.city',
		title: 'City',
		validation: yup.string().required('City is required')
	},
	{
		key: 'CorporateInformation.ComplianceContact.Address.State',
		fieldName: 'complianceContact.address.state',
		title: 'State',
		validation: yup.string().required('State is required')
	},
	{
		key: 'CorporateInformation.ComplianceContact.Address.ZipCode',
		fieldName: 'complianceContact.address.zipCode',
		title: 'ZIP Code',
		validation: ZIP_CODE_VALID_REQUIRED_VALIDATION
	},
	{
		key: 'CorporateInformation.BusinessOwnerShipType',
		fieldName: 'ownershipType',
		title: 'Business Ownership Type',
		validation: yup.string().required('Business Ownership Type is required')
	},
	{
		key: 'CorporateInformation.BusinessOwnerShip.NameOfOwner',
		fieldName: 'nameOfOwner',
		title: 'Name of Owner',
		validation: yup.string().required('Name of Owner is required')
	},
	{
		key: 'CorporateInformation.BusinessOwnerShip.OwnerSSN',
		fieldName: 'ownerSsn',
		title: 'SSN or EIN',
		validation: yup.string().required('SSN or EIN is required')
	},
	{
		key: 'CorporateInformation.BusinessOwnerShip.StateOfTexas',
		fieldName: 'stateOfTexas',
		title: 'State of Texas?',
		validation: yup.string().required('This is required')
	},
	{
		key: 'CorporateInformation.BusinessOwnerShip.SOSCharacter',
		fieldName: 'texasSosCharterNumber',
		title: 'Texas Secretary of State (SoS) Charter#',
		validation: yup.string().required('Texas Secretary of State (SoS) Charter# is required'),
		value: ''
	},
	{
		key: 'CorporateInformation.BusinessOwnerShip.EIN',
		fieldName: 'federalTaxId',
		title: 'EIN #',
		validation: yup.string().required('This field is required')
			.matches(/^[0-9]+$/, "Must be only digits")
			.min(9, 'Must be exactly 9 digits')
			.max(9, 'Must be exactly 9 digits'),
		value: ''
	},
	{
		key: 'CorporateInformation.BusinessOwnerShip.OfficerDetails',
		fieldName: 'officerDetails',
		title: 'Officer Details',
		validation: yup.string().required('Officer Details is required'),
		value: ''
	},
	{
		key: 'CorporateInformation.BusinessOwnerShip.RegAgentDetails',
		fieldName: 'registeredAgentDetails',
		title: 'Registered Agent Details',
		validation: yup.string().required('Registered Agent Details is required')
	},
	{
		key: 'CorporateInformation.BusinessOwnerShip.PartnerDetails',
		fieldName: 'partnerDetails',
		title: 'Partner Details',
		validation: yup.string().required('Partner Details is required')
	},
	{
		key: 'CorporateInformation.ConvictionCivilJudgment.ConvictionCivilJudgment',
		fieldName: 'convictionCivilJudgment',
		title:
			'During the last 6 years, has the vendor applicant or any of the applicant’s current owners, officers, or managers been convicted of or had a civil judgment entered against them or been convicted of a misdemeanor for any activity indicating a lack of business integrity? Activities indicating a lack of business integrity include fraud, antitrust violations, embezzlement, theft, forgery, bribery, falsification or destruction of records, making false statements, receiving stolen property, making false claims, obstruction of justice and tax evasion.',
		validation: yup.string().required('This field is required')
	},
	{
		key: 'CorporateInformation.ConvictionCivilJudgment.ConvictionDetails',
		fieldName: 'convictionDetails',
		title: 'Provide Conviction Details',
		validation: yup.string().required('Provide Conviction Details is required')
	}
];
