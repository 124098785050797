import { Outlet } from "react-router";

import { Box } from "@mui/material";
import { PreScreeningProvider } from "../../contexts/PreScreeningContext";

export const PreScreeningLayout = () => {
    return (
        <PreScreeningProvider>
            <Box display="flex" flexDirection='column' flex={1} overflow='auto'>
                <Outlet />
            </Box>
        </PreScreeningProvider>
    );
}
