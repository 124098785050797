import { useMemo, useState } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Box, Tab, Tabs, Typography } from '@mui/material';

import { Task } from './Task';
import { LoadingPage } from '../../pages/Loading';
import { ErrorPage } from '../../pages/Error';
import { PortalTask, Response, parseResponse } from '../../models';
import { EntityType } from '../../models/EntityType';

type TaskListProps = {
	parentType: string;
	parentId: string;
	includeChildren: boolean;
	isChild?: boolean;
};

export const TaskList = ({ parentType, parentId, includeChildren, isChild = false }: TaskListProps) => {
	const fetchTasks = (): Promise<PortalTask[]> =>
		axios
			.get<Response<PortalTask[]>>(`/api/tasks?type=${parentType}&id=${parentId}&includeChildren=${includeChildren}`)
			.then(parseResponse);

	const { isFetching, isError, data } = useQuery(['tasks', parentType, parentId], fetchTasks);

	const [selectedTab, setSelectedTab] = useState<number>(0);

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setSelectedTab(newValue);
	};

	const filteredTasks = useMemo(
		() =>
			data?.filter(task => {
				const taskCompleteFilter = task.completed === (selectedTab === 1);
				const taskChildFilter = isChild ? task.regardingId !== parentId : task.regardingId === parentId;
				return taskCompleteFilter && taskChildFilter;
			}),
		[data, selectedTab, isChild, parentId]
	);

	const content = useMemo(() => {
		if (isFetching) {
			return <LoadingPage />;
		}

		if (isError) {
			return <ErrorPage />;
		}

		if (filteredTasks && filteredTasks.length > 0) {
			return filteredTasks?.map(task => <Task task={task} key={task.id} regardingType={parentType} regardingId={parentId} />);
		} else {
			return (
				<Box
					p={2}
					mb={2}
					sx={{
						borderColor: theme => theme.palette.grey['100'],
						backgroundColor: '#ffffff',
						borderStyle: 'solid',
						borderWidth: '2px',
						borderRadius: '10px'
					}}
				>
					<Typography>{selectedTab === 0 ? 'You have no open tasks.' : 'You have no completed tasks.'}</Typography>
				</Box>
			);
		}
	}, [isFetching, isError, filteredTasks, selectedTab, parentType, parentId]);

	return (
		<Box>
			<Typography variant="h2" sx={{ color: '#B84787' }}>
				{parentType === EntityType.Application ? '' : 'Tasks'}
			</Typography>
			<Tabs
				value={selectedTab}
				onChange={handleTabChange}
				textColor="secondary"
				indicatorColor="secondary"
				sx={{ pb: 3 }}
			>
				<Tab label="New" />
				<Tab label="Completed" />
			</Tabs>
			{content}
		</Box>
	);
};
