import axios from 'axios';
import { createContext, useContext, useMemo, PropsWithChildren, useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import { ErrorPage } from '../pages/Error';
import { LoadingPage } from '../pages/Loading';
import { Response, parseResponse } from '../models';
import { Metadata } from '../models/Metadata';

type MetadataContextType = {
	metadata: Metadata[];
	setMetadata: (value: Metadata[]) => void;
};

export const MetadataContext = createContext<MetadataContextType | undefined>(undefined);

export const MetadataProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const fetchMetadata = () => axios.get<Response<Metadata[]>>('/api/configuration/metadata').then(parseResponse);

	const { isFetching, isError, data, refetch } = useQuery({
		queryKey: ['metadata'],
		queryFn: () => fetchMetadata(),
		staleTime: Infinity
	});

	const [metadata, setMetadata] = useState<Metadata[]>([]);

	useEffect(() => {
		if (data) {
			setMetadata(data);
		}
	}, [data]);

	const value = useMemo(
		() => ({
			metadata: metadata,
			setMetadata: setMetadata
		}),
		[metadata, setMetadata]
	);

	if (isFetching) {
		return <LoadingPage />;
	}

	if (isError) {
		return <ErrorPage retry={refetch} />;
	}

	return <MetadataContext.Provider value={value}>{children}</MetadataContext.Provider>;
};

export const useMetadata = () => useContext(MetadataContext) as MetadataContextType;
