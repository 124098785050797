import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { PropsWithChildren } from 'react';

export interface ApplicationStepProps extends PropsWithChildren {
	backText?: string;
	onClickBack?: () => void;
	isBackLoading?: boolean;
	isBackDisabled?: boolean;
	nextText?: string;
	onClickNext?: () => void;
	isNextLoading?: boolean;
	isNextDisabled?: boolean;
}

export const ApplicationStep: React.FC<ApplicationStepProps> = ({
	children,
	backText = 'Back',
	onClickBack,
	isBackLoading,
	isBackDisabled,
	nextText = 'Next',
	onClickNext,
	isNextLoading,
	isNextDisabled
}) => {
	return (
		<>
			<Box display="flex" flexDirection="column" flex={1} sx={{ overflowY: 'auto' }} px={3}>
				{children}
			</Box>
			{(onClickBack || onClickNext) && (
				<Box py={2} sx={{ backgroundColor: theme => theme.palette.grey[900] }}>
					<Box display="flex" justifyContent="center" gap={3}>
						{onClickBack && (
							<LoadingButton
								variant="text"
								onClick={onClickBack}
								loading={isBackLoading}
								disabled={isBackDisabled || isBackLoading}
								data-testid="step-back-btn"
							>
								{backText}
							</LoadingButton>
						)}
						{onClickNext && (
							<LoadingButton
								variant="contained"
								onClick={onClickNext}
								loading={isNextLoading}
								disabled={isNextDisabled || isNextLoading}
								data-testid="step-next-btn"
							>
								{nextText}
							</LoadingButton>
						)}
					</Box>
				</Box>
			)}
		</>
	);
};
