export const FEATURES = {
	Dashboard: 'FB.VendorPortal.Dashboard',
	Profile: 'FB.VendorPortal.Profile',
	AccessManagement: 'FB.VendorPortal.AccessManagement',
	ContactManagement: 'FB.VendorPortal.ContactManagement',
	PreScreeningProcess: 'FB.VendorPortal.PreScreenProcess',
	DashboardTraining: 'FB.VendorPortal.Dashboard.Training',
	ApplicationProcess: 'FB.VendorPortal.ApplicationProcess',
	DashboardDisqualification: 'FB.VendorPortal.Dashboard.Disqualification'
};
