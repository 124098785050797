import { Box, Typography } from "@mui/material";

import { Checkbox } from "../../Fields";

interface FullServiceGrocerProps {
    readOnly: boolean;
}

export const FullServiceGrocer = ({ readOnly }: FullServiceGrocerProps) => {
    return (
        <>
            <Typography variant="subtitle2" sx={{ fontWeight: "bold", marginTop: "15px" }}>
                Is the store a full-service grocery store?
            </Typography>
            <Box>
                <Checkbox id="storeIsFullServiceGrocer" label="Yes" disabled={readOnly} />
            </Box>
        </>
    );
};
