import { Box, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Controller } from 'react-hook-form';

interface ContactTypeFieldProps {
	name: string;
	label: string;
	disabled?: boolean;
	required?: boolean;
	control?: any;
	testId?: string;
}
export const ContactTypeField = ({ name, control, label, disabled, required, testId }: ContactTypeFieldProps) => {
	const fetchContactTypes = () =>
		axios.get<string[]>('/api/configuration/contactTypes').then(response => response?.data);
	const { isLoading, isError, data } = useQuery({
		queryKey: ['configuration', 'contactTypes'],
		queryFn: () => fetchContactTypes(),
		staleTime: 5 * 60 * 1000,
	});

	const handleSelect = (role: string, values: string[] = []) => {
		return values?.some(x => x === role) ? values?.filter(x => x !== role) : [...values, role];
	};

	return (
		<Controller
			name={name}
			data-testid={testId}
			control={control}
			render={({ field }) => (
				<FormControl required={required} disabled={disabled}>
					<FormLabel sx={{ py: 1 }}>{label}</FormLabel>
					<Box sx={{ border: '1px solid #EFEFF7', borderRadius: '10px', py: '4px', pl: 2, pr: '4px' }}>
						<FormGroup>
							<Box sx={{ overflowY: 'auto', maxHeight: '130px' }} display="flex" flexDirection="column">
								{isLoading || isError ? (
									<CircularProgress size={24} />
								) : (
									data.map(contactType => (
										<FormControlLabel
											key={contactType}
											label={contactType}
											control={
												<Checkbox
													color="secondary"
													size="small"
													sx={{ py: '4px' }}
													checked={field.value?.some((x: string) => x === contactType) ?? false}
													onChange={() => field.onChange(handleSelect(contactType, field.value))}
												/>
											}
										/>
									))
								)}
							</Box>
						</FormGroup>
					</Box>
				</FormControl>
			)}
		/>
	);
};
