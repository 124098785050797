import axios from 'axios';
import { SnackbarProvider } from 'notistack';
import { Outlet } from 'react-router';
import { useAuth } from 'react-oidc-context';

import { ConfigurationProvider } from '../../contexts/ConfigurationContext';
import { RelationshipProvider } from '../../contexts/RelationshipContext';
import { UserProvider } from '../../contexts/UserContext';

export const AuthenticatedLayout = () => {

  const auth = useAuth()

  if (auth.isAuthenticated) {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${auth.user?.access_token}`
    }
  }

  return (
    <SnackbarProvider>
      <ConfigurationProvider>
        <RelationshipProvider>
          <UserProvider>
            <Outlet />
          </UserProvider>
        </RelationshipProvider>
      </ConfigurationProvider>
    </SnackbarProvider >
  )
}
