import {
	Box,
	FormControl,
	FormHelperText,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Tooltip,
	Typography
} from '@mui/material';
import { Controller } from 'react-hook-form';
import InfoIcon from '@mui/icons-material/Info';
import { FieldMetadata } from '../../models/Metadata';

interface FormSelectProps {
	name: string;
	label: string;
	control: any;
	disabled?: boolean;
	testId?: string;
	required?: boolean;
	list: { key: string; label: string }[];
	multiSelect?: boolean;
	errors?: { message?: string };
	metadata: FieldMetadata;
}

export const FormSelect: React.FC<FormSelectProps> = ({
	name,
	testId,
	control,
	disabled,
	required,
	label,
	list,
	multiSelect = false,
	errors,
	metadata
}) => {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onChange, value }, fieldState: { error } }) => (
				<FormControl fullWidth variant="standard" required={required} error={!!error}>
					<InputLabel>{label}</InputLabel>
					<Select
						inputProps={{
							'data-testid': testId
						}}
						value={multiSelect && !Array.isArray(value) ? [] : value || ''}
						label={label}
						onChange={onChange}
						multiple={multiSelect}
						disabled={disabled}
						required={required}
						error={!!error}
					>
						{list.map(listItem => (
							<MenuItem key={listItem.key} value={listItem.key}>
								{listItem.label}
							</MenuItem>
						))}
					</Select>
					{metadata.description && (
						<Box sx={{ position: 'absolute', top: 17, right: 32 }}>
							<Tooltip title={<Typography dangerouslySetInnerHTML={{ __html: metadata.description }} />}>
								<IconButton edge="end" size="small">
									<InfoIcon fontSize="small" />
								</IconButton>
							</Tooltip>
						</Box>
					)}
					<FormHelperText error>{error ? error.message : null}</FormHelperText>
				</FormControl>
			)}
		/>
	);
};
