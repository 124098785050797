import axios from 'axios';

import { Response, Training, parseResponse } from '../../models';

export const TRAINING = (corpId: string, vendorId: string) =>
	`/api/corporations/${corpId}/vendors/${vendorId}/trainings`;

const queries = {
	getTrainingList: (corpId: string, vendorId: string) => ({
		queryKey: ['trainingList', corpId, vendorId],
		queryFn: (): Promise<Training[]> => axios.get<Response<Training[]>>(TRAINING(corpId, vendorId)).then(parseResponse)
	})
};
export default queries;
