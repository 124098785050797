import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { useQuery } from '../../../utils/react-query';
import queries from '../queries';

interface TraningListProps {
	corporationId: string;
	vendorId: string;
}

export const TrainingList = ({ corporationId, vendorId }: TraningListProps) => {
	const { isFetching, data: trainingList } = useQuery(queries.getTrainingList(corporationId, vendorId));

	const columns: GridColDef[] = [
		{
			field: 'format',
			headerName: 'Format',
			flex: 1
		},
		{
			field: 'type',
			headerName: 'Type',
			flex: 1
		},
		{
			field: 'completionDate',
			headerName: 'Completion Date',
			flex: 1,
			valueFormatter: params => {
				if (params.value) {
					const formattedDate = new Date(params.value).toLocaleDateString();
					return formattedDate;
				}
				return '';
			}
		}
	];

	return (
		<>
			<DataGrid
				sx={{ border: 'none' }}
				rows={trainingList || []}
				columns={columns}
				loading={isFetching}
				disableRowSelectionOnClick
				disableVirtualization
				data-testid="training-list"
			/>
		</>
	);
};
