import { Box, Typography } from "@mui/material";

import { TextField } from "../../Fields/TextField";

interface CorporateDetailsProps {
    readOnly: boolean;
}

export const CorporateDetails = ({ readOnly }: CorporateDetailsProps) => {
    return (
			<>
				<Typography variant="subtitle2" sx={{ fontWeight: 'bold', marginTop: '15px' }}>
					Corporate/Bus Ent details
				</Typography>
				<Box>
					<TextField id="legalEntityBusinessName" label="Legal Entity Business Name" disabled={readOnly} required />
				</Box>
				<Box display="flex" gap={2}>
					<TextField
						id="corporationNumber"
						label="Corporation Number"
						disabled={readOnly}
						maxLength={5}
						validate={value => (!/^[0-9]{5}$/.test(value) ? 'Please use format XXXXX.' : undefined)}
					/>
					<TextField id="einOrSsn" label="EIN or SSN" disabled={readOnly} />
				</Box>
				<Box display="flex" gap={2}>
					<TextField
						id="corporatePrimaryContactFirstName"
						label="Primary Contact First Name"
						disabled={readOnly}
						required
						maxLength={100}
					/>
					<TextField
						id="corporatePrimaryContactLastName"
						label="Primary Contact Last Name"
						disabled={readOnly}
						required
						maxLength={100}
					/>
				</Box>
				<Box display="flex" gap={2}>
					<TextField
						id="corporatePrimaryContactEmail"
						label="Primary Contact Email"
						disabled={readOnly}
						required
						maxLength={100}
					/>
					<TextField
						id="corporatePrimaryContactPhone"
						label="Primary Contact Phone Number"
						disabled={readOnly}
						required
						validate={value =>
							!/\(([0-9]{3})\) ([0-9]{3})-([0-9]{4})/.test(value) ? 'Please use format (555) 123-4567.' : undefined
						}
						maxLength={14}
					/>
				</Box>
				<Box>
					<TextField id="ownerFullName" label="Owner's full name" disabled={readOnly} required />
				</Box>
			</>
		);
};
