export interface Address {
	line1?: string;
	line2?: string;
	city?: string;
	state?: string;
	zipCode?: string;
	county?: string;
}

export const DEFAULT_ADDRESS_VALUES: Address = {
	line1: '',
	line2: '',
	city: '',
	state: '',
	zipCode: '',
	county: ''
};

export const addressToString = (address?: Address): string | undefined => {
    if (!address) {
        return undefined;
    }

    const { line1, line2, city, state, county, zipCode } = address;
    const result: string[] = [];

    if (line1) result.push(line1);
    if (line2) result.push(line2);
    if (city) result.push(city);

    let stateZip = '';
    if (state) stateZip += state;
    if (zipCode) stateZip += ` ${zipCode}`;
    if (stateZip) result.push(stateZip);

    if (county) result.push(county);

    return result.join(', ');
};
