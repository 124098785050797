import {
	DialogActions,
	DialogContent,
	DialogTitle,
	Dialog as MuiDialog,
	DialogProps as MuiDialogProps
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';

type CloseReason = 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick';
interface DialogProps extends MuiDialogProps {
	onClose: (reason: CloseReason) => void;
	buttons?: React.ReactElement[];
	skipHeader?: boolean;
}

export const ActionDialog = ({ title, open, skipHeader = true, onClose, children, buttons, ...props }: DialogProps) => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<MuiDialog disableEscapeKeyDown fullScreen={fullScreen} open={open} onClose={onClose} {...props}>
			{skipHeader && <DialogTitle data-testid="action-dialog-title">{title}</DialogTitle>}
			<DialogContent>{children}</DialogContent>
			<DialogActions>
				{buttons?.map((button, index) => React.cloneElement(button, { key: `modal-button-${index}` }))}
			</DialogActions>
		</MuiDialog>
	);
};
