import { InputAdornment, Link } from '@mui/material';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Search } from '@mui/icons-material';

import { APPLICATION_STATUS, Application, ApplicationBase } from '../../../../models';
import { useApplication } from '../../../../contexts/ApplicationContext';
import { CustomNoRowsOverlay } from '../../../CustomNoRowsOverlay/CustomNoRowsOverlay';
import { APPLICATION_TYPE } from '../../NewApplicationModal';
import { useCreateApplicationMutation } from '../../queries';
import { LoadingButton } from '@mui/lab';
import { useQueryClient } from '@tanstack/react-query';

interface VendorListProps {
	data?: ApplicationBase[];
	onAddVendorClickSuccess: (applicationId: string) => void;
}

export const VendorList = ({ data, onAddVendorClickSuccess }: VendorListProps) => {
	const application = useApplication();
	const queryClient = useQueryClient();
	const createAppMutation = useCreateApplicationMutation({
		onSuccess: (applicationData: Application) => {
			queryClient.setQueryData<Application>(['applications', application.id], prevData => {
				if (prevData) {
					var prev = JSON.parse(JSON.stringify(prevData)) as Application;
					prev.children?.push(applicationData);

					return prev;
				}
			});
		}
	});
	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: 'Name',
			minWidth: 170,
			renderCell: ({ row }) => (
				<Link
					href="#"
					data-testid="vendor-name"
					onClick={() => {
						onAddVendorClickSuccess(row.id);
					}}
				>
					<div>{row?.name}</div>
				</Link>
			)
		},
		{
			field: 'status',
			headerName: 'Status',
			minWidth: 170
		},
		{
			field: 'createdOn',
			headerName: 'Date Added',
			minWidth: 120,
			valueFormatter: params => {
				if (params.value) {
					const formattedDate = new Date(params.value).toLocaleDateString();
					return formattedDate;
				}
				return '';
			}
		}
	];

	const ContactListToolbar = ({ visible = true }) => {
		return (
			<GridToolbarContainer sx={{ mt: 3, mb: 1, p: 0, alignContent: 'center' }}>
				<GridToolbarQuickFilter
					sx={{ pb: 0 }}
					variant="outlined"
					size="small"
					InputProps={{
						startAdornment: null,
						endAdornment: (
							<InputAdornment position="end">
								<Search />
							</InputAdornment>
						)
					}}
				/>
				{visible && application.subType !== APPLICATION_STATUS.RENEWAL && (
					<LoadingButton
						sx={{ py: '7px' }}
						variant="outlined"
						color="secondary"
						loading={createAppMutation.isLoading}
						onClick={() => {
							createAppMutation.mutate(
								{
									parentId: application?.id,
									type: APPLICATION_TYPE.VENDOR
								},
								{
									onSuccess: (applicationData: ApplicationBase) => {
										onAddVendorClickSuccess(applicationData.id);
									}
								}
							);
						}}
						data-testid="vendor-list-add-vendor"
					>
						Add vendor
					</LoadingButton>
				)}
			</GridToolbarContainer>
		);
	};

	return (
		<DataGrid
			sx={{ border: 'none' }}
			rows={data || []}
			columns={columns}
			disableRowSelectionOnClick
			disableVirtualization
			slots={{
				toolbar: ContactListToolbar,
				noRowsOverlay: () => <CustomNoRowsOverlay message="No vendors to display" />
			}}
			data-testid="vendor-list"
		/>
	);
};
