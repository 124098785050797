import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import { ReactComponent as Logo } from '../assets/images/mosaic.svg';

export const Footer = () => {

    return (
        <AppBar position="relative" component='footer' sx={{ background: 'white',  zIndex: (theme) => theme.zIndex.drawer + 1  }}>
            <Toolbar>
                <Logo title="WIC Mosaic" />
                <Box display='flex' flex={1} />
                <Typography variant='subtitle2' color='#716977'>&copy; Microsoft 2023</Typography>
            </Toolbar>
        </AppBar>
    )
}