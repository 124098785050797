import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { useQuery } from '../../../utils/react-query';
import queries from '../queries';

interface DisqualificationListProps {
	corporationId: string;
	vendorId: string;
}

export const VendorDisqualificationList = ({ corporationId, vendorId }: DisqualificationListProps) => {
	const { isFetching, data: disqualificationList } = useQuery(queries.getDisqualificationList(corporationId, vendorId));

	const columns: GridColDef[] = [
		{
			field: 'reason',
			headerName: 'FDP Reason',
			flex: 1
		},
		{
			field: 'startDate',
			headerName: 'Effective Date',
			flex: 1,
			valueFormatter: params => {
				if (params.value) {
					const formattedDate = new Date(params.value).toLocaleDateString();
					return formattedDate;
				}
				return '';
			}
		},
		{
			field: 'endDate',
			headerName: 'End Date',
			flex: 1,
			valueFormatter: params => {
				if (params.value) {
					const formattedDate = new Date(params.value).toLocaleDateString();
					return formattedDate;
				}
				return '';
			}
		}
	];

	return (
		<>
			<DataGrid
				sx={{ border: 'none' }}
				rows={disqualificationList || []}
				columns={columns}
				loading={isFetching}
				disableRowSelectionOnClick
				disableVirtualization
				data-testid="disqualification-list"
			/>
		</>
	);
};
