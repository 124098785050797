import {
	Hydrate,
	QueryClient,
	QueryClientProvider,
	dehydrate,
	useMutation,
	useQuery as useOriginalQuery,
	useQueryClient,
} from '@tanstack/react-query';

export const useQuery = useOriginalQuery;

// NOTE: Interval is in milliseconds or can be a function
// export const usePollingQuery = (props, interval = 3000) => {
// 	// eslint-disable-next-line no-unused-vars
// 	const { refetchInterval, ...rest } = props;
// 	const queryKey = useUpdatedQueryKey(rest);

// 	useOriginalQuery({ ...rest, refetchInterval: interval, queryKey: [...queryKey, 'POLLING'] });
// };

// export const useInfiniteQuery = props => {
// 	return useOriginalInfiniteQuery(props);
// };

export { Hydrate, QueryClient, QueryClientProvider, dehydrate, useMutation, useQueryClient };
