import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import VendorDetails from './VendorDetails';
import { MetadataProvider } from '../../../../contexts/MetadataContext';
import { ApplicationProvider } from '../../../../contexts/ApplicationContext';
import { useCallback, useState } from 'react';
import ActionDialog from '../../../Dialog/ActionDialog';

export interface VendorDialogProps {
	parentApplicationId: string;
	applicationId: string;
	handleDialogClose: () => void;
}
export const VendorDialog = ({ parentApplicationId, applicationId, handleDialogClose }: VendorDialogProps) => {
	const [formIsDirty, setFormIsDirty] = useState(false);
	const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

	const updateFormDirtyState = useCallback((isDirty: boolean) => {
		setFormIsDirty(isDirty);
	}, []);

	const handleCloseWithConfirmation = () => {
		if (formIsDirty) {
			setIsConfirmDialogOpen(true);
		} else {
			handleDialogClose();
		}
	};

	const closeConfirmDialog = () => {
		setIsConfirmDialogOpen(false);
	};

	const buttons = [
		<Button variant="text" color="secondary" sx={{ marginLeft: 1 }} onClick={closeConfirmDialog}>
			Cancel
		</Button>,
		<Button
			variant="contained"
			color="error"
			onClick={() => {
				handleDialogClose();
			}}
		>
			Discard Changes
		</Button>
	];
	return (
		<>
			<Dialog
				open
				onClose={handleCloseWithConfirmation}
				maxWidth="xl"
				PaperProps={{
					sx: {
						width: '80vw',
						height: '90vh'
					}
				}}
			>
				<DialogTitle>Edit Vendor</DialogTitle>
				<DialogContent sx={{ display: 'flex', p: 0, m: 0, flexDirection: 'column' }}>
					<MetadataProvider>
						<ApplicationProvider applicationId={applicationId}>
							<VendorDetails
								parentApplicationId={parentApplicationId}
								informDialogAboutDirtyState={updateFormDirtyState}
								isDialog
								onClickBackSuccess={handleDialogClose}
								onClickNextSuccess={handleDialogClose}
							/>
						</ApplicationProvider>
					</MetadataProvider>
				</DialogContent>
			</Dialog>
			<ActionDialog
				title="Confirm"
				open={isConfirmDialogOpen}
				onClose={closeConfirmDialog}
				buttons={buttons}
				maxWidth="xs"
			>
				<Typography sx={{ pt: 3 }}>You have unsaved changes. Would you like to discard them?</Typography>
			</ActionDialog>
		</>
	);
};
