import { useForm } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';

import { Application } from '../../../models';
import { StepProps } from '../NewApplicationFlow';
import { SETTINGS } from '../../../constants/settings';
import { PortalContent } from '../../PortalContent';
import { useUpdateApplicationMutation } from '../queries';
import { useApplication } from '../../../contexts/ApplicationContext';
import { FormCheckbox } from '../../Fields/FormCheckbox';
import { ApplicationStep } from '../ApplicationStep';
import { enqueueSnackbar } from 'notistack';

const Attestation = ({ onNextClick, onPrevClick }: StepProps) => {
	const application = useApplication();

	const queryClient = useQueryClient();
	const mutation = useUpdateApplicationMutation({
		onSuccess: (applicationData: Application) => {
			queryClient.setQueryData<Application[]>(['applications'], oldData => {
				var applications = JSON.parse(JSON.stringify(oldData ?? [])) as Application[];
				var applicationIndex = applications?.findIndex(x => x?.id === applicationData?.id);
				if (applicationIndex >= 0) {
					return [
						...applications?.slice(0, applicationIndex),
						applicationData,
						...applications?.slice(applicationIndex + 1)
					];
				}

				return [...applications, applicationData];
			});
			queryClient.setQueryData<Application>(['applications', application.id], () => applicationData);

			if (onNextClick) {
				onNextClick();
			}
		},
		onError: (e: Error) => {
			enqueueSnackbar(e.message, { variant: 'error', autoHideDuration: 7500 });
		}
	});

	const {
		handleSubmit,
		control,
		formState: { errors },
		watch
	} = useForm<Application>({
		defaultValues: {
			attested: application.attested ?? false
		}
	});

	const onSubmit = (updatedApplication: Application) => {
		mutation.mutate({
			id: application.id,
			payload: {
				attested: updatedApplication.attested
			}
		});
	};

	const attested = watch('attested');

	return (
		<ApplicationStep
			onClickBack={onPrevClick}
			onClickNext={handleSubmit(onSubmit)}
			isNextLoading={mutation.isLoading}
			isNextDisabled={!attested}
		>
			<form>
				<PortalContent contentKey={SETTINGS.ApplicationAttestation} />

				<FormCheckbox
					required={true}
					name={'attested'}
					control={control}
					label={'I attest that the above information is accurate.'}
					testId={'attested'}
					errors={errors?.attested}
				/>
			</form>
		</ApplicationStep>
	);
};
export default Attestation;
