import { createContext, useContext, PropsWithChildren, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { ErrorPage } from '../pages/Error';
import { LoadingPage } from '../pages/Loading';
import { Configuration } from '../models';

export class FeatureManager {
    private features: { [key: string]: boolean; };

    constructor(features?: { [key: string]: boolean }) {
        this.features = features ?? {}
    }

    public isEnabled(key: string): boolean {
        return Object.keys(this.features).includes(key) ? this.features[key] : false
    }
}

export class SettingManager {
    private settings: { [key: string]: string; };

    constructor(settings?: { [key: string]: string }) {
        this.settings = settings ?? {}
    }

    public get(key: string): string | undefined {
        return Object.keys(this.settings).includes(key) ? this.settings[key] : undefined
    }
}

export type ConfigurationContextType = {
    features: FeatureManager
    settings: SettingManager
}

export const ConfigurationContext = createContext<ConfigurationContextType>({
    features: new FeatureManager(),
    settings: new SettingManager(),
});
export const ConfigurationProvider: React.FC<PropsWithChildren> = ({ children }) => {

    const fetchConfiguration = () => axios.get<Configuration>('/api/configuration').then(response => response.data)

    const { isFetching, isError, data, refetch } = useQuery({
        queryKey: ['configuration'],
        queryFn: () => fetchConfiguration(),
    })

    const value = useMemo(() => ({
        features: new FeatureManager(data?.features),
        settings: new SettingManager(data?.settings),
    }), [data])

    if (isFetching) {
        return (<LoadingPage />)
    }

    if (isError) {
        return (<ErrorPage retry={refetch} />)
    }

    return (
        <ConfigurationContext.Provider value={value}>
            {children}
        </ConfigurationContext.Provider>
    );
}

export const useConfiguration = () => useContext(ConfigurationContext)