import axios from "axios"
import { useQuery } from "@tanstack/react-query"
import { Attachment, Response, parseResponse } from "../../models"
import { CircularProgress, List } from "@mui/material"
import { FileListItem } from "./FileListItem"
import { FileUpload } from "./FileUpload"

type FileListProps = {
    parentType: string
    parentId: string
    allowUpload: boolean
}
export const FileList = ({ parentType, parentId, allowUpload = true }: FileListProps) => {

    const fetchFiles = () => axios.get<Response<Attachment[]>>(`/api/files?type=${parentType}&id=${parentId}`).then(parseResponse)
    const { data, isLoading, isError } = useQuery({
        queryKey: ["files", parentType, parentId],
        queryFn: fetchFiles,
    });

    if (isLoading || isError) {
        return <CircularProgress size={24} />;
    }

    return (
        <>
            <List>
                {data.map((file, index) => <FileListItem key={index} name={file.name} isSuccess />)}
                {allowUpload && <FileUpload parentType={parentType} parentId={parentId} />}
            </List>
        </>
    )
}