import axios from "axios";
import { createContext, useContext, useMemo, PropsWithChildren, useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from 'react-router-dom';

import { EmptyPreScreening, PreScreening } from '../models/PreScreening';
import { ErrorPage } from '../pages/Error';
import { LoadingPage } from '../pages/Loading';
import { Response, parseResponse } from '../models';

type PreScreeningContextType = {
	preScreening: PreScreening;
	setPreScreening: (value: PreScreening) => void;
};

export const PreScreeningContext = createContext<PreScreeningContextType | undefined>(undefined);

export const PreScreeningProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const searchParams = useSearchParams();
	var id = new URLSearchParams(searchParams[0]).get('id');

	const fetchPreScreenings = () =>
		axios
			.get<Response<PreScreening | PreScreening[]>>(id ? `/api/prescreenings/${id}` : '/api/prescreenings')
			.then(parseResponse);

	const { isFetching, isError, data, refetch } = useQuery({
		queryKey: ['prescreening'],
		queryFn: () => fetchPreScreenings()
	});

	const [preScreening, setPreScreening] = useState<PreScreening>(EmptyPreScreening);

	useEffect(() => {
		if (data) {
			if (Array.isArray(data)) {
				setPreScreening(data[0]);
			} else {
				setPreScreening(data);
			}
		}
	}, [data]);

	const value = useMemo(
		() => ({
			preScreening: preScreening,
			setPreScreening: setPreScreening
		}),
		[preScreening, setPreScreening]
	);

	if (isFetching) {
		return <LoadingPage />;
	}

	if (isError) {
		return <ErrorPage retry={refetch} />;
	}

	return <PreScreeningContext.Provider value={value}>{children}</PreScreeningContext.Provider>;
};

export const usePreScreening = () => useContext(PreScreeningContext) as PreScreeningContextType;
