import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { Button } from '@mui/material';
import { FileListItem } from './FileListItem';
import { Attachment, Response, parseResponse } from '../../models';
import { UploadFile } from '../../models/UploadFile';

type FileUploadItemProps = {
	parentType: string;
	parentId: string;
	onFileSelected: () => void;
};

export const FileUploadItem: React.FC<FileUploadItemProps> = ({ parentType, parentId, onFileSelected }) => {
	const fileInputRef = useRef<HTMLInputElement>(null);
	const handleUploadClick = () => fileInputRef.current?.click();

	const convertFileToBase64 = (file: File): Promise<string> => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onloadend = () => {
				const result = reader.result as string;
				const base64Data = result.split(',')[1];
				resolve(base64Data);
			};
			reader.onerror = reject;
			reader.readAsDataURL(file);
		});
	};

	const uploadFile = async (file: File) => {
		var content = await convertFileToBase64(file);
		var data: UploadFile = {
			parentType: parentType,
			parentId: parentId,
			name: file.name,
			type: file.type,
			content: content
		};
		var response = await axios.post<Response<Attachment>>('/api/files', data);
		return await parseResponse(response);
	};

	const { mutate, isLoading, isSuccess, isError } = useMutation(uploadFile);

	const [files, setFiles] = useState<File[]>([]);

	const onChangeFile: React.ChangeEventHandler<HTMLInputElement> = event => {
		if (event.target.files) {
			const newFiles = Array.from(event.target.files);
			setFiles(newFiles);
			onFileSelected();
		}
	};

	useEffect(() => {
		files.forEach(file => {
			mutate(file);
		});
	}, [mutate, files]);

	return (
		<>
			{files.length === 0 && (
				<>
					<input
						accept=".jpg,.jpeg,.png,.gif,.doc,.docx,.pdf,.txt"
						style={{ display: 'none' }}
						id="file-upload"
						data-testid="file-upload"
						type="file"
						multiple
						onChange={onChangeFile}
						ref={fileInputRef}
					/>
					<Button variant="outlined" color="secondary" onClick={handleUploadClick}>
						Upload document
					</Button>
				</>
			)}
			{files.map((file, index) => (
				<FileListItem key={index} name={file.name} isUploading={isLoading} isSuccess={isSuccess} isFailure={isError} />
			))}
		</>
	);
};
