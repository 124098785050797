import { Typography, Grid, Link } from '@mui/material';

interface GridCellProps {
	title: string;
	value?: string | number | boolean;
	link?: boolean;
	onClick?: () => void;
	xs?: number;
}

export const GridCell = ({ title, value = '--', xs = 3, link = false, onClick }: GridCellProps) => {
	return (
		<Grid item xs={xs}>
			<Typography fontWeight={700}>{title}</Typography>
			{link ? (
				<Link component="button" onClick={onClick}>
					{value}
				</Link>
			) : (
				<Typography>{value}</Typography>
			)}
		</Grid>
	);
};
