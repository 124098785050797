import { ErrorOutline } from "@mui/icons-material"
import { Box, Button, Typography } from "@mui/material"

interface ErrorPageProps {
    message?: string
    retry?: () => void
}

export const ErrorPage = ({ message = 'An unexpected error occurred.', retry }: ErrorPageProps) => {
    return (
        <Box display='flex' flexDirection='column' gap={2} flex={1} justifyContent='center' alignItems='center'>
            <ErrorOutline sx={{ fontSize: 48 }} color='error' />
            <Typography variant='subtitle1' color={(theme) => theme.palette.error.main}>{message}</Typography>
            {retry && <Button variant='contained' onClick={retry}>Retry</Button>}
        </Box>
    )
}