import { Box, Typography } from '@mui/material';

interface CustomNoRowsOverlayProps {
	message: string;
}
export const CustomNoRowsOverlay: React.FC<CustomNoRowsOverlayProps> = ({ message }) => (
	<Box display="flex" alignItems="center" justifyContent="center" height="100%">
		<Typography variant="body2">{message}</Typography>
	</Box>
);
