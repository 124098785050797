import { useState } from "react";
import { FileUploadItem } from "./FileUploadItem";

type FileUploadProps = {
    parentType: string
    parentId: string
}

export const FileUpload = ({ parentType, parentId }: FileUploadProps) => {

    const [count, setCount] = useState(1)

    const increment = () => {
        setCount(prevCount => prevCount + 1)
    }

    return (
        <>
            {[...Array(count).keys()].map(x => (
                <FileUploadItem key={x} parentType={parentType} parentId={parentId} onFileSelected={increment} />
            ))}
        </>
    )
};
