import * as yup from 'yup';
export const PHONE_NUMBER_REGEX = /^\(([0-9]{3})\)\s([0-9]{3})-([0-9]{4})$/;
export const ZIP_REGEX = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

export const BOOLEAN_FIELD_REQUIRED_VALIDATION = yup.boolean().required('This Field is required');
export const PHONE_NUMBER_REQUIRED_VALIDATION = yup
	.string()
	.required('Phone is required')
	.matches(PHONE_NUMBER_REGEX, 'Please use format (555) 123-4567.');

export const ZIP_CODE_VALID_REQUIRED_VALIDATION = yup
	.string()
	.required('Please enter the ZIP Code')
	.matches(ZIP_REGEX, 'Please enter valid ZIP Code.');
