import { Box } from '@mui/material';

import { Outlet } from 'react-router';
import { Page } from '../components/Page';
import { ApplicationVendorList } from '../components/Applications/ApplicationVendorList';

export const ApplicationsPage = () => {

	return (
		<Page>
			<Box display='flex' flexDirection='row' flex={1} maxHeight='100%'>
				<ApplicationVendorList />
				<Outlet />
			</Box>
		</Page >
	);
};
