import { StepProps } from '../../NewApplicationFlow';
import { useApplication } from '../../../../contexts/ApplicationContext';
import { APPLICATION_TYPE } from '../../NewApplicationModal';
import CorporateDetails from './CorporateDetails';
import CorporateVendorDetails from './CorporateVendorDetails';
import { MetadataProvider } from '../../../../contexts/MetadataContext';

const CorporateStep = ({ onNextClick, onPrevClick }: StepProps) => {
	const application = useApplication();

	if (application.type === APPLICATION_TYPE.CORP_VENDOR) {
		return (
			<MetadataProvider>
				<CorporateDetails onNextClick={onNextClick} onPrevClick={onPrevClick} />
			</MetadataProvider>
		);
	} else {
		return <CorporateVendorDetails onNextClick={onNextClick} onPrevClick={onPrevClick} />;
	}
};

export default CorporateStep;
