import * as React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { MENU, MenuItem, ROUTES } from '../../constants';
import { useConfiguration } from '../../contexts/ConfigurationContext';
import { useUser } from '../../contexts/UserContext';

export interface NavigatorProps {
    width: number
}
export const Navigator = ({ width }: NavigatorProps) => {

    const { features, settings } = useConfiguration()
    const user = useUser()

    const navigate = useNavigate()
    const location = useLocation()

    const renderCategory = (item: MenuItem) => {

        if (item.isVisible && !item.isVisible(features, settings, user)) {
            return
        }

        return (
            <div key={item.name}>
                <ListItem sx={{ pt: 4, pb: 2, px: 3, fontSize: 16, fontWeight: 700, color: 'common.black' }}>
                    {item.name}
                </ListItem>
                <Box>
                    {item.items && item.items.map((item) => renderItem(item))}
                </Box>
            </div>
        )
    }

    const renderItem = (item: MenuItem) => {
        const isActive = item.path === location.pathname || (item.path !== ROUTES.home.path && location.pathname.startsWith(item.path ?? ''))

        if (item.isVisible && !item.isVisible(features, settings, user)) {
            return
        }

        return (
            <ListItem disablePadding key={item.name}>
                <ListItemButton selected={isActive} sx={{ py: '4px', px: 3 }} onClick={() => item.path && navigate(item.path)}>
                    {isActive && <div style={{ height: 22, background: 'linear-gradient(180deg, #B84787 0%, #81629D 100%)', borderRadius: '90px', position: 'relative', left: -16, marginRight: -4, padding: 2 }} />}
                    <ListItemIcon>{item.icon && React.createElement(item.icon)}</ListItemIcon>
                    <ListItemText>{item.name}</ListItemText>
                </ListItemButton>
            </ListItem>
        )
    }

    return (
        <Box height='100%' data-testid="navigator">
            <List disablePadding sx={{ pt: 3, height: '100%', width: width }}>
                {MENU.map((item) => {
                    if (item.items) {
                        return renderCategory(item)
                    }
                    else {
                        return renderItem(item)
                    }
                })}
            </List>
        </Box>
    );
}