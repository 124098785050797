import { Box, ButtonProps, Container } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { PropsWithChildren } from 'react';

export interface PreScreeningStepProps extends PropsWithChildren {
	backText?: string
	backProps?: ButtonProps
	onClickBack?: () => void;
	isBackLoading?: boolean;
	isBackDisabled?: boolean;
	nextText?: string
	nextProps?: ButtonProps
	onClickNext?: () => void;
	isNextLoading?: boolean;
	isNextDisabled?: boolean;
}

export const PreScreeningStep: React.FC<PreScreeningStepProps> = ({
	children,
	backText = 'Back',
	backProps,
	onClickBack,
	isBackLoading,
	isBackDisabled,
	nextText = 'Next',
	nextProps,
	onClickNext,
	isNextLoading,
	isNextDisabled
}) => {
	return (
		<>
			<Box display="flex" flexDirection="column" flex={1} sx={{ overflowY: 'auto' }} px={3}>
				<Container>{children}</Container>
			</Box>
			{(onClickBack || onClickNext) && (
				<Box py={2} sx={{ backgroundColor: theme => theme.palette.grey[900] }}>
					<Box display="flex" justifyContent="center" gap={3}>
						{onClickBack && (
							<LoadingButton
								variant="contained"
								onClick={onClickBack}
								loading={isBackLoading}
								disabled={isBackDisabled || isBackLoading}
								data-testid="step-back-btn"
								{...backProps}
							>
								{backText}
							</LoadingButton>
						)}
						{onClickNext && (
							<LoadingButton
								variant="contained"
								onClick={onClickNext}
								loading={isNextLoading}
								disabled={isNextDisabled || isNextLoading}
								data-testid="step-next-btn"
								{...nextProps}
							>
								{nextText}
							</LoadingButton>
						)}
					</Box>
				</Box>
			)}
		</>
	);
};
