import { Box, Typography } from "@mui/material"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { PreScreeningStep } from "../PreScreeningStep";

export const Confirmation = () => {
  return (
    <PreScreeningStep>
      <Box display='flex' flexDirection='column' py={5} sx={{ backgroundColor: '#F7F7F7', alignItems: 'center', borderRadius: '10px' }}>
        <CheckCircleIcon sx={{ fontSize: 75, color: '#00A99D' }} />
        <Box px='12%' mt={5}>
          <Typography variant='h2'>Successfully submitted!</Typography>
          <Typography variant='body1'>Your form was successfully submitted. We'll let you know if there are any changes that need to be made.</Typography>
        </Box>
      </Box>
    </PreScreeningStep>
  );
}